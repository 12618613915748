import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import {
  blockUnblockSubAdmin,
  deleteSubAdmin,
  getNotificationApi,
  getSubAdmin,
} from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import moment from 'moment'
import { CButton } from '@coreui/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'

const Notification = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Sub Admin')
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return { page: 1, type: '', search: '', limit: pageLength, ...sorting }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'notification' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'notification' && { ...prevFilters }),
  })
  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const navigate = useNavigate()

  const getAllNotification = useCallback((payload) => {
    getNotificationApi(payload).then((response) => {
      if (response.code === 200) {
        setUser(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      sort_key: 'id',
      width: '100px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      sortable: true,
      sort_key: 'subject',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Message',
      selector: (row) => row.message,
      sortable: true,
      sort_key: 'message',
      minWidth: '300px',
      grow: 2,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
      sort_key: 'type',
      minWidth: '100px',
      grow: 2,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Action',
      minWidth: '250px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/send-notification/${row.id}`)}
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger >
          )}
          {/* {(isUserAdmin || getUserAccess.is_edit === 1) && (
                       //  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                            <li className="list-inline-item">
                                <button
                                    className="btn btn-link"
                                    onClick={() => {
                                        navigate(`/sub-admin/edit/${row.id}`)
                                    }}
                                >
                                    <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
                                </button>
                            </li>
                        //  </OverlayTrigger >
                    )}
                    {(isUserAdmin || getUserAccess.is_remove === 1) && (
                       //  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                            <li className="list-inline-item">
                                <button className="btn btn-link" onClick={(e) => HandleDeleteSubAdmin(e, row.id)}>
                                    <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
                                </button>
                            </li>
                        //  </OverlayTrigger >
                    )} */}
        </ul>
      ),
    },
  ]

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getAllNotification(filter)
    setPrevFilters({ ...filter, listing_page: 'notification' })
  }, [filter, getAllNotification, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Notification Page</title>
      </Helmet>
      <AppHeader title="Notification" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            title={'Notification'}
            columns={columns}
            data={user}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('type', e.target.value)}
                      value={payload.type}
                    >
                      <option value={''} disabled={true}>
                        Select Type
                      </option>
                      {[
                        { value: 'all', label: 'All' },
                        { value: 'user', label: 'User' },
                        { value: 'Designer', label: 'Designer' },
                        { value: 'news letter', label: 'News Letter' },
                      ]?.map((item) => {
                        return (
                          <option key={item?.value} value={item?.value} disabled={item.disabled}>
                            {item?.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-between  ">
                  <div>
                    <CButton
                      color="primary"
                      onClick={() => {
                        applyFilter()
                      }}
                      disabled={!payload?.search?.length && !payload.type.length}
                      size="sm"
                      className="mt-3 theme-custom-btn"
                    >
                      Apply
                    </CButton>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      disabled={!payload?.search?.length && !payload.type.length}
                      onClick={() => {
                        clearFilter()
                      }}
                      className="ms-3 mt-3 theme-custom-btn-outline"
                    >
                      Clear
                    </CButton>
                  </div>
                  {(isUserAdmin || getUserAccess.is_add === 1) && (
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        navigate('/send-notification')
                      }}
                      data-coreui-toggle="modal"
                      data-coreui-target="#staticBackdrop"
                      className="ms-3 mt-3 px-4 theme-custom-btn-outline"
                    >
                      + ADD NOTIFICATION
                    </CButton>
                  )}
                </div>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default Notification
