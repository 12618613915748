import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import Loader from '../loader/Loader'
import DataTable from 'react-data-table-component'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { editContactUs, getContactUs } from 'src/api/apiHandler'
import { CButton } from '@coreui/react'
import { AppHeader } from 'src/components'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Globals from 'src/common/utils'
import { showPromiseNotification } from 'src/common/notification'
import swal from 'sweetalert2'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'
import { useNavigate } from 'react-router-dom'

const Contactus = () => {
  const { prevFilters, setPrevFilters } = useFilter()
  const isUserAdmin = isAdmin()
  const navigate = useNavigate()
  const getUserAccess = isSubadmin() && getUserPermissions('ContactUs Queries')
  console.log('getUserAccess: ', getUserAccess, !(isUserAdmin || getUserAccess?.id_edit === 1))

  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'cu.id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return {
      page: 1,
      type: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      ...sorting,
    }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'contactus' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'contactus' && { ...prevFilters }),
  })
  console.log('filter: ', filter)

  const [contact, setContact] = useState([])
  console.log('contact: ', contact)
  const [loaded, setLoaded] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  console.log('totalRows: ', totalRows)

  const getStaticPage = useCallback((payload) => {
    getContactUs(payload).then((response) => {
      if (response.code === 200) {
        console.log('response: ', response)
        setContact(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setContact([])
        setLoaded(false)
      }
    })
  }, [])

  const updateStatus = (e, id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'You want to change the status',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            editContactUs({ contact_id: id, status: e }).then((response) => {
              if (response.code === 200) {
                setContact((item) =>
                  item.map((val) => {
                    if (val.id === id) val.status = e
                    return val
                  }),
                )
                resolve(response)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      minWidth: '80px',
      sortable: true,
      sort_key: 'cu.id',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'User ID',
      selector: (row, index) => (row.user_id ? row.user_id : '-'),
      minWidth: '140px',
      sortable: true,
      sort_key: 'cu.user_id',
      style: {
        fontSize: '14px',
        textAlign: 'center',
      },
    },
    {
      name: 'Full Name',
      cell: (row) => row.full_name,
      minWidth: '200px',
      sortable: true,
      sort_key: 'cu.full_name',

      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Email',
      cell: (row) => row.email,
      sortable: true,
      sort_key: 'cu.email',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Subject',
      cell: (row) => row.subject,
      sortable: true,
      sort_key: 'cu.subject',
      minWidth: '300px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Date',
      cell: (row) => moment(row.created_at).format('DD-MM-YYYY'),
      minWidth: '150px',
      sortable: true,
      sort_key: 'cu.created_at',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Description',
      minWidth: '300px',
      style: {
        fontSize: '14px',
      },
      cell: (row) => {
        // <ul className="list-inline">

        if (row?.description?.length > 40) {
          return (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>{row?.description}</Tooltip>}>
            <div>{row?.description?.slice(0, 40)}...</div>
            //  </OverlayTrigger  >
          )
        }
        return <p>{row?.description}</p>
      },
    },
    {
      name: 'Status',
      minWidth: '150px',
      style: {
        textAlign: 'center',
        marginTop: '1%',
        marginLeft: '0.1%',
      },
      cell: (row) => (
        <ul className="list-inline">
          <li className="list-inline-item">
            <select
              className="form-select"
              onChange={(e) => updateStatus(e.target.value, row.id)}
              value={row.status}
              disabled={!(isUserAdmin || getUserAccess?.is_edit === 1)}
            >
              {[
                { value: 'new', label: 'New', disable: true },
                { value: 'in_progress', label: 'In Progress' },
                { value: 'hold', label: 'On Hold' },
                { value: 'resolved', label: 'Resolved' },
              ].map((item) => {
                return (
                  <option key={item?.value} value={item.value} disabled={item.disable}>
                    {item.label}
                  </option>
                )
              })}
            </select>
          </li>
        </ul>
      ),
    },
    (isUserAdmin ||
      getUserAccess.is_view === 1 ||
      getUserAccess.is_edit === 1 ||
      getUserAccess.is_remove === 1) && {
      name: 'Action',
      minWidth: '100px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  navigate(`/contactus/view-contactus/${row.id}`)
                  // setSingleData({ type: 'view', data: row })
                  // setAddIconModal(true)
                }}
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger >
          )}
        </ul>
      ),
    },
  ]

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const setDates = (e, { startDate, endDate }) => {
    setPayload((prev) => ({
      ...prev,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    }))
  }

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getStaticPage(filter)
    setPrevFilters({ ...filter, listing_page: 'contactus' })
  }, [filter, getStaticPage, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Contact Us Queries</title>
      </Helmet>
      <AppHeader title="ContactUs Queries" />
      <div className="px-3">
        {loaded ? (
          <Loader />
        ) : (
          <DataTable
            title="Contact Us Queries"
            columns={columns}
            data={contact}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <DateRangePicker
                      onApply={setDates}
                      initialSettings={{
                        startDate: moment().subtract(6, 'days').format('MM/DD/YYYY'),
                        endDate: moment().format('MM/DD/YYYY'),
                        ranges: Globals.datePickerRanges,
                        autoUpdateInput: false,
                      }}
                    >
                      <input
                        type="text"
                        value={
                          payload.start_date !== '' && payload.end_date !== ''
                            ? moment(payload.start_date).format('MMMM DD, YYYY') +
                            ' - ' +
                            moment(payload.end_date).format('MMMM DD, YYYY')
                            : 'Select Period'
                        }
                        className=" form-control"
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('type', e.target.value)}
                      value={payload.type}
                    >
                      <option value="" selected disabled>
                        Select Status
                      </option>
                      {[
                        { value: 'new', label: 'New' },
                        { value: 'in_progress', label: 'In Progress' },
                        { value: 'hold', label: 'On Hold' },
                        { value: 'resolved', label: 'Resolved' },
                      ].map((item) => {
                        return (
                          <option key={item?.value} value={item.value}>
                            {item.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <CButton
                    color="primary"
                    onClick={() => {
                      applyFilter()
                    }}
                    size="sm"
                    className="mt-3 theme-custom-btn"
                    disabled={
                      !payload.search.length && !payload.type.length && !payload.start_date.length
                    }
                  >
                    Apply
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      clearFilter()
                    }}
                    disabled={
                      !payload.search.length && !payload.type.length && !payload.start_date.length
                    }
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Clear
                  </CButton>
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export default Contactus
