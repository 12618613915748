// export const baseUrl = "http://localhost:7088";
// export const baseUrl = "http://3.7.8.99:3000";
export const baseUrl = "http://localhost:3000";

export const NGURL = "https://e9a6-122-169-110-153.ngrok-free.app"


//TO CALL GET API
export const callGetAPIAdmin = async (path) => {
    const response = await fetch(baseUrl + path, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'api-key': 'W/ZXjTee7BHwm5TlEYbRig==',
            'accept-language': 'en',
            'token': localStorage.getItem("role") === "admin" ? localStorage.getItem("atoken") : localStorage.getItem("stoken")
        }
    });
    const data = await response.json();

    return data;
};



//TO CALL POST
export const callPostAPIAdmin = async (path, body) => {
    const response = await fetch(baseUrl + path, {
        method: 'POST',
        body: body,
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'api-key': 'W/ZXjTee7BHwm5TlEYbRig==',
            'accept-language': 'en',
            'token': localStorage.getItem("role") === "admin" ? localStorage.getItem("atoken") : localStorage.getItem("stoken")
        }
    });
    const data = await response.json();

    return data;
}



// TO CALL MULTIPART
export const callMultiPartAdmin = async (path, body) => {
    const response = await fetch(baseUrl + path, {
        method: 'POST',
        body: body,
        headers: {
            'accept': 'application/json',
            'api-key': 'W/ZXjTee7BHwm5TlEYbRig==',
            'accept-language': 'en',
            'token': localStorage.getItem("role") === "admin" ? localStorage.getItem("atoken") : localStorage.getItem("stoken")
        }
    });
    const data = await response.json();

    return data;
}

