import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { getToken } from 'src/common/LocalStorageService'

const PrivateLayout = () => {
  const navigate = useNavigate()
  const [isRender, setIsRender] = useState(false)

  useEffect(() => {
    const isLoggedIn = !getToken()

    if (isLoggedIn) {
      navigate('/')
    } else {
      setIsRender(true)
    }
  }, [navigate])
  if (!isRender) {
    return null
  }
  return <Outlet />
}

export default PrivateLayout
