import React from 'react';
import './roundLoader.css';

const RoundLoader = () => {
    return (
        <div className="round-loader"></div>
    );
};

export default RoundLoader;
