import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { MonthlySettlement, MonthlySettlementDetails, getUsers } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Globals from 'src/common/utils'
import moment from 'moment'
import { CButton } from '@coreui/react'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const ViewMonthlySettlementsPage = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Monthly Settlement')
  const clickRef = useRef()
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [monthlySettlementList, setMonthlySettlementList] = useState([])
  const [userData, setUserData] = useState([])
  console.log('userData: ', userData)
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [price, setPrice] = useState(0)
  const [sorting, setSorting] = useState({
    sort_by: 'u.id',
    sort_direction: 'desc',
  })
  const location = useLocation()
  const { id } = useParams()
  const { filterData } = location.state || {}
  console.log('filterData: ', filterData)

  const minDate = moment().clone().startOf('month').clone().subtract(1, 'day').toDate()
  const [startDate, setStartDate] = useState(minDate)

  const initialFilter = useMemo(() => {
    // const date = moment(startDate, 'ddd MMM DD YYYY HH:mm:ss Z')
    return {
      page: 1,
      is_paid: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      // start_date: date.startOf('month').format('YYYY/MM/DD'),
      // end_date: date.endOf('month').format('YYYY/MM/DD'),
      ...sorting,
    }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'view_monthly_settlement' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'view_monthly_settlement' && { ...prevFilters }),
  })
  console.log('filter123: ', filter);

  const handleFilter = useCallback((key, value) => {
    console.log('key: ', key);
    console.log('value: ', value);
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
    setStartDate(minDate);
  }, [initialFilter, minDate])
  const HandleDate = useCallback((startDate) => {
    const date = moment(startDate, 'ddd MMM DD YYYY HH:mm:ss Z')
    setPayload((prev) => ({
      ...prev,
      start_date: date.startOf('month').format('YYYY/MM/DD'),
      end_date: date.endOf('month').format('YYYY/MM/DD'),
    }))
  }, [])
  const navigate = useNavigate()

  const getMonthlySettlementList = useCallback(() => {
    MonthlySettlementDetails({ ...filter, userId: id }).then((response) => {
      if (response.code === 200) {
        setUserData(response.data.user_data)
        setPrice(response?.data?.price)
        setMonthlySettlementList(response.data.template_data.data)
        setTotalRows(response.data.template_data.total_page_count)
        setLoaded(false)
      } else {
        setMonthlySettlementList([])
        setLoaded(false)
      }
    })
  }, [filter, id])

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '10px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      width: '100px',
      sort_key: 'u.id',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Name',
      selector: (row) => row.full_name,
      sort_key: 'u.full_name',
      sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sort_key: 'u.email',
      sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Templates Name',
      selector: (row) => row?.template_name,
      sort_key: 't.name',
      sortable: true,
      minWidth: '200px',
      //   headerStyle: {
      //     justifyContent: 'center',
      //   },
      style: {
        fontSize: '14px',
        // justifyContent: 'center',
      },
    },
    {
      name: 'Template',
      selector: (row) => row.thumbnail,
      minWidth: '150px',
      style: {
        fontSize: '14px',
      },
      cell: (row) => (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img src={row.thumbnail} height={50} width={50} alt="Image" />
      ),
    },

    {
      name: 'Download Date',
      selector: (row) => moment(row.created_at).format('DD/MM/YYYY, hh:mm:ss A'),
      sort_key: 'td.created_at',
      sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    // {
    //     name: 'Payment Status',
    //     minWidth: '200px',
    //     style: {
    //         textAlign: 'center',
    //     },
    //     cell: (row) => (
    //         <ul className="list-inline mb-0">
    //             <li className="list-inline-item">
    //                 <select
    //                     className="form-select"
    //                     // onChange={(e) => updateStatus(e.target.value, row.id)}
    //                     value={row?.download_template?.every((item) => item.is_paid) ? 1 : 0}
    //                     disabled={!(isUserAdmin || getUserAccess.is_edit === 1)}
    //                 >
    //                     {[
    //                         { value: 1, label: 'Paid' },
    //                         { value: 0, label: 'Unpaid' },
    //                     ]?.map((item) => {
    //                         return (
    //                             <option key={item?.value} value={item?.value} disabled={item.disabled}>
    //                                 {item?.label}
    //                             </option>
    //                         )
    //                     })}
    //                 </select>
    //             </li>
    //         </ul>
    //     ),
    // },
    (isUserAdmin || getUserAccess.is_view || getUserAccess.is_remove) && {
      name: 'Action',
      minWidth: '150px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/view-template/${row.template_id}`)}
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
        </ul>
      ),
    },
  ]

  // const setDates = (e, { startDate, endDate }) => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     start_date: startDate?.format('YYYY-MM-DD'),
  //     end_date: endDate?.format('YYYY-MM-DD'),
  //   }))
  // }

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getMonthlySettlementList({ ...filter, ...filterData, userId: id })
    setPrevFilters({ ...filter, listing_page: 'view_monthly_settlement' })
  }, [filter, filterData, getMonthlySettlementList, id, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>View Monthly Settlement</title>
      </Helmet>
      <AppHeader title="View Monthly Settlement" />
      {loaded ? (
        <Loader />
      ) : (
        <>
          <div className=" d-flex row m-3 p-1 pt-4 pb-4" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="col-lg-4 col-md-6 col-sm-10 col-12">
              <h5 className="font-family-primary fw-semibold">User Details :</h5>
              <p className="mb-2">
                <span className="fw-semibold me-2">Full Name:</span> {userData.full_name}
              </p>
              <p className="mb-2">
                <span className="fw-semibold me-2">Email:</span> {userData.email}
              </p>
              <p className="mb-2 text-capitalize">
                <span className="fw-semibold  me-2">Subscription Type:</span>{' '}
                {userData?.subscribed_type
                  ? userData.subscribed_type + ' / ' + userData.subscription_duration
                  : 'Free User'}
              </p>
              <p className="mb-2">
                <span className="fw-semibold me-2">Total Earning:</span>{' '}
                {price
                  ? '$ ' +
                  price
                  : 0}
              </p>
            </div>
          </div>
          <div className="px-3">
            <DataTable
              title={'Downloaded Template'}
              columns={columns}
              data={monthlySettlementList}
              pagination
              paginationServer
              responsive
              onSort={handleSort}
              sortServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={filter.page}
              paginationPerPage={filter.limit}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              onChangeRowsPerPage={(noRows) => {
                setPageLength(noRows)
                handleFilter('limit', noRows)
              }}
              onChangePage={(page) => {
                console.log('page: ', page);
                handleFilter('page', page)
              }}
              selectableRowsHighlight
              highlightOnHover
              striped
              subHeader
              fixedHeader
              fixedFooter
              persistTableHead
              subHeaderWrap
              subHeaderAlign="left"
              customStyles={tableCustomStyles}
              progressPending={loaded}
              noDataComponent={
                <>
                  <h5 className="p-3">No Data Found</h5>
                </>
              }
              subHeaderComponent={
                <div className="w-100 ">
                  <div className="row row-gap-2 ">
                    <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search.."
                        value={payload.search}
                        onChange={(e) => {
                          handleFilterPayload('search', e.target.value)
                          if (!e.target.value.length) {
                            clearFilter()
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key == 'Enter') {
                            if (e.target.value !== '') {
                              applyFilter()
                            }
                          }
                        }}
                      />
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                                            <select
                                                className="form-select"
                                                onChange={(e) => handleFilterPayload('is_paid', e.target.value)}
                                                value={payload.is_paid}
                                            >
                                                <option value={''} disabled={true}>
                                                    Select Status
                                                </option>
                                                {[
                                                    { value: 1, label: 'Paid' },
                                                    { value: 0, label: 'Unpaid' },
                                                ]?.map((item) => {
                                                    return (
                                                        <option key={item?.value} value={item?.value} disabled={item.disabled}>
                                                            {item?.label}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div> */}
                    <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                      <DatePicker
                        selected={startDate}
                        className="form-control w-100 cursor-pointer"
                        onChange={(date) => { setStartDate(date); HandleDate(date) }}
                        dateFormat="MMM yyyy"
                        showMonthYearPicker
                        maxDate={minDate}
                      />
                    </div>
                  </div>
                  <>
                    <CButton
                      color="primary"
                      onClick={() => {
                        applyFilter()
                      }}
                      ref={clickRef}
                      size="sm"
                      className="mt-3 theme-custom-btn"
                      disabled={!payload.search.length && !payload.start_date.length}
                    >
                      Apply
                    </CButton>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        clearFilter()
                      }}
                      disabled={!payload.search.length && !payload.start_date.length}
                      className="ms-3 mt-3 theme-custom-btn-outline"
                    >
                      Clear
                    </CButton>
                  </>
                </div>
              }
            />
            <div className="d-flex justify-content-end " style={{ backgroundColor: '#FFFFFF' }}>
              <Link className="btn btn-outline-danger me-2 mb-2" to={-1}>
                <i className="fas fa-arrow-left" /> Back
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ViewMonthlySettlementsPage
