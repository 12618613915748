import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { activeInactiveFAQ, deleteFAQ, getFAQ } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import { CButton } from '@coreui/react'
import { AppHeader } from 'src/components'
import Loader from '../loader/Loader'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'

const FAQ = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('FAQ')
  const { prevFilters, setPrevFilters } = useFilter()
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'id',
    sort_direction: 'desc',
  })
  const navigate = useNavigate()
  const initialFilter = useMemo(() => {
    return { page: 1, search: '', limit: pageLength, ...sorting }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'faq' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'faq' && { ...prevFilters }),
  })

  const [loaded, setLoaded] = useState(false)
  const [faq, setFaq] = useState([])

  const [totalRows, setTotalRows] = useState(0)

  const deletePage = async (id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        width: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let body = { faq_id: id }
          deleteFaq(body, id)
        }
      })
  }

  const activePage = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Inactive it!' : 'Yes, Active it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let body = { faq_id: id }
          activeInactiveFaq(body, id)
        }
      })
  }

  const getFaq = useCallback((payload) => {
    getFAQ(payload).then((response) => {
      if (response.code === 200) {
        setFaq(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setFaq([])
        setLoaded(false)
      }
    })
  }, [])

  const activeInactiveFaq = useCallback(
    (payload, id) => {
      const myPromise = new Promise((resolve, reject) =>
        activeInactiveFAQ(payload).then((response) => {
          console.log('response: ', response, faq)
          if (response.code === 200) {
            resolve(response)
            const updatedData = faq.map((item) => {
              if (item.id === id) {
                return { ...item, is_active: response?.data?.is_active }
              }
              return item
            })
            setFaq(updatedData)
            setLoaded(false)
          } else {
            // setFaq([])
            setLoaded(false)
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
    [faq],
  )

  const deleteFaq = useCallback(
    (payload, id) => {
      const myPromise = new Promise((resolve, reject) =>
        deleteFAQ(payload).then((response) => {
          if (response.code === 200) {
            const filteredData = faq.filter((item) => item.id !== id)
            console.log('filteredData: ', filteredData)
            resolve(response)
            if (faq.length >= 2) {
              getFaq(filter)
            } else {
              setFilter((prev) => {
                return { ...prev, page: filter.page - 1 }
              })
            }
            // setFaq(filteredData)
            // setTotalRows((prev) => prev - 1)
            setLoaded(false)
          } else {
            // setFaq([])
            setLoaded(false)
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
    [faq],
  )

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      width: '100px',
      sortable: true,
      sort_key: 'id',
      style: {
        fontSize: '14px',
        marginLeft: '0.4%',
      },
    },
    {
      name: 'Question',
      selector: (row, index) => row.question_en,
      width: '30%',
      sortable: true,
      sort_key: 'question_en',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Answer',
      selector: (row, index) => row.answer_en,
      minWidth: '200px',
      sortable: true,
      sort_key: 'answer_en',
      maxWidth: '500px',
      style: {
        fontSize: '14px',
        marginLeft: '0.1%',
      },
    },
    {
      name: 'Status',
      selector: (row, index) => (
        <li
          className={
            row.is_active === 1
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={() => {
            ;(isUserAdmin || getUserAccess.is_edit === 1) && activePage(row.id, row.is_active)
          }}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_active === 1
                  ? 'fas fa-toggle-on text-success'
                  : 'fas fa-toggle-off text-danger'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
      ),
      width: '150px',
      style: {
        fontSize: '14px',
        marginLeft: '0.1%',
      },
    },
    {
      name: 'Action',
      width: '300px',
      style: {
        textAlign: 'center',
        marginTop: '1%',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={() => navigate(`/faqs/view-faq/${row.id}`)}>
                <i className="fas fa-eye fs-6 te"></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_edit === 1) && (
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={() => navigate(`/faqs/edit-faq/${row.id}`)}>
                <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_remove === 1) && (
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={() => deletePage(row.id)}>
                <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
        </ul>
      ),
    },
  ]

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    console.log('payload: ', payload)
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getFaq(filter)
    setPrevFilters({ ...filter, listing_page: 'faq' })
  }, [filter, getFaq, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>FAQ Page</title>
      </Helmet>

      <AppHeader title="FAQ" />
      <div className="px-3">
        {loaded ? (
          <Loader />
        ) : (
          <DataTable
            title="FAQ Page"
            columns={columns}
            data={faq}
            pagination
            paginationServer
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              console.log('page: ', page)
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <div>
                    <CButton
                      color="primary"
                      onClick={() => {
                        applyFilter()
                      }}
                      disabled={!payload.search.length}
                      size="sm"
                      className="mt-3 theme-custom-btn"
                    >
                      Apply
                    </CButton>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      disabled={!payload.search.length}
                      onClick={() => {
                        clearFilter()
                      }}
                      className="ms-3 mt-3 theme-custom-btn-outline"
                    >
                      Clear
                    </CButton>
                  </div>
                  {(isUserAdmin || getUserAccess.is_add === 1) && (
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        navigate('/faqs/add-faq')
                      }}
                      className="ms-3 mt-3 px-4 theme-custom-btn-outline"
                    >
                      + ADD FAQ
                    </CButton>
                  )}
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export default FAQ
