import { callGetAPIAdmin, callMultiPartAdmin, callPostAPIAdmin } from "./BaseService";
import swal from "sweetalert2";

let response = '/api/v1/admin';

// Common Get Data Api Calling
export const CommonGetDataApi = async (type, body, query) => {

    switch (type) {
        case "dashboard":
            return await callPostAPIAdmin(`${response}/dashboard`, body);
        case "user":
            return await callPostAPIAdmin(`${response}/user${query}`, body);
        case "designerRequest":
            return await callPostAPIAdmin(`${response}/designerrequests`, body);
        case "reported-user":
            return await callPostAPIAdmin(`${response}/reporteduser`, body);
        case "subscription":
            return await callPostAPIAdmin(`${response}/subscriptionplans`, body);
        case "earning":
            return await callPostAPIAdmin(`${response}/earning`, body);
        case "static":
            return await callPostAPIAdmin(`${response}/static`, body);
        case "faq":
            return await callPostAPIAdmin(`${response}/faq`, body);
        case "contact":
            return await callPostAPIAdmin(`${response}/contactus`, body);
        default:
            throw new Error("Invalid API call type");
    }
}

// Common Active Inactive Api Calling
export const CommonActiveInActiveApi = async (type, body) => {

    switch (type) {
        case "user":
            return await callPostAPIAdmin(`${response}/activeinactiveuser`, body);
        case "subscription":
            return await callPostAPIAdmin(`${response}/activeinactiveplan`, body);
        case "static":
            return await callPostAPIAdmin(`${response}/activeinactivestatic`, body);
        case "faq":
            return await callPostAPIAdmin(`${response}/activeinactivefaq`, body);
        default:
            throw new Error("Invalid API call type");
    }

}

// Common Delete Api Calling
export const CommonDeleteApi = async (type, body) => {

    switch (type) {
        case "user":
            return await callPostAPIAdmin(`${response}/deleteuser`, body);
        case "subscription":
            return await callPostAPIAdmin(`${response}/deleteplan`, body);
        case "static":
            return await callPostAPIAdmin(`${response}/deletepage`, body);
        case "faq":
            return await callPostAPIAdmin(`${response}/deletefaq`, body);
        default:
            throw new Error("Invalid API call type");
    }
}


// Common Edit Api Calling
export const CommonEditApi = async (type, body) => {

    switch (type) {
        case "user":
            return await callPostAPIAdmin(`${response}/edituser`, body);
        case "designerrequest":
            return await callPostAPIAdmin(`${response}/editdesignerrequest`, body);
        case "subscription":
            return await callPostAPIAdmin(`${response}/editplan`, body);
        case "static":
            return await callPostAPIAdmin(`${response}/staticpage`, body);
        case "faq":
            return await callPostAPIAdmin(`${response}/editfaq`, body);
        case "contact":
            return await callPostAPIAdmin(`${response}/editcontact`, body);
        default:
            throw new Error("Invalid API call type");
    }
}

// Common Add Api Calling
export const CommonAddApi = async (type, body) => {

    switch (type) {
        case "faq":
            return await callPostAPIAdmin(`${response}/addfaq`, body);
        default:
            throw new Error("Invalid API call type");
    }
}



