import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import defaultImage from '../../Dummy.png'
import Loader from '../loader/Loader'
import { CommonEditApi } from 'src/services/SocialService'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NotificationManager } from 'react-notifications'
import { getTemplateApi, getUsers } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import moment from 'moment'
import { AppHeader } from 'src/components'

const ViewTemplate = (props = {}) => {
  const { type } = props
  const navigate = useNavigate()
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])
  const [mainTemplateData, setMainTemplateData] = useState()
  console.log('mainTemplateData: ', mainTemplateData)

  const getTemplate = useCallback((payload) => {
    getTemplateApi(payload).then((response) => {
      if (response.code === 200) {
        if (response?.data?.data[0]?.main_template) {
          setMainTemplateData(response?.data?.data[0]?.main_template[0])
        }
        setUser(response.data.data[0])
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  let editUserSchema

  console.log('user: ', user)
  const initialValues = useMemo(() => {
    const mo_no = user?.country_code + user?.mobile_no
    return {
      thumbnail: user?.thumbnail || '',
      main_thumbnail: user?.main_thumbnail || '',
      category_name: user?.category_name || '',
      name: user?.name || '',
      subcategory_name: user?.subcategory_name || '',
      type: user?.type || '',
      orientation: user?.orientation || '',
      colors: user?.colors || '',
      keywords: user?.keywords || '',
    }
  }, [user?.country_code, user?.mobile_no, user?.thumbnail, user?.main_thumbnail, user?.category_name, user?.name, user?.subcategory_name, user?.type, user?.orientation, user?.colors, user?.keywords])

  const editUserForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: editUserSchema,
    onSubmit: async (values) => {
      console.log(values, 'Values')

      var formData = {
        user_id: id,
        full_name: values.full_name,
        dob: values.dob,
        about: values.about,
        portfolio_link: values.portfolio_link,
      }

      const pData = await CommonEditApi('user', JSON.stringify(formData))
      if (pData.code === 1) {
        NotificationManager.success(pData.message, 'Success!')
        navigate('/user')
      } else {
        NotificationManager.error(pData.message, 'Opps!')
      }
    },
  })

  console.log('errors: ', editUserForm.errors, 'values: ', editUserForm.values)
  console.log('editUserForm: ', editUserForm.values)

  useEffect(() => {
    getTemplate({ template_id: id })
  }, [getTemplate, id])

  return (
    <>
      <Helmet>
        <title>View Template Page</title>
      </Helmet>
      <AppHeader title="View Template" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="container-fluid mt-2 px-3">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form name="edituser" id="edituser" onSubmit={editUserForm.handleSubmit}>
                    <div className="mb-3 row ">
                      <div className=' col-12 col-xl-7 col-lg-7 col-md-7 col-sm-7 '>
                        <div className='template_view_Main'>
                          <div className="template_view">
                            <img
                              src={editUserForm.values.main_thumbnail || editUserForm.values.thumbnail}
                              id="output"
                              alt="Profile"
                              className=" full-width-height"
                            />
                          </div>

                        </div>
                      </div>

                      <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-5">
                        <label htmlFor="name" className="form-label userLabel">
                          Template Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          defaultValue={editUserForm.values.name}
                          placeholder="Enter Template Name"
                          disabled={type === 'view' ? true : false}
                        />
                        <label htmlFor="category_name" className="form-label userLabel">
                          Category
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="category_name"
                          id="category_name"
                          defaultValue={editUserForm.values.category_name}
                          placeholder="Select Category"
                          disabled={type === 'view' ? true : false}
                        />
                        <label htmlFor="subcategory_name" className="form-label userLabel">
                          Sub Category
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="subcategory_name"
                          id="subcategory_name"
                          defaultValue={editUserForm.values.subcategory_name}
                          placeholder="Select Sub Category"
                          disabled={type === 'view' ? true : false}
                        />
                        <label htmlFor="orientation" className="form-label userLabel">
                          Template Orientation
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="orientation"
                          id="orientation"
                          defaultValue={editUserForm.values.orientation}
                          placeholder="Enter Template Orientation"
                          disabled={type === 'view' ? true : false}
                        />
                        {/* <label htmlFor="orientation" className="form-label userLabel">
                          Template Colors
                        </label>
                        <div className="d-flex">
                          {editUserForm.values.colors.length > 0 &&
                            editUserForm.values.colors.map((item) => (
                              <>
                                <div
                                  className="m-1"
                                  style={{
                                    height: '2em',
                                    width: '2em',
                                    border: "1px solid black",
                                    borderRadius: '50%',
                                    background: `${item.color}`,
                                  }}
                                />
                              </>
                            ))}
                        </div> */}
                        <label htmlFor="orientation" className="form-label userLabel">
                          Template Keywords
                        </label>
                        <div className="d-flex">
                          <p>
                            {editUserForm.values.keywords.length > 0 &&
                              editUserForm.values.keywords.map((item, index) => (
                                <>
                                  {item.keyword}
                                  {index < editUserForm.values.keywords.length - 1 && ' , '}
                                </>
                              ))}
                          </p>
                        </div>
                      </div>
                    </div>

                    {mainTemplateData && (
                      <>
                        <div className="d-flex justify-content-center m-4">
                          <h2> Parent Template Details: </h2>
                        </div>
                        <div className="mb-3 row template_view_Main">
                          <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-7 template_view">
                            <img
                              src={mainTemplateData?.main_thumbnail || mainTemplateData?.thumbnail}
                              id="output"
                              alt="Profile"
                              className=" full-width-height"
                            />
                          </div>

                          <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-5">
                            <label htmlFor="name" className="form-label userLabel">
                              Template Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              defaultValue={mainTemplateData.name}
                              placeholder="Enter Template Name"
                              disabled={type === 'view' ? true : false}
                            />
                            <label htmlFor="category_name" className="form-label userLabel">
                              Category
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="category_name"
                              id="category_name"
                              defaultValue={mainTemplateData.category_name}
                              placeholder="Select Category"
                              disabled={type === 'view' ? true : false}
                            />
                            <label htmlFor="subcategory_name" className="form-label userLabel">
                              Sub Category
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="subcategory_name"
                              id="subcategory_name"
                              defaultValue={mainTemplateData.subcategory_name}
                              placeholder="Select Sub Category"
                              disabled={type === 'view' ? true : false}
                            />
                            <label htmlFor="orientation" className="form-label userLabel">
                              Template Orientation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="orientation"
                              id="orientation"
                              defaultValue={mainTemplateData.orientation}
                              placeholder="Enter Template Orientation"
                              disabled={type === 'view' ? true : false}
                            />
                            <label htmlFor="orientation" className="form-label userLabel">
                              Template Colors
                            </label>
                            <div className="d-flex">
                              {mainTemplateData.colors &&
                                mainTemplateData.colors.length > 0 &&
                                mainTemplateData.colors.map((item) => (
                                  <>
                                    <div
                                      className="m-1"
                                      style={{
                                        height: '2em',
                                        width: '2em',
                                        border: "1 px solid black",
                                        borderRadius: '50%',
                                        background: `${item.color}`,
                                      }}
                                    />
                                  </>
                                ))}
                            </div>
                            <label htmlFor="orientation" className="form-label userLabel">
                              Template Keywords
                            </label>
                            <div className="d-flex">
                              <p>
                                {mainTemplateData.keywords &&
                                  mainTemplateData.keywords.length > 0 &&
                                  mainTemplateData.keywords.map((item, index) => (
                                    <>
                                      {item.keyword}
                                      {index < mainTemplateData.keywords.length - 1 && ' , '}
                                    </>
                                  ))}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="d-flex justify-content-end ">
                      <Link className="btn btn-outline-danger me-2" to={-1}>
                        <i className="fas fa-arrow-left" /> Back
                      </Link>
                      {type === 'edit-user' ? (
                        <button type="submit" className="btn btn-outline-success">
                          {' '}
                          Save{' '}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

ViewTemplate.propTypes = {
  type: PropTypes.string,
}

export default ViewTemplate
