import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { blockUnblockSubAdmin, deleteSubAdmin, getSubAdmin } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import moment from 'moment'
import { CButton } from '@coreui/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'

const SubAdmin = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Sub Admin')
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'a.id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return { page: 1, type: '', search: '', limit: pageLength, ...sorting }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'subadmin' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'subadmin' && { ...prevFilters }),
  })
  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const navigate = useNavigate()

  const getAllSubAdmins = useCallback((payload) => {
    getSubAdmin(payload).then((response) => {
      if (response.code === 200) {
        setUser(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  const handleBlockUnblockSubAdmin = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Unblock it!' : 'Yes, Block it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        minWidth: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            blockUnblockSubAdmin({ subadmin_id: id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                setUser((prev) =>
                  prev.map((item) => {
                    if (id === item.id) {
                      item.is_block = status === 0 ? 1 : 0
                    }
                    return item
                  }),
                )
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const HandleDeleteSubAdmin = async (e, id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            deleteSubAdmin({ subadmin_id: id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                if (user.length >= 2) {
                  getAllSubAdmins(filter)
                } else {
                  setFilter((prev) => {
                    return { ...prev, page: filter.page - 1 }
                  })
                }
                // const filteredData = user.filter((item) => item.id !== id)
                // setUser(filteredData)
                // setTotalRows((prev) => prev - 1)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      sort_key: 'a.id',
      minWidth: '50px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Full Name',
      selector: (row) => row.full_name,
      sortable: true,
      sort_key: 'a.full_name',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      sort_key: 'a.email',
      minWidth: '300px',
      grow: 2,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Mobile Number',
      selector: (row) => (
        <>
          {row.country_code} {row.mobile_no}
        </>
      ),
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'DOB',
      // selector: (row) => moment(row.dob).format('DD-MMM-YYYY'),
      selector: (row) => {
        const date = moment(row.dob);
        return date.isValid() ? date.format('DD-MM-YYYY') : '-';
      },
      minWidth: '150px',
      style: {
        fontSize: '14px',
      },
    },
    (isUserAdmin || getUserAccess.is_edit === 1) && {
      name: 'Block/Unblock',
      minWidth: '150px',
      alignItems: 'center',
      cell: (row) => (
        //  <OverlayTrigger placement="top" overlay={<Tooltip>Block/Unblock</Tooltip>}>
        <li
          className={
            row.is_block === 0
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={() => handleBlockUnblockSubAdmin(row.id, row.is_block)}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_block === 0
                  ? 'fas fa-toggle-off text-success'
                  : 'fas fa-toggle-on text-danger'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
        //  </OverlayTrigger  >
      ),
    },
    {
      name: 'Action',
      minWidth: '250px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/sub-admin/view/${row.id}`)}
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger >
          )}
          {(isUserAdmin || getUserAccess.is_edit === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  navigate(`/sub-admin/edit/${row.id}`)
                }}
              >
                <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger  >
          )}
          {(isUserAdmin || getUserAccess.is_remove === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={(e) => HandleDeleteSubAdmin(e, row.id)}>
                <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger  >
          )}
        </ul>
      ),
    },
  ]

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    console.log('filter: ', filter);
    getAllSubAdmins(filter)
    setPrevFilters({ ...filter, listing_page: 'subadmin' })
  }, [filter, getAllSubAdmins, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Sub Admin Page</title>
      </Helmet>
      <AppHeader title="Sub Admin" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            title={'Sub Admin'}
            columns={columns}
            data={user}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between  ">
                  <div>
                    <CButton
                      color="primary"
                      onClick={() => {
                        applyFilter()
                      }}
                      disabled={!payload?.search?.length}
                      size="sm"
                      className="mt-3 theme-custom-btn"
                    >
                      Apply
                    </CButton>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      disabled={!payload?.search?.length}
                      onClick={() => {
                        clearFilter()
                      }}
                      className="ms-3 mt-3 theme-custom-btn-outline"
                    >
                      Clear
                    </CButton>
                  </div>
                  {(isUserAdmin || getUserAccess.is_add === 1) && (
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        navigate('/sub-admin/add')
                      }}
                      data-coreui-toggle="modal"
                      data-coreui-target="#staticBackdrop"
                      className="ms-3 mt-3 px-4 theme-custom-btn-outline"
                    >
                      + ADD SUB ADMIN
                    </CButton>
                  )}
                </div>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default SubAdmin
