import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import defaultImage from '../../Dummy.png'
import Loader from '../loader/Loader'
import { Field, FieldArray, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { addSubAdmin, editSubAdmin, getSubAdmin, getSubAdminRoles } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import { AppHeader } from 'src/components'
import { PhoneNumberUtil } from 'google-libphonenumber'
import uploadToS3 from 'src/common/s3AwsService'

const AddEditViewSubAdmin = (props = {}) => {
  const { type } = props
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])
  const [isImgChange, setIsImgChange] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [subAdminRights, setSubAdminRights] = useState([])
  const phoneUtil = PhoneNumberUtil.getInstance()
  const navigate = useNavigate()
  const isPhoneValid = useCallback(
    (phone) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
      } catch (error) {
        return false
      }
    },
    [phoneUtil],
  )

  const getUser = useCallback((payload) => {
    getSubAdmin(payload).then((response) => {
      if (response.code === 200) {
        setUser(response.data.data[0])
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  const getSubAdminRolesList = useCallback((payload) => {
    getSubAdminRoles(payload).then((response) => {
      console.log('response: subadmin rights', response)
      if (response?.code === 200) {
        setSubAdminRights(response.data)
        setLoaded(false)
      } else {
        setSubAdminRights([])
        setLoaded(false)
      }
    })
  }, [])

  const initialValues = useMemo(() => {
    const mo_no = user?.mobile_no
    console.log('initialValues====>: ', user?.permissions, subAdminRights)
    return {
      full_name: user?.full_name || '',
      dob: user?.dob?.length ? moment(user?.dob).format('YYYY-MM-DD') : '',
      email: user?.email || '',
      mobile_no: mo_no || '',
      password: '',
      profile_image: user?.profile_image || defaultImage,
      rights: user?.permissions
        ? subAdminRights?.map((item) => {
            const permission = user?.permissions?.find((val) => val.role_id === item?.id)
            return {
              ...item,
              ...permission,
              permissions: {
                is_add: permission?.is_add,
                is_edit: permission?.is_edit,
                is_view: permission?.is_view,
                is_remove: permission?.is_remove,
              },
            }
          })
        : subAdminRights,
    }
  }, [
    subAdminRights,
    user?.dob,
    user?.email,
    user?.full_name,
    user?.mobile_no,
    user?.permissions,
    user?.profile_image,
  ])

  const ediSubAdminSchema = useCallback(() => {
    return Yup.object().shape({
      full_name: Yup.string()
        .required('Please enter Full Name')
        .min(2, 'Too Short!')
        .max(30, 'Too Long!'), // .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
      email: Yup.string().required('Please enter Email').email('*Please enter valid email'),
      dob: Yup.string().test('dob', 'You must be at least 18 years old', function (value) {
        console.log('value: ================>', value)
        if (value) {
          const today = moment()
          const birthdateMoment = moment(value, 'YYYY-MM-DD')
          const age = today.diff(birthdateMoment, 'years')
          return age >= 18
        } else {
          return true
        }
      }),
      mobile_no: Yup.string().test(
        'phoneValidation',
        'Please enter a valid mobile number',
        (phone) => {
          if (phone) {
            return isPhoneValid(phone)
          } else {
            return true
          }
        },
      ),
      ...(type === 'add' && {
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
          )
          .required('Password is required'),
      }),
    })
  }, [isPhoneValid, type])

  const handleSubmit = useCallback(
    (values) => {
      const payload = {
        ...values,
        ...(id && { subadmin_id: id }),
      }

      const handlePromise = (promise) => {
        return new Promise((resolve, reject) => {
          promise
            .then((response) => {
              console.log('response:', typeof response, response)
              if (response.code === 200) {
                resolve(response)
                if (type === 'add') {
                  navigate('/sub-admin')
                }
              } else {
                reject(response)
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      }

      const processUpload = async () => {
        if (isImgChange) {
          const response = await uploadToS3(profileImage, 'admin')
          payload.profile_image = response.key
          return await handlePromise(id ? editSubAdmin(payload) : addSubAdmin(payload))
        } else {
          const { profile_image, ...restpayload } = payload
          return await handlePromise(id ? editSubAdmin(restpayload) : addSubAdmin(restpayload))
        }
      }

      showPromiseNotification(processUpload())
    },
    [id, isImgChange, navigate, profileImage, type],
  )

  const handleImageChange = useCallback((formik, e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        // setImagePreview(reader.result)
        formik.setFieldValue('profile_image', reader?.result)
      }
      reader.readAsDataURL(file)
      setIsImgChange(true)
      setProfileImage(file)
    }
  }, [])

  const exclusionRules = [
    { moduleName: 'User', permissionValue: ['is_add'] },
    { moduleName: 'Designer Requests', permissionValue: ['is_add', 'is_remove'] },
    { moduleName: 'CMS Pages', permissionValue: ['is_add', 'is_remove'] },
    { moduleName: 'ContactUs Queries', permissionValue: ['is_add', 'is_remove'] },
    { moduleName: 'Templates', permissionValue: ['is_add'] },
    { moduleName: 'Rating Reviews', permissionValue: ['is_add', 'is_remove'] },
    { moduleName: 'Subscription', permissionValue: ['is_add', 'is_remove'] },
  ]

  useEffect(() => {
    if (id) {
      getUser({ id })
    }
    getSubAdminRolesList({ id })
  }, [getSubAdminRolesList, getUser, id])

  return (
    <>
      <Helmet>
        <title>User {type === 'edit-user' ? 'Edit' : 'View'} Page</title>
      </Helmet>
      <AppHeader title="View Profile" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="container-fluid mt-2 px-3">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={ediSubAdminSchema}
                onSubmit={(value) => {
                  console.log('value: ========>', value)
                  handleSubmit(value)
                }}
              >
                {(formik) => {
                  const {
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    submitCount,
                    initialValues,
                  } = formik
                  console.log('initialValues: ', initialValues)
                  console.log('Formik values: ', values)
                  return (
                    <Form name="edituser" id="edituser">
                      <div className="d-flex justify-content-center">
                        <div className="admin-profile mb-3">
                          <label className={'-label custom-hover'} htmlFor="file">
                            <i className="fas fa-camera"></i>
                            <span>Change Image</span>
                          </label>
                          <input
                            className="img-input"
                            id="file"
                            // {...register('profile_image')}
                            accept="image/*"
                            type="file"
                            onChange={(e) => handleImageChange(formik, e)}
                            // disabled={type !== 'edit'}
                          />
                          <img
                            src={values?.profile_image}
                            // src={defaultImage}
                            id="output"
                            alt="Profile"
                            width="200"
                            className="img-thumbnail"
                          />
                        </div>
                      </div>
                      <div className="d-flex row">
                        <div className="mb-3 row col-12 col-lg-6 col-md-12 col-sm-12 h-100">
                          <div className="col-12 mb-3 ">
                            <label htmlFor="full_name" className="form-label userLabel">
                              <span className="text-danger">*</span> Full Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="full_name"
                              id="full_name"
                              placeholder="Enter your Full Name"
                              value={values.full_name}
                              onChange={(e) => handleChange(e)}
                              disabled={type === 'view'}
                            />
                            {errors.full_name && submitCount ? (
                              <span className="error mt-2">{errors.full_name}</span>
                            ) : null}
                          </div>

                          <div className="col-12 mb-3 ">
                            <label htmlFor="email" className="form-label userLabel">
                              <span className="text-danger">*</span> Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="email"
                              value={values.email}
                              placeholder="Enter Your Email"
                              onChange={(e) => handleChange(e)}
                              disabled={type === 'view'}
                            />
                            {errors.email && submitCount ? (
                              <span className="error mt-2">{errors.email}</span>
                            ) : null}
                          </div>
                          {type === 'add' && (
                            <div className="col-12 mb-3 ">
                              <label htmlFor="password" className="form-label userLabel">
                                <span className="text-danger">*</span> Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                value={values.password}
                                placeholder="Enter Your Password"
                                onChange={(e) => handleChange(e)}
                                disabled={type === 'view'}
                              />
                              {errors.password && submitCount ? (
                                <span className="error mt-2">{errors.password}</span>
                              ) : null}
                            </div>
                          )}

                          <div className="col-12 mb-3">
                            <label htmlFor="email" className="form-label userLabel">
                              Mobile Number
                            </label>
                            <PhoneInput
                              defaultCountry="ua"
                              value={values.mobile_no}
                              className="form-group"
                              disabled={type === 'view'}
                              inputProps={{
                                className: 'form-control',
                                name: 'PhoneNumber',
                              }}
                              inputStyle={{
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                                borderLeft: '0',
                                paddingLeft: '0',
                              }}
                              countrySelectorStyleProps={{
                                buttonContentWrapperClassName: 'form-control h-100',
                                buttonContentWrapperStyle: {
                                  borderTopRightRadius: '0',
                                  borderBottomRightRadius: '0',
                                  border: '1px solid #E4EAF7',
                                  paddingRight: '5px',
                                  borderRight: '0',
                                  backgroundColor: type === 'view' ? '#d8dbe0' : '',
                                },

                                buttonClassName: 'border-0 h-100',
                              }}
                              onChange={(phone) => {
                                setFieldValue('mobile_no', phone)
                              }}
                            />
                            {errors.mobile_no && submitCount ? (
                              <span className="error">{errors.mobile_no}</span>
                            ) : null}
                          </div>

                          <div className="col-12 mb-3 ">
                            <label
                              htmlFor="dob"
                              className="form-label userLabel"
                              // style={{ marginLeft: "-3%" }}
                            >
                              Date of Birth
                            </label>

                            <input
                              type="date"
                              className="form form-control"
                              name="dob"
                              id="dob"
                              placeholder="Your Date of Birth"
                              value={values.dob}
                              onChange={(e) => handleChange(e)}
                              // style={{ width: "100%" }}
                              disabled={type === 'view'}
                            />
                            {errors.dob && submitCount ? (
                              <span className="error mt-2">{errors.dob}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-12 col-sm-12">
                          <p className="userLabel">Permissions</p>
                          <div className="mt-3 w-100 overflow-auto ">
                            <FieldArray name="rights">
                              {({ push, remove }) => (
                                <>
                                  {values?.rights &&
                                    values?.rights.map((module, index) => {
                                      return (
                                        <div className="row w-100 mb-3" key={index}>
                                          <div className="col-3 d-flex align-items-center justify-content-start">
                                            <p
                                              className="d-inline w-100 m-0"
                                              style={{ minWidth: '100px' }}
                                            >
                                              {module.name}
                                            </p>
                                          </div>
                                          <div className="col-9 d-flex align-items-center justify-content-start">
                                            {[
                                              { label: 'Add', value: 'is_add' },
                                              { label: 'Update', value: 'is_edit' },
                                              { label: 'Delete', value: 'is_remove' },
                                              { label: 'View', value: 'is_view' },
                                            ].map((permission, permissionIndex) => {
                                              const isExcluded = exclusionRules.some(
                                                (rule) =>
                                                  rule.moduleName === module.name &&
                                                  rule.permissionValue.includes(permission.value),
                                              )
                                              return (
                                                <div
                                                  key={permissionIndex}
                                                  style={{ minWidth: '100px' }}
                                                  className="form-check p-2"
                                                >
                                                  <Field
                                                    type="checkbox"
                                                    className="form-check-input permition-btn"
                                                    id={`${index}-${permission.value}`}
                                                    name={`rights.${index}.permissions.[${permission.value}]`}
                                                    // value={permission}
                                                    onChange={(e) => {
                                                      const checked = e.target.checked
                                                      setFieldValue(
                                                        `rights.${index}.permissions.${permission.value}`,
                                                        checked,
                                                      )
                                                    }}
                                                    disabled={isExcluded || type === 'view'}
                                                  />
                                                  <label
                                                    className="form-check-label permition-lable ms-1"
                                                    htmlFor={`${index}-${permission.value}`}
                                                  >
                                                    {permission.label}
                                                  </label>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      )
                                    })}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <Link className="btn btn-outline-danger me-2" to="/sub-admin">
                          <i className="fas fa-arrow-left" /> Back
                        </Link>
                        {type !== 'view' ? (
                          <button type="submit" className="btn btn-outline-success">
                            Save
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AddEditViewSubAdmin.propTypes = {
  type: PropTypes.string,
}

export default AddEditViewSubAdmin
