import { axiosClient } from './apiClient'

//============================================================================//
//============================ user api start=================================//
//============================================================================//

export function login(data) {
  return axiosClient.post('/api/v1/admin/auth/login', data)
}

export function logout(data) {
  return axiosClient.post('/api/v1/admin/auth/logout', data)
}

export function forgotPassword(data) {
  return axiosClient.post('/api/v1/admin/auth/forgot-password', data)
}

export function resetPassword(data) {
  return axiosClient.post('/api/v1/admin/auth/reset-password', data)
}

export function getProfile(data) {
  return axiosClient.post('/api/v1/admin/auth/get-profile', data)
}

export function editProfile(data) {
  return axiosClient.post('/api/v1/admin/auth/edit-profile', data)
}

export function getSubAdmin(data) {
  return axiosClient.post('/api/v1/admin/subadmin/get-subadmin', data)
}

export function addSubAdmin(data) {
  return axiosClient.post('/api/v1/admin/subadmin/add-subadmin', data)
}

export function editSubAdmin(data) {
  return axiosClient.post('/api/v1/admin/subadmin/edit-subadmin', data)
}

export function getSubAdminRoles(data) {
  return axiosClient.post('/api/v1/admin/subadmin/get-subadmin-roles', data)
}

export function deleteSubAdmin(data) {
  return axiosClient.post('/api/v1/admin/subadmin/delete-subadmin', data)
}

export function blockUnblockSubAdmin(data) {
  return axiosClient.post('/api/v1/admin/subadmin/block-unblock-subadmin', data)
}

export function dashboard(data) {
  return axiosClient.post('/api/v1/admin/user/dashboard')
}

export function getUsers(data) {
  return axiosClient.post('/api/v1/admin/user/get-user', data)
}

export function blockUnblockUser(data) {
  return axiosClient.post('/api/v1/admin/user/block-unblock-user', data)
}

export function deleteUserApi(data) {
  return axiosClient.post('/api/v1/admin/user/delete-user', data)
}

//============================================================================//
//============================ user api end  =================================//
//============================================================================//

//============================================================================//
//============================ FAQ api start =================================//
//============================================================================//

export function getFAQ(data) {
  return axiosClient.post('/api/v1/admin/cms/get-faq', data)
}

export function activeInactiveFAQ(data) {
  return axiosClient.post('/api/v1/admin/cms/active-inactive-faq', data)
}

export function deleteFAQ(data) {
  return axiosClient.post('/api/v1/admin/cms/delete-faq', data)
}

export function editFAQ(data) {
  return axiosClient.post('/api/v1/admin/cms/edit-faq', data)
}
export function addFAQ(data) {
  return axiosClient.post('/api/v1/admin/cms/add-faq', data)
}

export function getContactUs(data) {
  return axiosClient.post('/api/v1/admin/cms/get-contact-us', data)
}

export function editContactUs(data) {
  return axiosClient.post('/api/v1/admin/cms/edit-contact-us', data)
}

//============================================================================//
//============================ FAQ api end   =================================//
//============================================================================//

//====================================================================================//
//============================ static page api start =================================//
//====================================================================================//

export function getStaticPageApi(data) {
  return axiosClient.post('/api/v1/admin/cms/get-static-page', data)
}

export function editStaticPageApi(data) {
  return axiosClient.post('/api/v1/admin/cms/edit-static-page', data)
}

export function activeInactiveStaticPage(data) {
  return axiosClient.post('/api/v1/admin/cms/active-inactive-staticPage', data)
}

export function deleteStaticPage(data) {
  return axiosClient.post('/api/v1/admin/cms/delete-static-page', data)
}

//============================================================================//
//============================ static page api end =================================//
//============================================================================//

//============================================================================//
//============================ designer request api start   =================================//
//============================================================================//

export function getRequestedDesigner(data) {
  return axiosClient.post('/api/v1/admin/user/get-designer-request', data)
}

export function editRequestedDesigner(data) {
  return axiosClient.post('/api/v1/admin/user/edit-designer-request', data)
}

//====================================================================================//
//============================ designer request api end =================================//
//====================================================================================//
//============================================================================//
//============================ designer category api start   =================================//
//============================================================================//

export function getCategory(data) {
  return axiosClient.post('/api/v1/admin/category/get-category', data)
}

export function addCategory(data) {
  return axiosClient.post('/api/v1/admin/category/add-category', data)
}

export function editCategory(data) {
  return axiosClient.post('/api/v1/admin/category/edit-category', data)
}

export function deleteCategory(data) {
  return axiosClient.post('/api/v1/admin/category/delete-category', data)
}

export function activeInactiveCategory(data) {
  return axiosClient.post('/api/v1/admin/category/active-inactive-category', data)
}

//==========================================================================================//
//============================ designer category api end   =================================//
//==========================================================================================//

//============================================================================================//
//============================ social media icon api start   =================================//
//============================================================================================//

export function getSocialMediaIconApi(data) {
  return axiosClient.post('/api/v1/admin/assets/get-social-media-icon', data)
}

export function addSocialMediaIconApi(data) {
  return axiosClient.post('/api/v1/admin/assets/add-social-media-icon', data)
}

export function editSocialMediaIconApi(data) {
  return axiosClient.post('/api/v1/admin/assets/edit-social-media-icon', data)
}

export function activeInactiveSocialMediaIconApi(data) {
  return axiosClient.post('/api/v1/admin/assets/active-inactive-social-media-icon', data)
}

export function deleteSocialMediaIconApi(data) {
  return axiosClient.post('/api/v1/admin/assets/delete-social-media-icon', data)
}

//============================================================================================//
//============================ social media icon api start   =================================//
//============================================================================================//

//============================================================================================//
//============================ shape api start   =================================//
//============================================================================================//

export function getShapesApi(data) {
  return axiosClient.post('/api/v1/admin/assets/get-shapes', data)
}

export function addShapeApi(data) {
  return axiosClient.post('/api/v1/admin/assets/add-shape', data)
}

export function editShapeApi(data) {
  return axiosClient.post('/api/v1/admin/assets/edit-shape', data)
}

export function activeInactiveShapeApi(data) {
  return axiosClient.post('/api/v1/admin/assets/active-inactive-shape', data)
}

export function deleteShapeApi(data) {
  return axiosClient.post('/api/v1/admin/assets/delete-shape', data)
}

//============================================================================================//
//============================ shape api start   =================================//
//============================================================================================//

//============================================================================================//
//============================ sticker api start   =================================//
//============================================================================================//

export function getStickersApi(data) {
  return axiosClient.post('/api/v1/admin/assets/get-stickers', data)
}

export function addStickerApi(data) {
  return axiosClient.post('/api/v1/admin/assets/add-sticker', data)
}

export function editStickerApi(data) {
  return axiosClient.post('/api/v1/admin/assets/edit-sticker', data)
}

export function activeInactiveStickerApi(data) {
  return axiosClient.post('/api/v1/admin/assets/active-inactive-sticker', data)
}

export function deleteStickerApi(data) {
  return axiosClient.post('/api/v1/admin/assets/delete-sticker', data)
}

//============================================================================================//
//============================ sticker api start   =================================//
//============================================================================================//
//============================================================================================//
//============================ clipart api start   =================================//
//============================================================================================//

export function getClipartsApi(data) {
  return axiosClient.post('/api/v1/admin/assets/get-clipart', data)
}

export function addClipartApi(data) {
  return axiosClient.post('/api/v1/admin/assets/add-clipart', data)
}

export function editClipartApi(data) {
  return axiosClient.post('/api/v1/admin/assets/edit-clipart', data)
}

export function activeInactiveClipartApi(data) {
  return axiosClient.post('/api/v1/admin/assets/active-inactive-clipart', data)
}

export function deleteClipartApi(data) {
  return axiosClient.post('/api/v1/admin/assets/delete-clipart', data)
}

//============================================================================================//
//============================ clipart api end   =================================//
//============================================================================================//
//============================================================================================//
//============================ font api start   =================================//
//============================================================================================//

export function getFontsApi(data) {
  return axiosClient.post('/api/v1/admin/assets/get-fonts', data)
}

export function addFontApi(data) {
  return axiosClient.post('/api/v1/admin/assets/add-font', data)
}

export function editFontApi(data) {
  return axiosClient.post('/api/v1/admin/assets/edit-font', data)
}

export function activeInactiveFontApi(data) {
  return axiosClient.post('/api/v1/admin/assets/active-inactive-font', data)
}

export function deleteFontApi(data) {
  return axiosClient.post('/api/v1/admin/assets/delete-font', data)
}

//============================================================================================//
//============================ font api end   =================================//
//============================================================================================//

//============================================================================================//
//============================ Template api start   =================================//
//============================================================================================//

export function getTemplateApi(data) {
  return axiosClient.post('/api/v1/admin/template/get-template', data)
}
export function editTemplateStatusApi(data) {
  return axiosClient.post('/api/v1/admin/template/edit-template-status', data)
}
export function activeInactiveTemplateApi(data) {
  return axiosClient.post('/api/v1/admin/template/active-inactive-template', data)
}

export function deleteTemplateApi(data) {
  return axiosClient.post('/api/v1/admin/template/delete-template', data)
}

//============================================================================================//
//============================ Template api end   =================================//
//============================================================================================//

//============================================================================================//
//============================ Rating and Review api start   =================================//
//============================================================================================//

export function getReviewApi(data) {
  return axiosClient.post('/api/v1/admin/user/get-review', data)
}

export function activeInactiveRatingReviewApi(data) {
  return axiosClient.post('/api/v1/admin/user/active-inactive-review', data)
}

//============================================================================================//
//============================ Rating and Review api end   =================================//
//============================================================================================//
//============================================================================================//
//============================ Rating and Review api start   =================================//
//============================================================================================//

export function getNotificationApi(data) {
  return axiosClient.post('/api/v1/admin/user/get-notification', data)
}

export function sendNotificationApi(data) {
  return axiosClient.post('/api/v1/admin/user/send-notification', data)
}

//============================================================================================//
//============================ Rating and Review api end   =================================//
//============================================================================================//

export function getSubscription(data) {
  return axiosClient.post('/api/v1/admin/subscription/get-subscription', data)
}
export function addSubscription(data) {
  return axiosClient.post('/api/v1/admin/subscription/add-subscription', data)
}
export function editSubscription(data) {
  return axiosClient.post('/api/v1/admin/subscription/edit-subscription', data)
}

export function myEarning(data) {
  return axiosClient.post('/api/v1/admin/earning/get-earning', data)
}

export function MonthlySettlement(data) {
  return axiosClient.post('/api/v1/admin/earning/get-designer-earning', data)
}

export function MonthlySettlementDetails(data) {
  return axiosClient.post('/api/v1/admin/earning/get-designer-earning-details', data)
}

export function updateEarningStatus(data) {
  return axiosClient.post('/api/v1/admin/earning/update-designer-earning-details', data)
}



export function getUserReport(data) {
  return axiosClient.post('/api/v1/admin/report/get-user-report', data)
}

export function getDesignerReport(data) {
  return axiosClient.post('/api/v1/admin/report/get-designer-report', data)
}

export function getActiveTemplateReport(data) {
  return axiosClient.post('/api/v1/admin/report/get-active-template-report', data)
}


