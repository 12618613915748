import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import logo from '../Logo.png'

import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { useNavigate } from 'react-router-dom'
import { hasPermissionForModule } from 'src/common/LocalStorageService'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const navigate = useNavigate()

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      style={{ backgroundColor: '#9d446e' }}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand
        className="d-none d-md-flex"
        to="/"
        style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }}
      >
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
        <img
          // src={logo}
          src={process.env.PUBLIC_URL + '/Logosvg.svg'}
          className="clear-fix my-2"
          style={{
            height: 'auto',
            width: '90%',
            border: '0px',
            aspectRatio: '3/2',
            objectFit: 'contain',
            mixBlendMode: 'color-dodge',
          }}
          onClick={() => navigate('/')}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav
            items={navigation.filter((item) => item.isDefault || hasPermissionForModule(item.name))}
          />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
