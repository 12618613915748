import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilEnvelopeClosed } from '@coreui/icons'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { saveUserDataToLocal } from 'src/common/LocalStorageService'
import { forgotPassword, login } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'

const ForgotPassword = () => {
  let navigate = useNavigate()

  const LoginSchema = Yup.object({
    email: Yup.string().required('* Please Enter Email').email('Enter Valid Email'),
  })

  const loginForm = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const myPromise = new Promise((resolved, reject) => {
        forgotPassword(values).then((response) => {
          console.log('response: ', typeof response, response)
          if (response.code === 200) {
            navigate('/')
            resolved(response)
          } else {
            reject(response)
          }
        })
      })
      showPromiseNotification(myPromise)
    },
  })

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer className="w-lg-25  w-md-50 w-sm-100 ">
          <CRow className="justify-content-center">
            <CCol sm={12} md={8} lg={4}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm name="loginForm" onSubmit={loginForm.handleSubmit}>
                      <img src={process.env.PUBLIC_URL + '/Logosvg.svg'}></img>
                      <div className="w-100 mt-5">
                        <h3 className="text-center mb-4">Forgot Password</h3>
                        <p
                          style={{ fontSize: '14px', color: '#98a6ad', textAlign: 'center' }}
                          className="mb-4"
                        >
                          Enter your email address and we will send you password reset instructions.
                        </p>
                        <div className="my-1 mb-3">
                          <CInputGroup>
                            <CInputGroupText>
                              <CIcon icon={cilEnvelopeClosed} />
                            </CInputGroupText>
                            <CFormInput
                              type="text"
                              name="email"
                              id="email"
                              placeholder="Email"
                              autoComplete="email"
                              onChange={(e) => loginForm.handleChange(e)}
                            />
                          </CInputGroup>

                          {loginForm.errors.email && loginForm.touched.email ? (
                            <span className="error">{loginForm.errors.email}</span>
                          ) : null}
                        </div>

                        <div className="d-flex justify-content-center mt-3">
                          <CButton
                            color="primary"
                            variant="outline"
                            // size="sm"
                            onClick={() => {
                              navigate('/')
                            }}
                            className="theme-custom-btn-outline me-3"
                          >
                            Back
                          </CButton>
                          <CButton
                            color="primary"
                            type="submit"
                            onClick={() => {}}
                            className=" theme-custom-btn"
                          >
                            Submit
                          </CButton>
                        </div>
                      </div>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default ForgotPassword
