import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useRoutes } from 'react-router-dom'
import './scss/style.scss'
import { getToken } from './common/LocalStorageService'
import routes from './routes'
import { history } from './common/helpers'
import ForgotPassword from './views/pages/ForgotPassword.js/ForgotPassword'
import ResetPassword from './views/pages/ResetPages.js/ResetPages'
import PageNotFound from './views/PageNotFound/PageNotFound'
import PrivateLayout from './layout/PrivateLayout'
import DefaultLayout from './layout/DefaultLayout'
import Login from './views/pages/login/Login'

const PublicLayout = () => {
  const navigate = useNavigate()
  const [isRender, setIsRender] = useState(false)

  useEffect(() => {
    const isLoggedIn = getToken()

    if (isLoggedIn) {
      navigate('/dashboard')
    } else {
      setIsRender(true)
    }
  }, [navigate])

  if (!isRender) {
    return null
  }

  return <Outlet />
}

const routeConfig = [
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      { path: '', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
    ],
  },
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        path: '/',
        element: <DefaultLayout />,
        children: [...routes.filter((item) => item.access)],
      },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]

function App() {
  const routeResult = useRoutes(routeConfig)
  history.navigate = useNavigate()
  history.location = useLocation()

  return <div className="App h-100">{routeResult}</div>
}

export default App
