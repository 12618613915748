import axios from 'axios'
import CryptoJS from 'crypto-js'
import { getToken } from 'src/common/LocalStorageService'
import { logOutRedirectCall } from 'src/common/RedirectPathManage'
import { showErrorMessage } from 'src/common/notification'

var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY)
var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY)

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: 'http://localhost:8765/' || process.env.REACT_APP_BASE_URL,
  headers: {
    'api-key': process.env.REACT_APP_API_KEY,
    'Accept-Language': 'en',
    'Content-Type': 'text/plain',
    type: 'admin',
  },
})

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  console.log(request.url, '==>', request.data)
  request.data = bodyEncryption(request.data, true)
  const token = getToken()
  // console.log('token: ', token)
  if (token) {
    console.log('token: ++++', token)
    request.headers['token'] = bodyEncryption(token)
  }
  return request
})

axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data)

    if (response.code === 400) {
      showErrorMessage(response.data.message)
    }
    console.log('response: axiosss ', response)

    return response
  },

  function (error) {
    console.log('error1: ===>', error)
    let res = error.response
    if (!error.response) {
      console.log('error: ', error)
      return Promise.reject(error)
    }

    if (res.status === 401) {
      logOutRedirectCall()
      const response = bodyDecryption(res.data)
      return response
    } else if (
      res.status === 400 ||
      res.status === 409 ||
      res.status === 500 ||
      res.status === 404
    ) {
      const response = bodyDecryption(res.data)
      return response
    } else {
      console.error('Looks like there was a problem. Status Code: ' + res.status)
      return Promise.reject(error)
    }
  },
)

function bodyEncryption(request, isStringify) {
  var request_ = isStringify ? JSON.stringify(request) : request
  var encrypted = CryptoJS.AES.encrypt(request_, key, { iv: iv })
  return encrypted.toString()
}

function bodyDecryption(request) {
  var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv })

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
}

export { axiosClient }
