import {
  cibClockify,
  cibGnuSocial,
  cibPrismic,
  cibScribd,
  cibSquarespace,
  cil3d,
  cilApps,
  cilAsteriskCircle,
  cilClipboard,
  cilContact,
  cilStar,
  cilFont,
  cilImage,
  cilListRich,
  cilMoney,
  cilPeople,
  cilTextShapes,
  cilTextSquare,
  CNavItem
} from '@coreui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import CIcon from '@coreui/icons-react'
import { CCol, CRow, CWidgetStatsC } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { dashboard } from 'src/api/apiHandler'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import PropTypes from 'prop-types'
import { hasPermissionForModule } from 'src/common/LocalStorageService'

const Dashboard = () => {
  let navigate = useNavigate()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const widgetData = [
    {
      color: 'info',
      icon: <CIcon icon={cilPeople} height={36} />,
      value: data.total_user === 0 ? '0' : data.total_user,
      title: 'Users',
      route: '/user',
      name: 'User',
    },
    {
      color: 'success',
      icon: <CIcon icon={cilListRich} height={36} />,
      value: data.total_category === 0 ? '0' : data.total_category,
      title: 'Categories',
      route: '/category',
      name: 'Category',
    },
    {
      color: 'danger',
      icon: <CIcon icon={cilListRich} height={36} />,
      value: data.total_subcategory === 0 ? '0' : data.total_subcategory,
      title: 'Sub Categories',
      route: '/sub-category',
      name: 'Sub Category',
    },
    {
      color: 'primary',
      icon: <CIcon icon={cibPrismic} height={36} />,
      value: data.total_template === 0 ? '0' : data.total_template,
      title: 'Templates',
      route: '/template',
      name: 'Templates',
    },
    {
      color: 'secondary',
      icon: <CIcon icon={cilMoney} height={36} />,
      value: data.total_subscription_plans === 0 ? '0' : data.total_subscription_plans,
      title: 'Subscription Plans',
      route: '/subscription',
      name: 'Subscription Plans',
    },
    {
      color: 'dark',
      icon: <CIcon icon={cibGnuSocial} height={36} />,
      value: data.total_social_icons === 0 ? '0' : data.total_social_icons,
      title: 'Social Media Icons',
      route: '/social-media-icon',
      name: 'Social Media Icons',
    },
    {
      color: 'warning',
      icon: <CIcon icon={cilFont} height={36} />,
      value: data.total_fonts === 0 ? '0' : data.total_fonts,
      title: 'Fonts',
      route: '/fonts',
      name: 'Fonts',
    },
    {
      color: 'info',
      icon: <CIcon icon={cibSquarespace} height={36} />,
      value: data.total_shapes === 0 ? '0' : data.total_shapes,
      title: 'Shapes',
      route: '/shapes',
      name: 'Shapes',
    },
    {
      color: 'danger',
      icon: <CIcon icon={cibClockify} height={36} />,
      value: data.total_cilp_arts === 0 ? '0' : data.total_cilp_arts,
      title: 'Cliparts',
      route: '/clipart',
      name: 'Cliparts',
    },
    {
      color: 'success',
      icon: <CIcon icon={cibScribd} height={36} />,
      value: data.total_stickers === 0 ? '0' : data.total_stickers,
      title: 'Stickers',
      route: '/sticker',
      name: 'Stickers',
    },
    // {
    //   color: 'primary',
    //   icon: <CIcon icon={cilTextSquare} height={36} />,
    //   value: data.total_fancy_texts === 0 ? '0' : data.total_fancy_texts,
    //   title: 'Fancy Texts',
    //   // route: '/',
    // },
    // {
    //   color: 'primary',
    //   icon: <CIcon icon={cilMoney} height={36} />,
    //   value: data.total_income ? data.total_income : '0',
    //   title: 'Total Income',
    //   name: 'Weekly Settlement',
    //   // route: '/',
    // },
    {
      color: 'primary',
      icon: <CIcon icon={cilStar} height={36} />,
      value: data.rating_review ? data.rating_review : '0',
      title: 'Rating Reviews',
      name: 'Rating Reviews',
      route: '/rating-review',
    },
    {
      color: 'secondary',
      icon: <CIcon icon={cilContact} height={36} />,
      value: data.contactus ? data.contactus : '0',
      title: 'Contact Us Queries',
      name: 'Contact Us Queries',
      route: '/contactus',
    },
  ]

  const WidgetStats = ({ color, icon, value, title, route }) => (
    <CCol sm={6} md={2} lg={3}>
      <CWidgetStatsC
        color={color}
        icon={icon}
        value={value}
        title={title}
        inverse
        className="mb-4 userlist"
        onClick={() => route && navigate(route)}
      />
    </CCol>
  )

  WidgetStats.propTypes = {
    color: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  }

  const Widgets = () => (
    <CRow className="w-100 px-3">
      {widgetData
        .filter((item) => item.isDefault || hasPermissionForModule(item.name))
        .map((widget, index) => (
          <WidgetStats key={index} {...widget} />
        ))}
    </CRow>
  )
  const getAdminData = async () => {
    setIsLoading(true)
    dashboard({}).then((response) => {
      if (response.code === 200) {
        setData(response.data[0])
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    getAdminData()
  }, [])

  return (
    <>
      <Helmet>
        <title> Dashboard</title>
      </Helmet>
      <AppHeader title="Dashboard" />
      {isLoading ? <Loader /> : <Widgets />}
    </>
  )
}

export default Dashboard
