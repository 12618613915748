import { FieldArray, Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Loader from '../loader/Loader'
import {
  addFAQ,
  addSubscription,
  editFAQ,
  editSubscription,
  getFAQ,
  getSubscription,
} from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import { AppHeader } from 'src/components'

const AddEditViewSubscription = (props) => {
  const { type } = props
  const { id } = useParams()
  const navigate = useNavigate()

  const [subscription, setSubscription] = useState([])

  const [loaded, setLoaded] = useState(false)

  const getSingleSubscription = useCallback((payload) => {
    getSubscription(payload).then((response) => {
      console.log('response: subbbbb ', response)
      if (response.code === 200) {
        setSubscription(response.data[0])
        setLoaded(false)
      } else {
        setSubscription([])
        setLoaded(false)
      }
    })
  }, [])

  const initialValues = useMemo(() => {
    return {
      ...subscription,
      subtitles: subscription?.subtitle?.length
        ? JSON.parse(subscription.subtitle)
        : [{ subtitle_en: '', subtitle_fr: '', subtitle_port: '' }],
    }
  }, [subscription])

  const editSchema = useCallback(() => {
    return Yup.object({
      name_en: Yup.string()
        .required('Please Enter Name')
        .min(3, 'Too Short!')
        .max(500, 'Too Long!'),
      name_fr: Yup.string()
        .required('Please Enter Name')
        .min(3, 'Too Short!')
        .max(500, 'Too Long!'),
      name_port: Yup.string()
        .required('Please Enter Name')
        .min(3, 'Too Short!')
        .max(500, 'Too Long!'),
      title_en: Yup.string()
        .required('Please Enter Title')
        .min(3, 'Too Short!')
        .max(1000, 'Too Long!'),
      title_fr: Yup.string()
        .required('Please Enter Title')
        .min(3, 'Too Short!')
        .max(1000, 'Too Long!'),
      title_port: Yup.string()
        .required('Please Enter Title')
        .min(3, 'Too Short!')
        .max(1000, 'Too Long!'),
      price_monthly: Yup.number('Please Enter Valid Number').required('Please Enter Price'),
      price_yearly: Yup.number('Please Enter Valid Number').required('Please Enter Price'),
      subtitles: Yup.array().of(
        Yup.object().shape({
          subtitle_en: Yup.string()
            .required('Please Enter Subtitle')
            .min(3, 'Too Short!')
            .max(1000, 'Too Long!'),
          subtitle_fr: Yup.string()
            .required('Please Enter Subtitle')
            .min(3, 'Too Short!')
            .max(1000, 'Too Long!'),
          subtitle_port: Yup.string()
            .required('Please Enter Subtitle')
            .min(3, 'Too Short!')
            .max(1000, 'Too Long!'),
        }),
      ),
    })
  }, [])

  const handleSubmit = useCallback(
    async (values) => {
      console.log('values: ', values)
      var formData = {
        ...values,
        subtitle: JSON.stringify(values.subtitles),
      }

      console.log('formData: ', formData)
      if (id) {
        formData.subscription_id = values?.id
        const myPromise = new Promise((resolve, reject) =>
          editSubscription(formData).then((response) => {
            if (response.code === 200) {
              resolve(response)
              navigate('/subscription')
            } else {
              reject(response)
            }
          }),
        )
        showPromiseNotification(myPromise)
      } else {
        const myPromise = new Promise((resolve, reject) =>
          addSubscription(formData).then((response) => {
            if (response.code === 200) {
              resolve(response)
              navigate('/subscription')
            } else {
              reject(response)
            }
          }),
        )
        showPromiseNotification(myPromise)
      }
    },
    [id, navigate],
  )

  useEffect(() => {
    if (id) {
      getSingleSubscription({ subscription_id: id })
    }
  }, [getSingleSubscription, id])

  return (
    <>
      <Helmet>
        <title>{type === 'edit' ? 'Edit' : type === 'add' ? 'Add' : 'View'} Subscription</title>
      </Helmet>
      <AppHeader
        title={
          type === 'edit' ? 'Edit FAQ' : type === 'add' ? 'Add Subscription' : 'View Subscription'
        }
      />
      {loaded ? (
        <Loader />
      ) : (
        <div className="container-fluid my-2 px-3">
          <div className="card">
            <div
              className="card-header text-dark fw-bolder fs-4"
              style={{ backgroundColor: 'lightpink' }}
            >
              {type === 'edit' ? 'Edit' : type === 'add' ? 'Add' : 'View'} Subscription
            </div>
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={editSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {(formik) => {
                  const { values, errors, handleChange, submitCount } = formik
                  console.log('errors: ', errors)
                  return (
                    <Form>
                      <div className="mb-3 row">
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="name_en" className="form-label userLabel">
                            <span className="text-danger">*</span> Name (English)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name_en"
                            id="name_en"
                            placeholder="Enter Your Subscription Name"
                            value={values.name_en}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.name_en && submitCount ? (
                            <span className="error mt-2">{errors.name_en}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="name_fr" className="form-label userLabel">
                            <span className="text-danger">*</span> Name (French)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name_fr"
                            id="name_fr"
                            placeholder="Enter Your Subscription Name"
                            value={values.name_fr}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.name_fr && submitCount ? (
                            <span className="error mt-2">{errors.name_fr}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="name_port" className="form-label userLabel">
                            <span className="text-danger">*</span> Name (Portuguese)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name_port"
                            id="name_port"
                            placeholder="Enter Your Subscription Name"
                            value={values.name_port}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.name_port && submitCount ? (
                            <span className="error mt-2">{errors.name_port}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="title_en" className="form-label userLabel">
                            <span className="text-danger">*</span> Title (English)
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="title_en"
                            id="title_en"
                            rows={2}
                            placeholder="Enter Your Title"
                            value={values.title_en}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.title_en && submitCount ? (
                            <span className="error mt-2">{errors.title_en}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="title_fr" className="form-label userLabel">
                            <span className="text-danger">*</span> Title (French)
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="title_fr"
                            id="title_fr"
                            rows={2}
                            placeholder="Enter Your Title"
                            value={values.title_fr}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.title_fr && submitCount ? (
                            <span className="error mt-2">{errors.title_fr}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="title_port" className="form-label userLabel">
                            <span className="text-danger">*</span> Title (Portuguese)
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="title_port"
                            id="title_port"
                            rows={2}
                            placeholder="Enter Your Title"
                            value={values.title_port}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.title_port && submitCount ? (
                            <span className="error mt-2">{errors.title_port}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="price_monthly" className="form-label userLabel">
                            <span className="text-danger">*</span> Price (Monthly)
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="price_monthly"
                            id="price_monthly"
                            placeholder="Enter Your Price"
                            value={values.price_monthly}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.price_monthly && submitCount ? (
                            <span className="error mt-2">{errors.price_monthly}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="price_yearly" className="form-label userLabel">
                            <span className="text-danger">*</span> Price (Yearly)
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="price_yearly"
                            id="price_yearly"
                            placeholder="Enter Your Price"
                            value={values.price_yearly}
                            onChange={(e) => handleChange(e)}
                            disabled={type === 'view'}
                          />
                          {errors.price_yearly && submitCount ? (
                            <span className="error mt-2">{errors.price_yearly}</span>
                          ) : null}
                        </div>
                      </div>

                      <div className="row border p-3">
                        <FieldArray name="subtitles">
                          {({ remove, push }) => (
                            <>
                              {values.subtitles.length > 0 &&
                                values.subtitles.map((subtitle, index) => (
                                  <div key={index}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>{index + 1}. Sub Title</div>
                                      {values?.subtitles.length > 1 && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-danger my-2"
                                          onClick={() => remove(index)}
                                          disabled={type === 'view'}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </div>

                                    <div className="mb-3 row border p-3">
                                      <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <label
                                          htmlFor={`subtitles.${index}.subtitle_en`}
                                          className="form-label userLabel"
                                        >
                                          <span className="text-danger">*</span> English
                                        </label>
                                        <Field
                                          as="textarea"
                                          className="form-control"
                                          name={`subtitles.${index}.subtitle_en`}
                                          rows={2}
                                          placeholder="Enter Your Sub Title"
                                          disabled={type === 'view'}
                                        />
                                        <ErrorMessage
                                          name={`subtitles.${index}.subtitle_en`}
                                          component="span"
                                          className="error mt-2"
                                        />
                                      </div>
                                      <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <label
                                          htmlFor={`subtitles.${index}.subtitle_fr`}
                                          className="form-label userLabel"
                                        >
                                          <span className="text-danger">*</span> French
                                        </label>
                                        <Field
                                          as="textarea"
                                          className="form-control"
                                          name={`subtitles.${index}.subtitle_fr`}
                                          rows={2}
                                          placeholder="Enter Your Sub Title"
                                          disabled={type === 'view'}
                                        />
                                        <ErrorMessage
                                          name={`subtitles.${index}.subtitle_fr`}
                                          component="span"
                                          className="error mt-2"
                                        />
                                      </div>
                                      <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                        <label
                                          htmlFor={`subtitles.${index}.subtitle_port`}
                                          className="form-label userLabel"
                                        >
                                          <span className="text-danger">*</span> Portuguese
                                        </label>
                                        <Field
                                          as="textarea"
                                          className="form-control"
                                          name={`subtitles.${index}.subtitle_port`}
                                          rows={2}
                                          placeholder="Enter Your Sub Title"
                                          disabled={type === 'view'}
                                        />
                                        <ErrorMessage
                                          name={`subtitles.${index}.subtitle_port`}
                                          component="span"
                                          className="error mt-2"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              <div className="row">
                                <div className="col-12">
                                  <button
                                    type="button"
                                    className="btn btn-outline-success me-2"
                                    onClick={() =>
                                      push({ subtitle_en: '', subtitle_fr: '', subtitle_port: '' })
                                    }
                                    disabled={type === 'view'}
                                  >
                                    Add Sub Title
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </FieldArray>
                      </div>
                      <div className="d-flex justify-content-end align-items-center ">
                        <Link className="btn btn-outline-danger me-2" to="/subscription">
                          <i className="fas fa-arrow-left" /> Back
                        </Link>
                        {(type === 'edit' || type === 'add') && (
                          <button type="submit" className="btn btn-outline-success my-2">
                            {type === 'edit' ? 'Edit' : 'Add'}
                          </button>
                        )}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
AddEditViewSubscription.propTypes = {
  type: PropTypes.string,
}
export default AddEditViewSubscription
