import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import defaultImage from '../../Dummy.png'
import Loader from '../loader/Loader'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { getNotificationApi, sendNotificationApi } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import 'react-international-phone/style.css'
import { AppHeader } from 'src/components'
import uploadToS3 from 'src/common/s3AwsService'

const AddViewNotification = (props = {}) => {
  const { type } = props
  const navigate = useNavigate()
  console.log('type: ', type)
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [notification, setNotification] = useState([])
  const fileRef = useRef()
  const [notificationImage, setNotificationImage] = useState([])
  const [notificationImageData, setNotificationImageData] = useState([])
  console.log('notificationImageData: ', notificationImageData);

  const getNotification = useCallback((payload) => {
    getNotificationApi(payload).then((response) => {
      if (response.code === 200) {
        setNotification(response.data.data[0])
        setLoaded(false)
      } else {
        setNotification([])
        setLoaded(false)
      }
    })
  }, [])

  const initialValues = useMemo(() => {
    return {
      subject: notification.subject,
      message: notification.message,
      type: notification.type == "news letter" ? 'news_letter' : notification.type,
      notification_image: []
    }
  }, [notification.message, notification.subject, notification.type])
  console.log('initialValues: ', initialValues);

  const ediSubAdminSchema = useCallback(() => {
    return Yup.object().shape({
      subject: Yup.string()
        .required('Please enter Subject')
        .min(2, 'Too Short!')
        .max(30, 'Too Long!'), // .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
      message: Yup.string().required('Please enter Message'),
      type: Yup.string().required('Please Select Type'),
    })
  }, [])

  const handleSubmit = useCallback(
    async (values) => {
      console.log('notificationImageData: ', notificationImageData);
      if (notificationImageData && notificationImageData.length > 0) {
        const uploadPromises = await Promise.allSettled(notificationImageData.map(async (item, index) => {
          try {
            const response = await uploadToS3(item, 'notification');
            if (response && response.key) {
              console.log('response.key: ', response.key);
              if (response.key.length) {
                values.notification_image[index] = response.key;
              }
            } else {
              console.error('Upload failed or response.key is undefined for item:', item);
            }
            return response; // return response to capture in uploadPromises
          } catch (error) {
            console.error('Error uploading item:', item, error);
            return null; // return null for failed uploads
          }
        }));
        console.log('uploadPromises: ', uploadPromises);
      }

      values.notification_image = values.notification_image.filter((item) => item);
      console.log('values: ', values)
      const myPromise = new Promise((resolve, reject) =>
        sendNotificationApi(values).then((response) => {
          if (response.code === 200) {
            resolve(response)
            navigate('/notification')
          } else {
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
    [navigate, notificationImageData],
  )
  const handleImageChange = useCallback(
    (e) => {
      const files = Array.from(e.target.files);

      console.log('files: ', files);
      setNotificationImageData(files);
      const imageUrls = files.map(file => {
        console.log('file: ', file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setNotificationImage(prevImages => [...prevImages, reader.result]);
        };
        return reader;
      });

      // if (file && file.type.startsWith('image')) {

      //   const reader = new FileReader()
      //   reader.onloadend = () => {
      //     setNotificationImage(reader?.result)
      //   }
      //   reader.readAsDataURL(file)
      //   setNotificationImage(file)
      // }
    },
    [],
  )

  const HandleRemoveImage = (id) => {
    const updatedImages = [...notificationImage];
    const updatedImagesData = [...notificationImageData];

    updatedImages.splice(id, 1);
    updatedImagesData.splice(id, 1);

    setNotificationImage(updatedImages);
    setNotificationImageData(updatedImagesData);
  }
  useEffect(() => {
    if (id) {
      getNotification({ notification_id: id })
    }
  }, [getNotification, id])

  return (
    <>
      <Helmet>
        <title>{type === 'add' ? 'Send' : 'View'} Notification Page</title>
      </Helmet>
      <AppHeader title="Notication" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="container-fluid mt-2 px-3">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={ediSubAdminSchema}
                onSubmit={(value) => {
                  console.log('value: ========>', value)
                  handleSubmit(value)
                }}
              >
                {(formik) => {
                  const { values, errors, handleChange, submitCount } = formik
                  return (
                    <Form name="edituser" id="edituser">
                      <div className="d-flex row">
                        <div className="mb-3 row col-12 col-lg-6 col-md-12 col-sm-12 h-100">
                          <div className="col-12 mb-3 ">
                            <label htmlFor="subject" className="form-label userLabel">
                              <span className="text-danger">*</span> Subject
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              id="subject"
                              placeholder="Enter Your Subject"
                              value={values.subject}
                              onChange={(e) => handleChange(e)}
                              disabled={type === 'view'}
                            />
                            {errors.subject && submitCount ? (
                              <span className="error mt-2">{errors.subject}</span>
                            ) : null}
                          </div>

                          <div className="col-12 mb-3 ">
                            <label htmlFor="message" className="form-label userLabel">
                              <span className="text-danger">*</span> Message
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              name="message"
                              id="message"
                              value={values.message}
                              placeholder="Enter Your message"
                              rows={3}
                              onChange={(e) => handleChange(e)}
                              disabled={type === 'view'}
                            />
                            {errors.message && submitCount ? (
                              <span className="error mt-2">{errors.message}</span>
                            ) : null}
                          </div>

                          <div className="col-12 mb-3 ">
                            <label htmlFor="type" className="form-label userLabel">
                              <span className="text-danger">*</span> Type
                            </label>
                            <select
                              className="form form-select"
                              value={values.type}
                              onChange={(e) => handleChange(e)}
                              id="type"
                              name="type"
                              // disabled={!(isUserAdmin || getUserAccess.is_edit === 1)}
                              disabled={type === 'view'}
                            >
                              <option value="" disabled={true} selected>
                                Please Select type
                              </option>
                              <option value="all">All</option>
                              <option value="news_letter">Newsletter</option>
                              <option value="user">User</option>
                              <option value="designer">Designer</option>
                            </select>
                            {errors.type && submitCount ? (
                              <span className="error mt-2">{errors.type}</span>
                            ) : null}
                          </div>
                          <div
                            className="icon-profile mb-3 col-12"
                            style={{ display: 'none' }}
                          // style={{ display: !isImgChange && !data?.data?.image && 'none' }}
                          >
                            <input
                              className="img-input d-none"
                              id="file"
                              // {...register('profile_image')}
                              name="notification_image"
                              accept="image/*"
                              type="file"
                              // style={{ height: 'fit-content' }}
                              // multiple
                              onChange={(e) => handleImageChange(e)}
                              ref={fileRef}
                            />
                            <label
                              className={'-label custom-hover'}
                              htmlFor="file"
                            >
                              <i className="fas fa-camera"></i>
                              <span>Change Image</span>
                            </label>
                            {/* <input
                                            className="img-input"
                                            id="file"
                                            // {...register('profile_image')}
                                            name='icon_image'
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => handleImageChange(e)}
                                            // disabled={data?.type !== 'edit'}
                                            /> */}
                          </div>
                          {/* {notificationImage && notificationImage.length > 0 && (
                            <div>
                              <button type="button" className="btn btn-success mb-3" onClick={() => { fileRef.current.click() }}>Add +</button>
                            </div>
                          )} */}
                          <div
                            className="area"
                            style={{ display: (notificationImage.length || type == 'view') && 'none' }}
                            onClick={() => {
                              fileRef.current.click()
                            }}
                          >
                            <div id="dropZone">Add image here</div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex" style={{ gap: '15px', flexWrap: 'wrap' }}>
                        {notificationImage && notificationImage.length > 0 && notificationImage.map((item, index) => (
                          <>
                            <div className="image-container" style={{ position: 'relative' }}>
                              <img
                                src={item}
                                alt={`Image ${index}`}
                                className="img-thumbnail icon-image"
                                style={{ height: '10rem' }}
                              // onClick={() => fileRef.current.click()}
                              />
                              <button
                                className="close-button"
                                type='buttton'
                                style={{ position: 'absolute', right: '0' }}
                                onClick={() => { HandleRemoveImage(index) }}
                              >
                                &times;
                              </button>
                            </div>
                          </>
                        ))}
                      </div>
                      <div className="d-flex" style={{ gap: '15px', flexWrap: 'wrap' }}>
                        {notification && notification?.image && (
                          <div className="image-container" style={{ position: 'relative' }}>
                            <img
                              src={notification.image}
                              alt={`UpdateImage`}
                              className="img-thumbnail icon-image"
                              style={{ height: '10rem' }}
                            // onClick={() => fileRef.current.click()}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-end ">
                        <Link className="btn btn-outline-danger me-2" to="/notification">
                          <i className="fas fa-arrow-left" /> Back
                        </Link>
                        {type !== 'view' ? (
                          <button type="submit" className="btn btn-outline-success">
                            Save
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AddViewNotification.propTypes = {
  type: PropTypes.string,
}

export default AddViewNotification
