import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import defaultImage from '../../Dummy.png'
import Loader from '../loader/Loader'
import { CommonEditApi } from 'src/services/SocialService'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NotificationManager } from 'react-notifications'
import { getUsers } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import moment from 'moment'
import { AppHeader } from 'src/components'
import _ from 'lodash'

const EditUser = (props = {}) => {
  const { type } = props
  const navigate = useNavigate()
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])

  const getUser = useCallback((payload) => {
    getUsers(payload).then((response) => {
      if (response.code === 200) {
        setUser(response.data.data[0])
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  let editUserSchema

  console.log('user: ', user)
  const initialValues = useMemo(() => {
    const mo_no = user?.country_code + user?.mobile_no
    return {
      full_name: user?.full_name || '',
      dob: moment(user?.dob).format('YYYY-MM-DD') || '',
      about: user?.about || '',
      portfolio_link: user?.portfolio_link || '',
      email: user?.email || '',
      mobile_no: mo_no || '',
      type: user?.type || '',
      profile_image: user?.profile_image || '',
      is_subscribed: user?.is_subscribed || '',
      subscribed_type: user?.subscribed_type || '',
      subscription_duration: user?.subscription_duration || '',
      end_date: user?.card_details?.end_date || '',
    }
  }, [user?.about, user?.card_details?.end_date, user?.country_code, user?.dob, user?.email, user?.full_name, user?.is_subscribed, user?.mobile_no, user?.portfolio_link, user?.profile_image, user?.subscribed_type, user?.subscription_duration, user?.type])

  const editUserForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: editUserSchema,
    onSubmit: async (values) => {
      console.log(values, 'Values')

      var formData = {
        user_id: id,
        full_name: values.full_name,
        dob: values.dob,
        about: values.about,
        portfolio_link: values.portfolio_link,
      }

      const pData = await CommonEditApi('user', JSON.stringify(formData))
      if (pData.code === 1) {
        NotificationManager.success(pData.message, 'Success!')
        navigate('/user')
      } else {
        NotificationManager.error(pData.message, 'Opps!')
      }
    },
  })

  console.log('errors: ', editUserForm.errors, 'values: ', editUserForm.values)

  useEffect(() => {
    getUser({ userId: id })
  }, [getUser, id])

  return (
    <>
      <Helmet>
        <title>User {type === 'edit' ? 'Edit' : 'View'} Page</title>
      </Helmet>
      <AppHeader title="View Profile" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="container-fluid mt-2 px-3">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form name="edituser" id="edituser" onSubmit={editUserForm.handleSubmit}>
                    {/* <div className="mb-3 row justify-content-center">
                      <label htmlFor="profile_image" className="form-label userLabel">
                        Profile Image
                      </label>
                      <img
                        src={
                          editUserForm.values.profile_image
                            ? editUserForm.values.profile_image
                            : defaultImage
                        }
                        style={{
                          height: '30vh',
                          width: '15%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          aspectRatio: '3:2',
                        }}
                      />
                    </div> */}
                    <div className="mb-3 row">
                      <div className="col-md-12 mb-3">
                        <div className="d-flex justify-content-center">
                          {/* <!-- Image Preview & Upload --> */}
                          <div className="admin-profile ">
                            <label
                              className={type === 'edit' ? '-label custom-hover' : '-label'}
                              htmlFor="file"
                            >
                              <i className="fas fa-camera"></i>
                              <span>Change Image</span>
                            </label>
                            <input
                              className="img-input"
                              id="file"
                              // {...register('profile_image')}
                              accept="image/*"
                              type="file"
                              // onChange={handleImageChange}
                              disabled={type !== 'edit'}
                            />
                            <img
                              src={editUserForm?.values?.profile_image}
                              // src={defaultImage}
                              id="output"
                              alt="Profile"
                              width="200"
                              className="img-thumbnail"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="full_name" className="form-label userLabel">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="full_name"
                          id="full_name"
                          placeholder="Enter your Full Name"
                          defaultValue={editUserForm.values.full_name}
                          onChange={(e) => editUserForm.handleChange(e)}
                          disabled={type === 'view' ? true : false}
                        />
                        {editUserForm.errors.full_name && editUserForm.touched.full_name ? (
                          <span className="error mt-2">{editUserForm.errors.full_name}</span>
                        ) : null}
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="email" className="form-label userLabel">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          defaultValue={editUserForm.values.email}
                          placeholder="Enter Your Email"
                          disabled={type === 'view' ? true : false}
                        />
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-2 col-sm-2 row">
                        <label htmlFor="mobile_no" className="form-label userLabel">
                          Mobile Number
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12">
                          <input
                            type="text"
                            className="form-control"
                            name="mobile_no"
                            id="mobile_no"
                            placeholder="Your Mobile No"
                            defaultValue={editUserForm.values.mobile_no}
                            style={{ width: '106%' }}
                            disabled={type === 'view' ? true : false}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label
                          htmlFor="is_subscribed"
                          className="form-label userLabel"
                        // style={{ marginLeft: "-1%" }}
                        >
                          Subscribed
                        </label>
                        {
                          console.log('editUserForm.values.is_subscribed: ', editUserForm.values.is_subscribed)
                        }
                        <input
                          type="text"
                          className="form form-control"
                          name="is_subscribed"
                          id="is_subscribed"
                          placeholder="Your Subscription Type"
                          value={
                            editUserForm.values.is_subscribed === 1 ? 'Paid User' : 'Free User'
                          }
                          // style={{ width: "106%", marginLeft: "2%" }}
                          disabled={type === 'view' ? true : false}
                        />
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label
                          htmlFor="dob"
                          className="form-label userLabel"
                        // style={{ marginLeft: "-3%" }}
                        >
                          Date of Birth
                        </label>

                        <input
                          type="date"
                          className="form form-control"
                          name="dob"
                          id="dob"
                          placeholder="Your Date of Birth"
                          value={editUserForm.values.dob}
                          onChange={(e) => editUserForm.handleChange(e)}
                          // style={{ width: "100%" }}
                          disabled={type === 'view' ? true : false}
                        />
                        {editUserForm.errors.dob && editUserForm.touched.dob ? (
                          <span className="error mt-2">{editUserForm.errors.dob}</span>
                        ) : null}
                      </div>
                    </div>
                    {/* {editUserForm.errors.dob && editUserForm.touched.dob &&
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-2 col-sm-2 offset-xl-8 offset-lg-8 offset-md-8 offset-sm-8" style={{ marginTop: "-1%", marginLeft: "68%" }}>
                                                    <span className="error">{editUserForm.errors.dob}</span>
                                                </div>
                                            } */}

                    {editUserForm.values?.is_subscribed === 1 ? (
                      <>
                        <div className="mb-3 row">
                          <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <label htmlFor="current_subscription" className="form-label userLabel">
                              Plan Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="current_subscription"
                              id="current_subscription"
                              placeholder="Your Current Subscription"
                              value={
                                !editUserForm.values.subscribed_type
                                  ? 'NOT FILLED YET'
                                  : _.capitalize(editUserForm.values.subscribed_type) + " / " + _.capitalize(editUserForm.values.subscription_duration)
                              }
                              disabled={type === 'view' ? true : false}
                            />
                          </div>

                          <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <label htmlFor="last_renew_date" className="form-label userLabel">
                              Last Renew Date
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_renew_date"
                              id="last_renew_date"
                              placeholder="Your Last Renew Date"
                              defaultValue={
                                editUserForm.values.end_date !== null
                                  ? moment(editUserForm.values.end_date).format('DD/MM/YYYY')
                                  : 'NOT FILLED YET'
                              }
                              disabled={type === 'view' ? true : false}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    {editUserForm.values?.type === 'designer' ? (
                      <div className="mb-3 row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <label htmlFor="about" className="form-label userLabel">
                            About
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="about"
                            id="about"
                            placeholder="Enter Something About Yourself"
                            defaultValue={
                              editUserForm.values.about !== null &&
                                editUserForm.values.about !== undefined
                                ? editUserForm.values.about
                                : 'NOT FILLED YET!'
                            }
                            onChange={(e) => editUserForm.handleChange(e)}
                            disabled={type === 'view' ? true : false}
                          />
                          {editUserForm.errors.about && editUserForm.touched.about ? (
                            <span className="error mt-2">{editUserForm.errors.about}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6">
                          <label htmlFor="portfolio_link" className="form-label userLabel">
                            Portfolio Link
                          </label>

                          <textarea
                            type="text"
                            className="form-control"
                            name="portfolio_link"
                            id="portfolio_link"
                            placeholder="Enter Your Portfolio Link"
                            defaultValue={
                              editUserForm.values.portfolio_link !== null &&
                                editUserForm.values.portfolio_link !== undefined
                                ? editUserForm.values.portfolio_link
                                : 'NOT FILLED YET!'
                            }
                            onChange={(e) => editUserForm.handleChange(e)}
                            disabled={type === 'view' ? true : false}
                          />
                          {editUserForm.errors.portfolio_link &&
                            editUserForm.touched.portfolio_link ? (
                            <span className="error mt-2">{editUserForm.errors.portfolio_link}</span>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="d-flex justify-content-end ">
                      <Link className="btn btn-outline-danger me-2" to="/user">
                        <i className="fas fa-arrow-left" /> Back
                      </Link>
                      {type === 'edit-user' ? (
                        <button type="submit" className="btn btn-outline-success">
                          {' '}
                          Save{' '}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

EditUser.propTypes = {
  type: PropTypes.string,
}

export default EditUser
