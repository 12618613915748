import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Loader from '../loader/Loader'
import { editRequestedDesigner, getRequestedDesigner, getReviewApi } from 'src/api/apiHandler'
import { AppHeader } from 'src/components'
import PropTypes from 'prop-types'
import { showPromiseNotification } from 'src/common/notification'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { Rating } from "react-simple-star-rating";

const ViewRatingReview = (props) => {
    const isUserAdmin = isAdmin()
    const getUserAccess = isSubadmin() && getUserPermissions('Designer Requests')

    const { type } = props
    console.log('type: ', type);
    const { id } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [user, setUser] = useState([])
    console.log('user: ', user)

    const getUser = useCallback((payload) => {
        getReviewApi(payload).then((response) => {
            console.log('response: ', response);
            if (response.code === 200) {
                setUser(response.data.data[0])
                setLoaded(false)
            } else {
                setUser([])
                setLoaded(false)
            }
        })
    }, [])

    const editUserSchema = Yup.object({
        status: Yup.string().required('Please Select Valid Status'),
    })

    const editUserForm = useFormik({
        initialValues: {
            ...user,
            mobile_no: user.country_code + user.mobile_no,
        },
        enableReinitialize: true,
        validationSchema: editUserSchema,

        onSubmit: async (values) => {
            var formData = {
                request_id: id,
                status: values.status,
            }

            const myPromise = new Promise((resolve, reject) =>
                editRequestedDesigner(formData).then((response) => {
                    if (response.code === 200) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }),
            )
            showPromiseNotification(myPromise)
        },
    })
    // https://2da1-122-169-110-153.ngrok-free.app

    useEffect(() => {
        getUser({ review_id: id })
    }, [getUser, id])

    return (
        <>
            <Helmet>
                <title>View Rating And Review</title>
            </Helmet>
            <AppHeader title={'Rating And Review'} />
            <div className="container-fluid mt-2 px-3">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-12">
                        <div className="card">
                            <div
                                className="card-header text-dark fw-bolder fs-4"
                                style={{ backgroundColor: 'lightpink' }}
                            >
                                {type === 'edit' ? 'Edit' : 'View'} Rating And Review
                            </div>
                            {loaded ? (
                                <Loader />
                            ) : (
                                <div className="card-body">
                                    <form name="edituser" id="edituser" onSubmit={editUserForm.handleSubmit}>

                                        <div className="mb-3 row">
                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <label htmlFor="is_subscribed" className="form-label userLabel me-3">
                                                    Ratings
                                                </label>

                                                <Rating
                                                    initialValue={editUserForm.values.rating}
                                                    size={35}
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "center",
                                                    }}
                                                    fillColor="orange"
                                                    emptyColor="gray"
                                                    className="foo"
                                                    readonly
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <label htmlFor="full_name" className="form-label userLabel">
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="full_name"
                                                    id="full_name"
                                                    placeholder="Enter your Full Name"
                                                    value={editUserForm.values.full_name}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                <label htmlFor="email" className="form-label userLabel">
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="email"
                                                    id="email"
                                                    value={editUserForm.values.email}
                                                    placeholder="Enter Your Email"
                                                    disabled
                                                />
                                            </div>

                                            {/* <div className="col-12 col-xl-4 col-lg-4 col-md-2 col-sm-2 row">
                                                <label htmlFor="mobile_no" className="form-label userLabel">
                                                    Mobile Number
                                                </label>
                                                <div className="col-12 col-xl-12 col-lg-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="mobile_no"
                                                        id="mobile_no"
                                                        placeholder="Your Mobile No"
                                                        value={editUserForm.values.mobile_no}
                                                        style={{ width: '106%' }}
                                                        disabled
                                                    />
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-12 col-xl-8 col-lg-8">
                                                <label htmlFor="about" className="form-label userLabel">
                                                    Feedback
                                                </label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name="feedback"
                                                    id="feedback"
                                                    rows={5}
                                                    placeholder="Enter Something About Feed Back"
                                                    value={
                                                        editUserForm.values.feedback !== null &&
                                                            editUserForm.values.feedback !== undefined
                                                            ? editUserForm.values.feedback
                                                            : 'NOT FILLED YET!'
                                                    }
                                                    disabled
                                                />
                                            </div>

                                        </div>

                                        <div className="d-flex justify-content-end ">
                                            <Link className="btn btn-outline-danger me-2" to="/rating-review">
                                                <i className="fas fa-arrow-left" /> Back
                                            </Link>
                                            {(isUserAdmin || getUserAccess.is_edit === 1) && type === 'edit' ? (
                                                <button type="submit" className="btn btn-outline-success">
                                                    Edit
                                                </button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

ViewRatingReview.propTypes = {
    type: PropTypes.string,
}

export default ViewRatingReview
