import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Loader from '../loader/Loader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { editProfile, getProfile } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { saveUserDataToLocal } from 'src/common/LocalStorageService'
import { AppHeader } from 'src/components'
import moment from 'moment'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import defaultImage from '../../Dummy.png'

import { PhoneNumberUtil } from 'google-libphonenumber'
import uploadToS3 from 'src/common/s3AwsService'

const phoneUtil = PhoneNumberUtil.getInstance()
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

const Profile = (props = {}) => {
  const { type } = props
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState([])
  // const [imagePreview, setImagePreview] = useState(null)
  const [isImgChange, setIsImgChange] = useState(false)
  const [profileImage, setProfileImage] = useState(null)

  const handleEditProfile = useCallback(
    (formData) => {
      const myPromise = new Promise((resolve, reject) => {
        if (isImgChange) {
          uploadToS3(profileImage, 'admin').then((response) => {
            console.log('response: ', response)

            const payload = {
              profile_image: response.key,
              ...formData,
            }

            console.log('payload: ', payload)
            editProfile(payload).then((response) => {
              console.log('response: ', response)
              if (response.code === 200) {
                saveUserDataToLocal(response.data)
                setData(response.data)
                resolve(response)
                setLoaded(true)
              } else {
                setData([])
                setLoaded(true)
                reject(response)
              }
            })
          })
        } else {
          const { profile_image, ...rest_payload } = formData
          editProfile(rest_payload).then((response) => {
            if (response.code === 200) {
              saveUserDataToLocal(response.data)
              setData(response.data)
              resolve(response)
              setLoaded(true)
            } else {
              setData([])
              setLoaded(true)
              reject(response)
            }
          })
        }
      })
      showPromiseNotification(myPromise)
    },
    [isImgChange, profileImage],
  )

  const handleEmailchange = (formData) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Email will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          handleEditProfile(formData)
        }
      })
  }

  const getUser = useCallback((payload) => {
    getProfile(payload).then((response) => {
      if (response.code === 200) {
        console.log('response: ', response)
        setData(response.data)
        setLoaded(true)
      } else {
        setData([])
        setLoaded(true)
      }
    })
  }, [])

  let editUserSchema = Yup.object().shape({
    full_name: Yup.string()
      .required('Please enter Full Name')
      .min(2, 'Too Short!')
      .max(30, 'Too Long!'), // .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    email: Yup.string().required('Please enter Email').email('*Please enter valid email'),
    dob: Yup.string().test('dob', 'You must be at least 18 years old', function (value) {
      console.log('value: ================>', value)
      if (value) {
        const today = moment()
        const birthdateMoment = moment(value, 'YYYY-MM-DD')
        const age = today.diff(birthdateMoment, 'years')
        return age >= 18
      } else {
        return true
      }
    }),
    mobile_no: Yup.string().test(
      'phoneValidation',
      'Please enter a valid mobile number',
      (phone) => {
        console.log('phone: ', phone)
        if (phone) {
          return isPhoneValid(phone)
        } else {
          return true
        }
      },
    ),
  })

  const initialValues = useMemo(() => {
    return {
      full_name: data?.full_name || '',
      email: data?.email || '',
      dob: moment(data?.dob).format('YYYY-MM-DD') || '',
      mobile_no: data?.mobile_no || '',
      profile_image: data?.profile_image || defaultImage,
    }
  }, [data?.dob, data?.email, data?.full_name, data?.mobile_no, data?.profile_image])

  const myProfileForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: editUserSchema,
    onSubmit: async (values) => {
      var formData = {
        admin_id: data?.id,
        full_name: values.full_name,
        email: values.email,
        dob: moment(values?.dob).format('YYYY-MM-DD'),
        mobile_no: values?.mobile_no || '',
      }
      if (values.email != data?.email) {
        handleEmailchange(formData)
      } else {
        handleEditProfile(formData)
      }
    },
  })

  console.log('myProfileForm: ', myProfileForm)

  const handleImageChange = useCallback(
    (e) => {
      const file = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          // setImagePreview(reader.result)
          myProfileForm.setFieldValue('profile_image', reader?.result)
        }
        reader.readAsDataURL(file)
        setIsImgChange(true)
        setProfileImage(file)
      }
    },
    [myProfileForm],
  )
  console.log('f', myProfileForm.values.dob)

  useEffect(() => {
    getUser({})
  }, [getUser, type])

  return (
    <>
      <Helmet>
        <title>User {type === 'edit' ? 'Edit' : 'View'} Page</title>
      </Helmet>
      <AppHeader title={type === 'edit' ? 'Edit Profile' : 'View Profile'} />
      {loaded === true ? (
        <div className="container-fluid mt-2">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-12">
              <div className="card">
                <div
                  className="card-header text-dark fw-bolder fs-4"
                  style={{ backgroundColor: 'lightpink' }}
                >
                  {type === 'edit' ? 'Edit' : 'View'} Profile
                </div>
                <div className="card-body">
                  <form name="edituser" id="edituser" onSubmit={myProfileForm.handleSubmit}>
                    <div className="mb-3 row">
                      <div className="col-md-12 mb-3">
                        <div className="d-flex justify-content-center">
                          {/* <!-- Image Preview & Upload --> */}
                          <div className="admin-profile ">
                            <label
                              className={type === 'edit' ? '-label custom-hover' : '-label'}
                              htmlFor="file"
                            >
                              <i className="fas fa-camera"></i>
                              <span>Change Image</span>
                            </label>
                            <input
                              className="img-input"
                              id="file"
                              // {...register('profile_image')}
                              accept="image/*"
                              type="file"
                              onChange={handleImageChange}
                              disabled={type !== 'edit'}
                            />
                            <img
                              src={myProfileForm?.values?.profile_image}
                              // src={defaultImage}
                              id="output"
                              alt="Profile"
                              width="200"
                              className="img-thumbnail"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 row row-gap-3 ">
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="full_name" className="form-label userLabel">
                          <span className="text-danger">*</span> Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="full_name"
                          id="full_name"
                          placeholder="Enter your Full Name"
                          value={myProfileForm.values.full_name}
                          onChange={(e) => myProfileForm.handleChange(e)}
                          disabled={type === 'view' ? true : false}
                        />
                        {myProfileForm.errors.full_name && myProfileForm.submitCount ? (
                          <span className="error mt-2">{myProfileForm.errors.full_name}</span>
                        ) : null}
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="email" className="form-label userLabel">
                          <span className="text-danger">*</span> Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          value={myProfileForm.values.email}
                          onChange={(e) => myProfileForm.handleChange(e)}
                          placeholder="Enter Your Email"
                          disabled={type === 'view' ? true : false}
                        />
                        {myProfileForm.errors.email && myProfileForm.submitCount ? (
                          <span className="error mt-2">{myProfileForm.errors.email}</span>
                        ) : null}
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="email" className="form-label userLabel">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="dob"
                          id="dob"
                          value={myProfileForm.values.dob}
                          onChange={(e) => myProfileForm.handleChange(e)}
                          placeholder="Enter Your Email"
                          disabled={type === 'view' ? true : false}
                        />
                        {myProfileForm.errors.dob && myProfileForm.submitCount ? (
                          <span className="error mt-2">{myProfileForm.errors.dob}</span>
                        ) : null}
                      </div>
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="email" className="form-label userLabel">
                          <span className="text-danger">*</span> Mobile Number
                        </label>
                        <PhoneInput
                          defaultCountry="ua"
                          value={myProfileForm.values.mobile_no}
                          className="form-group"
                          inputProps={{
                            className: 'form-control',
                            name: 'PhoneNumber',
                          }}
                          inputStyle={{
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            borderLeft: '0',
                            paddingLeft: '0',
                          }}
                          countrySelectorStyleProps={{
                            buttonContentWrapperClassName: 'form-control h-100',
                            buttonContentWrapperStyle: {
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                              border: '1px solid #E4EAF7',
                              paddingRight: '5px',
                              borderRight: '0',
                              backgroundColor: type === 'view' ? '#d8dbe0' : '',
                            },

                            buttonClassName: 'border-0 h-100 disabled',
                          }}
                          onChange={(phone) => {
                            myProfileForm.setFieldValue('mobile_no', phone)
                          }}
                          disabled={type === 'view' ? true : false}
                        />
                        {myProfileForm.errors.mobile_no && myProfileForm.submitCount ? (
                          <span className="error">{myProfileForm.errors.mobile_no}</span>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center ">
                      <Link className="btn btn-outline-danger me-2" to="/">
                        <i className="fas fa-arrow-left" /> Back
                      </Link>
                      {type === 'edit' && (
                        <button
                          type="submit"
                          disabled={!myProfileForm.dirty}
                          className="btn btn-outline-success"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

Profile.propTypes = {
  type: PropTypes.string,
}

export default Profile
