import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import swal from 'sweetalert2'
import { activeInactiveStickerApi, deleteStickerApi, getStickersApi } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import { CButton } from '@coreui/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import StickerModal from './stickerModal'

const Sticker = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Stickers')

  const [loaded, setLoaded] = useState(false)
  const [shape, setShape] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return {
      page: 1,
      type: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      ...sorting,
    }
  }, [pageLength, sorting])
  const [singleData, setSingleData] = useState({ type: '', data: {} })

  const [payload, setPayload] = useState(initialFilter)
  const [filter, setFilter] = useState(initialFilter)
  const [addShapeModal, setAddShapeModal] = useState(false)

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    console.log('payload: ', payload)
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const getStickerList = useCallback((payload) => {
    getStickersApi(payload).then((response) => {
      console.log('response: ', response)
      if (response.code == 200) {
        setShape(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setShape([])
        setLoaded(false)
      }
    })
  }, [])

  const handleActiveInactiveShape = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 0 ? 'Yes, Active it!' : 'Yes, Inactive it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        minWidth: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            activeInactiveStickerApi({ page_id: id }).then((response) => {
              if (response.code == 200) {
                resolve(response)
                setShape((prev) =>
                  prev.map((item) => {
                    if (id === item.id) {
                      item.is_active = status === 0 ? 1 : 0
                    }
                    return item
                  }),
                )
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: 'Cancelled',
            text: 'There is no change',
            icon: 'error',
            timer: 1000,
          })
        }
      })
  }

  const deleteShape = async (e, id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            deleteStickerApi({ page_id: id }).then((response) => {
              if (response.code == 200) {
                resolve(response)
                if (shape.length >= 2) {
                  getStickerList(filter)
                } else {
                  setFilter((prev) => {
                    return { ...prev, page: filter.page - 1 }
                  })
                }
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      sort_key: 'id',
      minWidth: '50px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Full Name',
      selector: (row) => row.name,
      sortable: true,
      sort_key: 'name',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },

    {
      name: 'Image',
      minWidth: '150px',
      style: {
        fontSize: '14px',
      },
      cell: (row) => <img src={row.image} height={50} width={50} alt="Image" />,
    },
    (isUserAdmin || getUserAccess?.is_edit) && {
      name: 'Status',
      minWidth: '150px',
      alignItems: 'center',
      cell: (row) => (
        <li
          className={
            row.is_active === 0
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={() => handleActiveInactiveShape(row.id, row.is_active)}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_active === 0
                  ? 'fas fa-toggle-off text-danger'
                  : 'fas fa-toggle-on text-success'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
      ),
    },
    (isUserAdmin ||
      getUserAccess.is_view === 1 ||
      getUserAccess.is_edit === 1 ||
      getUserAccess.is_remove === 1) && {
      name: 'Action',
      minWidth: '250px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  setSingleData({ type: 'view', data: row })
                  setAddShapeModal(true)
                }}
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_edit === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  setSingleData({ type: 'edit', data: row })
                  setAddShapeModal(true)
                }}
              >
                <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_remove === 1) && (
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={(e) => deleteShape(e, row.id)}>
                <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
        </ul>
      ),
    },
  ]

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }
  useEffect(() => {
    getStickerList(filter)
  }, [filter, getStickerList])

  return (
    <>
      <Helmet>
        <title>Stickers Page</title>
      </Helmet>
      <AppHeader title="Stickers" />
      <StickerModal
        addShapeModal={addShapeModal}
        setAddShapeModal={setAddShapeModal}
        setFilter={setFilter}
        setPayload={setPayload}
        initialFilter={initialFilter}
        getStickerList={getStickerList}
        data={singleData}
        setData={setSingleData}
        setShape={setShape}
      />
      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            title={`Stickers`}
            columns={columns}
            data={shape}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              console.log('page: ', page)
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <>
                  <div className="d-flex justify-content-between  ">
                    <div>
                      <CButton
                        color="primary"
                        onClick={() => {
                          applyFilter()
                        }}
                        size="sm"
                        className="mt-3 theme-custom-btn"
                        disabled={!payload.search.length}
                      >
                        Apply
                      </CButton>
                      <CButton
                        color="primary"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          clearFilter()
                        }}
                        disabled={
                          !payload.search.length &&
                          !payload.type.length &&
                          !payload.start_date.length
                        }
                        className="ms-3 mt-3 theme-custom-btn-outline"
                      >
                        Clear
                      </CButton>
                    </div>
                    {(isUserAdmin || getUserAccess.is_add === 1) && (
                      <CButton
                        color="primary"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSingleData({ type: 'add', data: {} })
                          setAddShapeModal(true)
                        }}
                        data-coreui-toggle="modal"
                        data-coreui-target="#staticBackdrop"
                        className="ms-3 mt-3 px-4 theme-custom-btn-outline"
                      >
                        + ADD STICKER
                      </CButton>
                    )}
                  </div>
                </>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default Sticker
