import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
// import { history } from "../../../common/helpers";

import CIcon from '@coreui/icons-react'
import { cilEnvelopeClosed, cilLockLocked } from '@coreui/icons'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { saveUserDataToLocal } from 'src/common/LocalStorageService'
import { login } from 'src/api/apiHandler'
import { showErrorMessage, showPromiseNotification } from 'src/common/notification'

const Login = () => {
  let navigate = useNavigate()

  const LoginSchema = Yup.object({
    email: Yup.string().required('* Please Enter Email').email('Enter Valid Email'),
    password: Yup.string().required('* Please Enter Password'),
  })

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      values.device_name = 'apple'
      values.device_token = 'aplle5454'
      values.device_type = 'W'
      values.os_version = '1.154'
      values.app_version = '16.1'
      console.log(values, 'val')

      var formData = {}

      formData.email = values.email
      formData.password = values.password
      formData.device_name = 'apple'
      formData.device_token = 'aplle5454'
      formData.device_type = 'W'
      formData.os_version = '16.0'
      formData.app_version = '1.0'

      login(formData).then((response) => {
        console.log('response: ', typeof response, response)
        if (response.code === 200) {
          localStorage.setItem('isLogin', 'true')
          saveUserDataToLocal(response.data)
          const currentPath = window.location.pathname
          const newPath = `${currentPath.endsWith('/') ? currentPath : currentPath + '/'}dashboard`
          window.location.href = newPath
        } else {
          showErrorMessage(response?.message)
        }
      })
    },
  })

  return (
    <>
      <Helmet>
        <title>Login Page</title>
      </Helmet>

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer className="w-lg-25  w-md-50 w-sm-100 ">
          <CRow className="justify-content-center">
            <CCol sm={12} md={8} lg={4}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm name="loginForm" onSubmit={loginForm.handleSubmit}>
                      <img src={process.env.PUBLIC_URL + '/Logosvg.svg'}></img>
                      <div className="w-100 mt-5">
                        <h3 className="text-center mb-4">Login</h3>
                        <p style={{ fontSize: '14px', color: '#98a6ad' }} className="mb-4">
                          Enter your email address and password to access admin panel.
                        </p>
                        <CInputGroup className="my-1 mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilEnvelopeClosed} />
                          </CInputGroupText>
                          <CFormInput
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Email"
                            autoComplete="email"
                            value={loginForm.values.email}
                            onChange={(e) => loginForm.handleChange(e)}
                          />
                        </CInputGroup>

                        {loginForm.errors.email && loginForm.touched.email ? (
                          <span className="error">{loginForm.errors.email}</span>
                        ) : null}

                        <CInputGroup className="my-1 ">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            value={loginForm.values.password}
                            onChange={(e) => loginForm.handleChange(e)}
                            autoComplete="current-password"
                          />
                        </CInputGroup>
                        {loginForm.errors.password && loginForm.touched.password ? (
                          <span className="error">{loginForm.errors.password}</span>
                        ) : null}
                        <div className="d-flex justify-content-between mt-3">
                          <CButton
                            color="link"
                            className="px-0"
                            onClick={() => {
                              navigate('/forgot-password')
                            }}
                          >
                            Forgot password?
                          </CButton>
                          <CButton color="primary" type="submit" className="px-4 theme-custom-btn">
                            Login
                          </CButton>
                        </div>
                      </div>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Login
