import React from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilUser, cilPowerStandby } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { getUserDataFromLocal, removeUserDataFromLocal } from 'src/common/LocalStorageService'
import { showPromiseNotification } from 'src/common/notification'
import { logout } from 'src/api/apiHandler'

const AppHeaderDropdown = () => {
  const navigate = useNavigate()

  //logout
  const handleLogout = () => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'You will stepout from the system',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Logout it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            logout({}).then((response) => {
              console.log('response: logout', response)
              if (response.code === 200) {
                localStorage.setItem('isLogin', 'false')
                removeUserDataFromLocal()
                resolve(response)
                // window.location.href = process.env.REACT_APP_BASE_PATH
                sessionStorage.clear()

                navigate('/', {
                  replace: true,
                })
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={getUserDataFromLocal()?.profile_image} size="md" className="header-image" />{' '}
        {getUserDataFromLocal()?.full_name}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
        <CDropdownItem className="custom-header">
          <Link to={'/view-profile'}>
            <CIcon icon={cilUser} className="me-2" />
            Profile
          </Link>
        </CDropdownItem>
        <CDropdownItem className="custom-header">
          <Link to={'/edit-profile'}>
            <CIcon icon={cilUser} className="me-2" />
            Edit Profile
          </Link>
        </CDropdownItem>

        <CDropdownDivider />
        <CDropdownItem className="userlist" onClick={(e) => handleLogout(e)}>
          <a>
            <CIcon icon={cilPowerStandby} className="me-2" />
            Log Out
          </a>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
