import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import DataTable from 'react-data-table-component'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { editRequestedDesigner, getDesignerReport, getRequestedDesigner } from 'src/api/apiHandler'
import { AppHeader } from 'src/components'
import { CButton } from '@coreui/react'
import Swal from 'sweetalert2'
import { showPromiseNotification } from 'src/common/notification'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'
import moment from 'moment'
import { exportExcelFile } from 'src/common/genrateExcel'
import _ from 'lodash'

const DesignerRequest = () => {
  const navigate = useNavigate()
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Designer Requests')
  const { prevFilters, setPrevFilters } = useFilter()
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'd.id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return {
      page: 1,
      type: '',
      search: '',
      limit: pageLength,
      ...sorting,
    }
  }, [pageLength, sorting])

  const [loaded, setLoaded] = useState(false)
  const [designerRequest, setDesignerRequest] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'designer_request' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'designer_request' && { ...prevFilters }),
  })

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    console.log('payload: ', payload)
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const getDesignerRequest = useCallback((payload) => {
    getRequestedDesigner(payload).then((response) => {
      if (response.code === 200) {
        setDesignerRequest(response?.data?.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setDesignerRequest([])
        setLoaded(false)
      }
    })
  }, [])

  const updateStatus = (e, id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'You want to change the status',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            editRequestedDesigner({ request_id: id, status: e }).then((response) => {
              if (response.code === 200) {
                setDesignerRequest((item) =>
                  item.map((val) => {
                    if (val.id === id) val.status = e
                    return val
                  }),
                )
                resolve(response)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '10px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      sort_key: 'd.id',
      minWidth: '80px',
      style: {
        fontSize: '14px',
        // justifyContent: "center",
      },
    },
    {
      name: 'Full Name',
      selector: (row) => row.full_name,
      sortable: true,
      sort_key: 'u.full_name',
      minWidth: '250px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sort_key: 'u.email',
      sortable: true,
      minWidth: '250px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Mobile Number',
      selector: (row) => (
        <>
          {row.country_code} {row.mobile_no}
        </>
      ),
      minWidth: '200px',
      style: {
        fontSize: '14px',
        // justifyContent: "center",
      },
    },
    {
      name: 'Subscription',
      selector: (row) => (row.is_subscribed === 0 ? 'Free Customer' : 'Paid Customer'),
      minWidth: '150px',
      sortable: true,
      sort_key: 'u.is_subscribed',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Status',
      minWidth: '200px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          <li className="list-inline-item">
            <select
              className="form-select"
              onChange={(e) => updateStatus(e.target.value, row.id)}
              value={row.status}
              disabled={!(isUserAdmin || getUserAccess.is_edit === 1)}
            >
              {[
                { value: 'requested', label: 'Requested', disabled: true },
                { value: 'accepted', label: 'Accepted' },
                { value: 'rejected', label: 'Rejected' },
              ]?.map((item) => {
                return (
                  <option key={item?.value} value={item?.value} disabled={item.disabled}>
                    {item?.label}
                  </option>
                )
              })}
            </select>
          </li>
        </ul>
      ),
    },
  ]

  if (isUserAdmin || getUserAccess.is_view === 1) {
    columns.push({
      name: 'Action',
      minWidth: '150px',
      style: {
        marginTop: '1%',
      },
      cell: (row) => (
        <ul className="list-inline">
          <li className="list-inline-item">
            <button
              className="btn btn-link"
              onClick={() => navigate(`/designerrequest/view-request/${row.id}`)}
            >
              <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
            </button>
          </li>
          <li className="list-inline-item">
            <button
              className="btn btn-link"
              onClick={() => navigate(`/designerrequest/edit-request/${row.id}`)}
            >
              <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
            </button>
          </li>
        </ul>
      ),
    })
  }

  const exportExcel = useCallback((payload) => {
    const myPromise = new Promise((resolve, reject) =>
      getDesignerReport({ start_date: filter?.start_date, end_date: filter?.end_date, type: payload.type, search: payload.search }).then(
        (response) => {
          console.log('response excel:', response);
          if (response.code === 200) {
            let columns = [
              {
                header: 'ID',
                key: 'id',
                width: 15,
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true,
              },
              { header: 'Name', key: 'full_name', width: 30, horizontal: 'center' },
              { header: 'Email', key: 'email', width: 30, horizontal: 'center' },
              { header: 'Phone', key: 'number', width: 30, horizontal: 'center' },
              { header: 'Type', key: 'subscribed_type', width: 15, horizontal: 'center' },
              { header: 'DOB', key: 'dob', width: 15, horizontal: 'center' },
              { header: 'Status', key: 'is_block', width: 15, horizontal: 'center' },
              { header: 'Designer Status', key: 'is_designer_status', width: 15, horizontal: 'center' },
              { header: 'Date Of Joining', key: 'created_at', width: 15, horizontal: 'center' },
              { header: 'Created Template Count', key: 'download_count', width: 35, horizontal: 'center' },
            ]

            const ExportData = response?.data?.map((user) => {
              return {
                ...user,
                number: `${user?.country_code} ${user?.mobile_no}`,
                subscribed_type: user?.is_subscribed ? _.capitalize(user?.subscribed_type) : 'Free',
                dob: user?.dob?.length ? moment(user.dob).format('DD-MM-YYYY') : '',
                created_at: user?.created_at?.length
                  ? moment(user.created_at).format('DD-MM-YYYY')
                  : '',
                is_block: user?.is_block ? 'Inactive' : 'Active',
                is_designer_status: user?.designer_status == "accepted" ? 'Accepted' : user?.designer_status == "rejected" ? "Rejected" : "Requested",
              }
            })

            const fileName = `Designer_Report_` + moment().format('DDMMYYYYhmmss')
            exportExcelFile(columns, ExportData, fileName)
            resolve(response)
          } else {
            reject(response)
          }
        },
      ),
    )
    showPromiseNotification(myPromise)
  }, [filter?.end_date, filter?.start_date])

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getDesignerRequest(filter)
    setPrevFilters({ ...filter, listing_page: 'designer_request' })
  }, [filter, getDesignerRequest, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Designer Requests</title>
      </Helmet>
      <AppHeader title="Designer Request" />
      <div className="px-3">
        {loaded ? (
          <Loader />
        ) : (
          <DataTable
            title="Designer Requests"
            columns={columns}
            data={designerRequest}
            pagination
            paginationServer
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              console.log('page: ', page)
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('type', e.target.value)}
                      value={payload.type}
                    >
                      <option value={''} disabled={true}>
                        Select Status
                      </option>
                      {[
                        { value: 'requested', label: 'Requested' },
                        { value: 'accepted', label: 'Accepted' },
                        { value: 'rejected', label: 'Rejected' },
                      ]?.map((item) => {
                        return (
                          <option key={item?.value} value={item?.value} disabled={item.disabled}>
                            {item?.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <>
                  <CButton
                    color="primary"
                    onClick={() => {
                      applyFilter()
                    }}
                    size="sm"
                    className="mt-3 theme-custom-btn"
                    disabled={!payload.search.length && !payload.type.length}
                  >
                    Apply
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      clearFilter()
                    }}
                    disabled={!payload.search.length && !payload.type.length}
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Clear
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      exportExcel(filter)
                    }}
                    disabled={designerRequest.length <= 0}
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Export
                  </CButton>
                </>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export default DesignerRequest
