import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import Loader from '../loader/Loader'
import DataTable from 'react-data-table-component'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import swal from 'sweetalert2'
import { activeInactiveCategory, deleteCategory, getCategory } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import { CButton } from '@coreui/react'
import { AppHeader } from 'src/components'
import AddEditCategory from './AddEditCategory'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'

const CategoryPage = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Category')
  const { prevFilters, setPrevFilters } = useFilter()
  const initialFilter = useMemo(() => {
    return { search: '' }
  }, [])

  const [categoryList, setCategoryList] = useState([])
  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'category' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'category' && { ...prevFilters }),
  })
  const [loaded, setLoaded] = useState(false)
  const [addCategoryModal, setAddCategoryModal] = useState(false)
  const [singleData, setSingleData] = useState({ type: '', data: {} })
  const [totalRows, setTotalRows] = useState(0)

  const getCategoryList = useCallback((payload) => {
    getCategory(payload).then((response) => {
      if (response.code === 200) {
        setCategoryList(response.data)
        setTotalRows(response.data.length)
        setLoaded(false)
      } else {
        setCategoryList([])
        setLoaded(false)
      }
    })
  }, [])

  const handleActiveInactive = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Inactive it!' : 'Yes, Active it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        width: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            activeInactiveCategory({ id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                setCategoryList((prev) =>
                  prev.map((item) => {
                    if (id === item.id) {
                      item.is_active = status === 0 ? 1 : 0
                    }
                    return item
                  }),
                )
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const handleDeleteCategory = async (id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            deleteCategory({ id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                const filteredData = categoryList.filter((item) => item.id !== id)
                setCategoryList(filteredData)
                setTotalRows((prev) => prev - 1)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '10px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'Id',
      selector: (row, index) => row?.id,
      sortable: true,
      width: '100px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Subcategory Count',
      selector: (row) => row.subcategory_count || 0,
      sortable: true,
      minWidth: '200px',
      center: true,
      align: 'center',
      style: {
        fontSize: '14px',
        textAlign: 'center',
      },
    },
    {
      name: 'Status',
      selector: (row) => (
        <li
          className={
            row.is_active === 1
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={(e) => {
            ;(isUserAdmin || getUserAccess.is_edit === 1) &&
              handleActiveInactive(row.id, row.is_active)
          }}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_active === 1
                  ? 'fas fa-toggle-on text-success'
                  : 'fas fa-toggle-off text-danger'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
      ),
      sortable: true,
      minWidth: '200px',
      grow: 1,

      style: {
        fontSize: '14px',
        // justifyContent: "center",
        marginLeft: '0.2%',
      },
    },
    {
      name: 'Action',
      minWidth: '300px',
      grow: 1,
      style: {
        textAlign: 'center',
      },
      selector: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  setSingleData({ type: 'view', data: row })
                  setAddCategoryModal(true)
                }}
              >
                <i className="fas fa-eye fs-6 te"></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_edit === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  setSingleData({ type: 'edit', data: row })
                  setAddCategoryModal(true)
                }}
              >
                <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_remove === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => {
                  handleDeleteCategory(row?.id)
                }}
              >
                <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
        </ul>
      ),
    },
  ]

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])
  useEffect(() => {
    getCategoryList(filter)
    setPrevFilters({ ...filter, listing_page: 'category' })
  }, [filter, getCategoryList, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Category</title>
      </Helmet>
      <AppHeader title="Category" />
      <AddEditCategory
        addCategoryModal={addCategoryModal}
        setAddCategoryModal={setAddCategoryModal}
        setFilter={setFilter}
        setPayload={setPayload}
        initialFilter={initialFilter}
        getCategoryList={getCategoryList}
        data={singleData}
        setData={setSingleData}
      />
      <div className="px-3">
        {loaded ? (
          <Loader />
        ) : (
          <div className="w-100">
            <DataTable
              title="All Category"
              columns={columns}
              data={categoryList}
              pagination
              // paginationServer
              responsive
              paginationTotalRows={totalRows}
              // paginationDefaultPage={filter.page}
              // paginationPerPage={filter.limit}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              // onChangeRowsPerPage={(noRows) => {
              //   handleFilter('limit', noRows)
              // }}
              // onChangePage={(page) => {
              //   console.log('page: ', page)
              //   handleFilter('page', page)
              // }}
              selectableRowsHighlight
              highlightOnHover
              striped
              subHeader
              fixedHeader
              fixedFooter
              persistTableHead
              subHeaderWrap
              subHeaderAlign="left"
              customStyles={tableCustomStyles}
              progressPending={loaded}
              noDataComponent={
                <>
                  <h5 className="p-3">No Data Found</h5>
                </>
              }
              subHeaderComponent={
                <div className="w-100 ">
                  <div className="row ">
                    <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search.."
                        value={payload.search}
                        onChange={(e) => {
                          handleFilterPayload('search', e.target.value)
                          if (!e.target.value.length) {
                            clearFilter()
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key == 'Enter') {
                            if (e.target.value !== '') {
                              applyFilter()
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <CButton
                        color="primary"
                        onClick={() => {
                          applyFilter()
                        }}
                        disabled={!payload?.search?.length}
                        size="sm"
                        className="mt-3 theme-custom-btn"
                      >
                        Apply
                      </CButton>
                      <CButton
                        color="primary"
                        variant="outline"
                        size="sm"
                        disabled={!payload?.search?.length}
                        onClick={() => {
                          clearFilter()
                        }}
                        className="ms-3 mt-3 theme-custom-btn-outline"
                      >
                        Clear
                      </CButton>
                    </div>
                    {(isUserAdmin || getUserAccess.is_add === 1) && (
                      <CButton
                        color="primary"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSingleData({ type: 'add', data: {} })
                          setAddCategoryModal(true)
                        }}
                        data-coreui-toggle="modal"
                        data-coreui-target="#staticBackdrop"
                        className="ms-3 mt-3 px-4 theme-custom-btn-outline"
                      >
                        + ADD CATEGORY
                      </CButton>
                    )}
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CategoryPage
