import 'react-app-polyfill/stable'
import 'core-js'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter } from 'react-router-dom'
import Notification from './common/notification'
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-toastify/dist/ReactToastify.css";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

createRoot(document.getElementById('root')).render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
    <Suspense fallback={loading}>
      <Provider store={store}>
        <Notification />
        <App />
      </Provider>
    </Suspense>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// "homepage": "http://3.7.8.99/templify/admin",