import ExcelJS from 'exceljs'

export function exportExcelFile(columns, data, fileName) {
  const workbook = new ExcelJS.Workbook()
  const workSheet = workbook.addWorksheet('Report-Data')

  // Set up the columns
  workSheet.columns = columns

  // Apply styles to the header row
  const headerRow = workSheet.getRow(1)
  headerRow.eachCell((cell) => {
    cell.font = { bold: true }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC0C0C0' }, // Light gray color for the header background
    }
    cell.alignment = { horizontal: 'center' }
  })

  // Add data to the worksheet
  data.forEach((element) => {
    workSheet.addRow(element)
  })

  // Center align all cells and adjust row height
  workSheet.eachRow({ includeEmpty: true }, (row) => {
    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.alignment = { horizontal: 'center' }
    })
  })

  // Write the workbook to a buffer and create a Blob for download
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = fileName
    anchor.click()
    window.URL.revokeObjectURL(url)
  })
}
