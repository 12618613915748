import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Loader from '../loader/Loader'
import { editRequestedDesigner, getRequestedDesigner } from 'src/api/apiHandler'
import { AppHeader } from 'src/components'
import PropTypes from 'prop-types'
import { showPromiseNotification } from 'src/common/notification'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'

const ViewDesignerRequest = (props) => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Designer Requests')

  const { type } = props
  console.log('type: ', type);
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])
  console.log('user: ', user)

  const getUser = useCallback((payload) => {
    getRequestedDesigner(payload).then((response) => {
      if (response.code === 200) {
        setUser(response.data.data[0])
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  const editUserSchema = Yup.object({
    status: Yup.string().required('Please Select Valid Status'),
  })

  const editUserForm = useFormik({
    initialValues: {
      ...user,
      mobile_no: user.country_code + user.mobile_no,
    },
    enableReinitialize: true,
    validationSchema: editUserSchema,

    onSubmit: async (values) => {
      var formData = {
        request_id: id,
        status: values.status,
      }

      const myPromise = new Promise((resolve, reject) =>
        editRequestedDesigner(formData).then((response) => {
          if (response.code === 200) {
            resolve(response)
          } else {
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
  })
  // https://2da1-122-169-110-153.ngrok-free.app

  useEffect(() => {
    getUser({ request_id: id })
  }, [getUser, id])

  return (
    <>
      <Helmet>
        <title>View Designer Request</title>
      </Helmet>
      <AppHeader title={'Designer Request'} />
      <div className="container-fluid mt-2 px-3">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-12">
            <div className="card">
              <div
                className="card-header text-dark fw-bolder fs-4"
                style={{ backgroundColor: 'lightpink' }}
              >
                {type === 'edit' ? 'Edit' : 'View'} Designer
              </div>
              {loaded ? (
                <Loader />
              ) : (
                <div className="card-body">
                  <form name="edituser" id="edituser" onSubmit={editUserForm.handleSubmit}>
                    <div className="mb-3 row">
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="full_name" className="form-label userLabel">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="full_name"
                          id="full_name"
                          placeholder="Enter your Full Name"
                          value={editUserForm.values.full_name}
                          disabled
                        />
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="email" className="form-label userLabel">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          value={editUserForm.values.email}
                          placeholder="Enter Your Email"
                          disabled
                        />
                      </div>

                      <div className="col-12 col-xl-4 col-lg-4 col-md-2 col-sm-2 row">
                        <label htmlFor="mobile_no" className="form-label userLabel">
                          Mobile Number
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12">
                          <input
                            type="text"
                            className="form-control"
                            name="mobile_no"
                            id="mobile_no"
                            placeholder="Your Mobile No"
                            value={editUserForm.values.mobile_no}
                            style={{ width: '106%' }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="is_subscribed" className="form-label userLabel">
                          Subscribed
                        </label>

                        <input
                          type="text"
                          className="form form-control"
                          name="is_subscribed"
                          id="is_subscribed"
                          placeholder="Your Subscription Type"
                          value={
                            editUserForm.values.is_subscribed === 1 ? 'Paid User' : 'Free User'
                          }
                          disabled
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12 col-xl-4 col-lg-4">
                        <label htmlFor="about" className="form-label userLabel">
                          About
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="about"
                          id="about"
                          rows={3}
                          placeholder="Enter Something About Yourself"
                          value={
                            editUserForm.values.about !== null &&
                              editUserForm.values.about !== undefined
                              ? editUserForm.values.about
                              : 'NOT FILLED YET!'
                          }
                          disabled
                        />
                      </div>
                      <div className="col-12 col-xl-4 col-lg-4">
                        <label htmlFor="portfolio_link" className="form-label userLabel">
                          Portfolio Link
                        </label>

                        <textarea
                          type="text"
                          className="form-control"
                          name="portfolio_link"
                          id="portfolio_link"
                          rows={3}
                          placeholder="Enter Your Portfolio Link"
                          value={
                            editUserForm.values.portfolio_link !== null &&
                              editUserForm.values.portfolio_link !== undefined
                              ? editUserForm.values.portfolio_link
                              : 'NOT FILLED YET!'
                          }
                          onChange
                          disabled
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="status" className="form-label userLabel required">
                          Status
                        </label>
                        <select
                          className="form form-select"
                          value={editUserForm.values.status}
                          onChange={(e) => editUserForm.handleChange(e)}
                          id="status"
                          name="status"
                          // disabled={!(isUserAdmin || getUserAccess.is_edit === 1)}
                          disabled={type === 'view'}
                        >
                          <option value="requested" disabled={true}>Requested</option>
                          <option value="accepted">Accepted</option>
                          <option value="rejected">Rejected</option>
                        </select>
                        {editUserForm.errors.status && editUserForm.touched.status ? (
                          <span className="error mt-2">{editUserForm.errors.status}</span>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end ">
                      <Link className="btn btn-outline-danger me-2" to="/designerrequest">
                        <i className="fas fa-arrow-left" /> Back
                      </Link>
                      {(isUserAdmin || getUserAccess.is_edit === 1) && type === 'edit' ? (
                        <button type="submit" className="btn btn-outline-success">
                          Edit
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

ViewDesignerRequest.propTypes = {
  type: PropTypes.string,
}

export default ViewDesignerRequest
