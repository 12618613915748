import AWS from 'aws-sdk'
import Globals from './utils'
import moment from 'moment';
function generateRandomFileName() {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 7; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  const currentDateTime = moment().format("YYYY_MM_DD_HH_mm_ss");
  const filename = `${result}_${currentDateTime}`;
  console.log('filename123123: ', filename);
  return filename;
}
const uploadToS3 = (file, folderName, acl = '') => {
  console.log('folderNameeeeeeeeeee: ', folderName, generateRandomFileName());
  return new Promise((resolve, reject) => {
    // Configure AWS SDK with your credentials
    AWS.config.update({
      accessKeyId: Globals.S3_ACCESS_KEY,
      secretAccessKey: Globals.S3_SECRET_ACCESS_KEY,
      region: Globals.S3_REGION,
    })

    const s3 = new AWS.S3()

    // Define the parameters for uploading
    const params = {
      Bucket: Globals.S3_BUCKET_NAME,
      Key: `templify/${folderName}/${generateRandomFileName() + file.name}`,
      Body: file,
      ContentType: file.type,
      // ACL: acl.length ? acl : 'private', // Set the ACL permissions for the file
      ACL: "public-read"
    }

    // Upload the file to S3
    s3.upload(params, (err, data) => {
      if (err) {
        reject(err)
      } else {
        console.log('S3 data: ', data)
        resolve(data) // Return the URL of the uploaded file
      }
    })
  })
}
const deleteFromS3 = (url, folderName, acl = '') => {
  return new Promise((resolve, reject) => {
    // Configure AWS SDK with your credentials
    AWS.config.update({
      accessKeyId: Globals.S3_ACCESS_KEY,
      secretAccessKey: Globals.S3_SECRET_ACCESS_KEY,
      region: Globals.S3_REGION,
    })

    const s3 = new AWS.S3()

    // Define the parameters for uploading
    const urlObj = new URL(url);

    // Get the pathname and remove the leading '/'
    const key = urlObj.pathname.substring(1);

    const params = {
      Bucket: Globals.S3_BUCKET_NAME,
      Key: key,
    }

    // Upload the file to S3
    s3.deleteObject(params, (err, data) => {
      if (err) {
        // reject(err)
      } else {
        console.log('S3 data: ', data)
        resolve(data) // Return the URL of the uploaded file
      }
    })
  })

}
const getFileFromS3 = (key) => {
  return new Promise((resolve, reject) => {
    // Configure AWS SDK with your credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
    });

    const s3 = new AWS.S3();

    // Define the parameters for getting the object
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: key,
    };

    // Get the file from S3
    s3.getObject(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data); // Return the data of the file
      }
    });
  });
};
export { getFileFromS3, deleteFromS3 }
export default uploadToS3
