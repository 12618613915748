import React, { Suspense, useEffect, useState } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { Outlet, useNavigate } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import { getToken } from 'src/common/LocalStorageService'
import { FilterProvider } from 'src/common/FilterContex'

const DefaultLayout = () => {
  const navigate = useNavigate()
  const [isRender, setIsRender] = useState(false)

  useEffect(() => {
    const isLoggedIn = !getToken()

    if (isLoggedIn) {
      navigate('/')
    } else {
      setIsRender(true)
    }
  }, [navigate])
  if (!isRender) {
    return null
  }
  return (
    <FilterProvider>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <div className="body flex-grow-1">
            <CContainer fluid className="p-0">
              {/* <Suspense fallback={<CSpinner color="primary" />}> */}
              <Outlet />
              {/* </Suspense> */}
            </CContainer>
          </div>
          <AppFooter />
        </div>
      </div>
    </FilterProvider>
  )
}

export default DefaultLayout
