import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { blockUnblockUser, deleteUserApi, getUserReport, getUsers } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Globals from 'src/common/utils'
import moment from 'moment'
import { CButton } from '@coreui/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { exportExcelFile } from 'src/common/genrateExcel'
import _ from 'lodash'

const User = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('User')
  const clickRef = useRef()
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [user, setUser] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'u.id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return {
      page: 1,
      type: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      subscription_type: '',
      subscription_duration: '',
      ...sorting,
    }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'user' && { ...prevFilters }),
  })
  console.log('payload: ', payload);

  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'user' && { ...prevFilters }),
  })

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const navigate = useNavigate()

  const getUser = useCallback((payload) => {
    getUsers(payload).then((response) => {
      if (response.code === 200) {
        setUser(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setUser([])
        setLoaded(false)
      }
    })
  }, [])

  const handleBlockUnblockUser = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Unblock it!' : 'Yes, Block it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        minWidth: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            blockUnblockUser({ userId: id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                setUser((prev) =>
                  prev.map((item) => {
                    if (id === item.id) {
                      item.is_block = status === 0 ? 1 : 0
                    }
                    return item
                  }),
                )
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const deleteUser = async (e, id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        minWidth: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            deleteUserApi({ userId: id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                if (user.length >= 2) {
                  getUser(filter)
                } else {
                  setFilter((prev) => {
                    return { ...prev, page: filter.page - 1 }
                  })
                }

                // const filteredData = user.filter((item) => item.id !== id)
                // setUser(filteredData)
                // setTotalRows((prev) => prev - 1)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '10px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      minWidth: '50px',
      sort_key: 'u.id',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Full Name',
      selector: (row) => row.full_name,
      sort_key: 'u.full_name',
      sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sort_key: 'u.email',
      sortable: true,
      minWidth: '300px',
      grow: 2,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Mobile Number',
      selector: (row) => (
        <>
          {row.country_code} {row.mobile_no}
        </>
      ),
      // sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Type',
      selector: (row) => (row.type === 'user' ? 'Customer' : 'Designer'),
      sort_key: 'u.type',

      minWidth: '100px',
      sortable: true,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Subscription',
      selector: (row) => (row.is_subscribed === 0 ? 'Free Customer' : 'Paid Customer'),
      minWidth: '150px',
      sort_key: 'u.is_subscribed',
      sortable: true,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Subscription Type',
      selector: (row) => row.is_subscribed ? _.capitalize(row.subscribed_type) + ' / ' + _.capitalize(row.subscription_duration) : ' - ',
      sortable: true,
      sort_key: 'u.subscribed_type',
      minWidth: '300px',
      grow: 2,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'DOB',
      // selector: (row) => moment(row.dob).format('DD-MM-YYYY'),
      selector: (row) => {
        const date = moment(row.dob);
        return date.isValid() ? date.format('DD-MM-YYYY') : '-';
      },
      minWidth: '150px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Joining Date',
      selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
      minWidth: '150px',
      sort_key: 'u.created_at',
      sortable: true,
      style: {
        fontSize: '14px',
      },
    },
    (isUserAdmin || getUserAccess?.is_edit) && {
      name: 'Block/Unblock',
      minWidth: '150px',
      alignItems: 'center',
      cell: (row) => (
        <li
          className={
            row.is_block === 0
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={() => handleBlockUnblockUser(row.id, row.is_block)}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_block === 0
                  ? 'fas fa-toggle-off text-success'
                  : 'fas fa-toggle-on text-danger'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
      ),
    },
    (isUserAdmin || getUserAccess.is_view || getUserAccess.is_remove) && {
      name: 'Action',
      minWidth: '150px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/user/view-user/${row.id}`)}
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
          {(isUserAdmin || getUserAccess.is_remove === 1) && (
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={(e) => deleteUser(e, row.id)}>
                <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
        </ul>
      ),
    },
  ]

  const exportExcel = useCallback((payload) => {
    const myPromise = new Promise((resolve, reject) =>
      getUserReport(payload).then(
        (response) => {
          if (response.code === 200) {
            let columns = [
              {
                header: 'ID',
                key: 'id',
                width: 15,
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true,
              },
              { header: 'Name', key: 'full_name', width: 30, horizontal: 'center' },
              { header: 'Email', key: 'email', width: 30, horizontal: 'center' },
              { header: 'Phone', key: 'number', width: 30, horizontal: 'center' },
              { header: 'Type', key: 'subscribed_type', width: 15, horizontal: 'center' },
              { header: 'DOB', key: 'dob', width: 15, horizontal: 'center' },
              { header: 'Status', key: 'is_block', width: 15, horizontal: 'center' },
              { header: 'Date Of Joining', key: 'created_at', width: 15, horizontal: 'center' },
            ]

            const ExportData = response?.data?.map((user) => {
              return {
                ...user,
                number: `${user?.country_code} ${user?.mobile_no}`,
                subscribed_type: user?.is_subscribed ? _.capitalize(user?.subscribed_type) : 'Free',
                dob: user?.dob?.length ? moment(user.dob).format('DD-MM-YYYY') : '',
                created_at: user?.created_at?.length
                  ? moment(user.created_at).format('DD-MM-YYYY')
                  : '',
                is_block: user?.is_block ? 'Inctive' : 'Active',
              }
            })

            const fileName = `User_Report_` + moment().format('DDMMYYYYhmmss')
            exportExcelFile(columns, ExportData, fileName)
            resolve(response)
          } else {
            reject(response)
          }
        },
      ),
    )
    showPromiseNotification(myPromise)
  }, [filter?.end_date, filter?.start_date])

  const setDates = (e, { startDate, endDate }) => {
    setPayload((prev) => ({
      ...prev,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    }))
  }

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getUser(filter)
    setPrevFilters({ ...filter, listing_page: 'user' })
  }, [filter, getUser, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>User Page</title>
      </Helmet>
      <AppHeader title="All Users" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            title={`${filter.type === 'user'
              ? 'Customer'
              : filter.type === 'designer'
                ? 'Designer'
                : 'All Users'
              } Listing`}
            columns={columns}
            data={user}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row row-gap-2 ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <DateRangePicker
                      onApply={setDates}
                      initialSettings={{
                        startDate: moment().subtract(6, 'days').format('MM/DD/YYYY'),
                        endDate: moment().format('MM/DD/YYYY'),
                        ranges: Globals.datePickerRanges,
                        autoUpdateInput: false,
                      }}
                    >
                      <input
                        type="text"
                        value={
                          payload.start_date !== ''
                            ? moment(payload.start_date).format('MMMM DD, YYYY') +
                            ' - ' +
                            moment(payload.end_date).format('MMMM DD, YYYY')
                            : 'Select Period'
                        }
                        className=" form-control "
                        onChange={() => { }}
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('type', e.target.value)}
                      value={payload.type}
                    >
                      <option value="" disabled selected>
                        Select Users
                      </option>
                      <option value="user">Customers</option>
                      <option value="designer">Designer</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('subscription_type', e.target.value)}
                      value={payload.subscription_type}
                    >
                      <option value="" disabled selected>
                        Select Subscription Type
                      </option>
                      <option value="pro">Pro</option>
                      <option value="enterprise">Enterprise</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('subscription_duration', e.target.value)}
                      value={payload.subscription_duration}
                    >
                      <option value="" disabled selected>
                        Select Subscription Duration
                      </option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                </div>
                <>
                  <CButton
                    color="primary"
                    onClick={() => {
                      applyFilter()
                    }}
                    ref={clickRef}
                    size="sm"
                    className="mt-3 theme-custom-btn"
                    disabled={
                      !payload.search.length && !payload.type.length && !payload.start_date.length && !payload.subscription_type.length && !payload.subscription_duration.length
                    }
                  >
                    Apply
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      clearFilter()
                    }}
                    disabled={
                      !payload.search.length && !payload.type.length && !payload.start_date.length && !payload.subscription_type.length && !payload.subscription_duration.length
                    }
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Clear
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    disabled={user && user.length <= 0}
                    onClick={() => {
                      exportExcel(filter)
                    }}
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Export
                  </CButton>
                </>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default User
