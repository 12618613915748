import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { myEarning } from 'src/api/apiHandler'
import moment from 'moment'
import { CButton, CCol, CRow, CWidgetStatsF } from '@coreui/react'

import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { useFilter } from 'src/common/FilterContex'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Globals from 'src/common/utils'
import CIcon from '@coreui/icons-react'
import { cilChartPie } from '@coreui/icons'
import _ from 'lodash'

const MyEarning = () => {
  const clickRef = useRef()
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [earningList, setEarningList] = useState({
    data: [],
  })
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'ut.id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return {
      page: 1,
      subscription_type: '',
      subscription_duration: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      ...sorting,
    }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'my earning' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'my earning' && { ...prevFilters }),
  })

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])

  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const getMyEarnig = useCallback((payload) => {
    myEarning(payload).then((response) => {
      if (response.code === 200) {
        setEarningList(response.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setEarningList([])
        setLoaded(false)
      }
    })
  }, [])

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '10px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      sort_key: 'ut.id',
      minWidth: '50px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'User',
      selector: (row) => row.full_name,
      sortable: true,
      sort_key: 'u.full_name',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Subscription',
      selector: (row) => _.capitalize(row.subscription_type) + ' / ' + _.capitalize(row.subscription_duration),
      sortable: true,
      sort_key: 'u.subscription_type',
      minWidth: '300px',
      grow: 2,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Amount',
      selector: (row) => '$' + row.price / 2,
      sortable: true,
      sort_key: 'ut.price',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },

    {
      name: 'Start Date',
      selector: (row) => moment(row.start_date).format('DD-MM-YYYY'),
      minWidth: '150px',
      sortable: true,
      sort_key: 'cd.start_date',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'End Date',
      selector: (row) => moment(row.end_date).format('DD-MM-YYYY'),
      minWidth: '150px',
      sortable: true,
      sort_key: 'cd.end_date',
      sortFunction: (a, b) => {
        const dateA = moment(a.end_date, 'YYYY-MM-DD')
        const dateB = moment(b.end_date, 'YYYY-MM-DD')
        return dateA - dateB
      },
      style: {
        fontSize: '14px',
      },
    },
  ]

  const setDates = (e, { startDate, endDate }) => {
    setPayload((prev) => ({
      ...prev,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    }))
  }

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getMyEarnig(filter)
    setPrevFilters({ ...filter, listing_page: 'my earning' })
  }, [filter, getMyEarnig, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>My Earning Page</title>
      </Helmet>
      <AppHeader title="My Earning" />

      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            // title={'My Earning'}
            columns={columns}
            data={earningList?.data}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 pt-3">
                {/* <CRow>
                  <CCol xs={4}>
                    <CWidgetStatsF
                      className="mb-3"
                      color="primary"
                      icon={<CIcon icon={cilChartPie} height={24} />}
                      title="Widget title"
                      value="89.9%"
                    />
                  </CCol>
                </CRow> */}

                <div className="mb-3 fw-bold">Total Earning:- ${earningList?.total_price}</div>
                <div className="row row-gap-2 ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  {/* <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <DateRangePicker
                      onApply={setDates}
                      initialSettings={{
                        startDate: moment().subtract(6, 'days').format('MM/DD/YYYY'),
                        endDate: moment().format('MM/DD/YYYY'),
                        ranges: Globals.datePickerRanges,
                        autoUpdateInput: false,
                      }}
                    >
                      <input
                        type="text"
                        value={
                          payload.start_date !== ''
                            ? moment(payload.start_date).format('MMMM DD, YYYY') +
                              ' - ' +
                              moment(payload.end_date).format('MMMM DD, YYYY')
                            : 'Select Period'
                        }
                        className=" form-control "
                        onChange={() => {}}
                      />
                    </DateRangePicker>
                  </div> */}
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('subscription_type', e.target.value)}
                      value={payload.subscription_type}
                    >
                      <option value="" disabled selected>
                        Select Subscription Type
                      </option>
                      <option value="pro">Pro</option>
                      <option value="enterprise">Enterprise</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('subscription_duration', e.target.value)}
                      value={payload.subscription_duration}
                    >
                      <option value="" disabled selected>
                        Select Subscription Duration
                      </option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                </div>
                <>
                  <CButton
                    color="primary"
                    onClick={() => {
                      applyFilter()
                    }}
                    ref={clickRef}
                    size="sm"
                    className="mt-3 theme-custom-btn"
                    disabled={
                      !payload.search.length &&
                      !payload.subscription_type.length &&
                      !payload.subscription_duration.length
                    }
                  >
                    Apply
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      clearFilter()
                    }}
                    disabled={
                      !payload.search.length &&
                      !payload.subscription_type.length &&
                      !payload.subscription_duration.length
                    }
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Clear
                  </CButton>
                </>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default MyEarning
