import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CButton } from '@coreui/react'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import { addStickerApi, editStickerApi } from 'src/api/apiHandler'
import { showErrorMessage, showPromiseNotification } from 'src/common/notification'
import uploadToS3 from 'src/common/s3AwsService'

const StickerModal = (props) => {
  const {
    addShapeModal,
    setAddShapeModal,
    initialFilter,
    setFilter,
    setPayload,
    getStickerList,
    data = {},
    setData,
    setShape,
  } = props
  const [isImgChange, setIsImgChange] = useState(false)
  const fileRef = useRef()
  const [iconImage, setIconImage] = useState(null)
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('* Please Enter Sticker Name')
      .min(3, '* Too Short!')
      .max(30, '* Too Long!')
      .matches(/^\S(?:\s?\S)*$/, '* Not start with white space'),
    icon_image: Yup.mixed()
      .test('fileSize', 'File size is too large (max 10 MB)', (value) => {
        if (!value || !value.size) return true
        return value.size <= 10 * 1024 * 1024
      })
      .required('Please Add Sticker Image'),
  })
  useEffect(() => {
    if (data?.data?.image) {
      setIconImage(data?.data?.image)
    }
  }, [data?.data?.image])
  const addEditForm = useFormik({
    enableReinitialize: true,
    initialValues: { name: data?.data?.name || '', icon_image: data?.data?.image || '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const handlePromise = (promise) => {
        return new Promise((resolve, reject) => {
          promise
            .then((response) => {
              if (response.code === 200) {
                if (data?.type === 'add') {
                  setFilter(initialFilter)
                  setPayload(initialFilter)
                  getStickerList(initialFilter)
                } else if (data?.type == 'edit') {
                  setShape((prevIcon) => {
                    return prevIcon.map((item) => {
                      if (item.id === data?.data?.id) {
                        item = { ...item, ...response.data }
                      }
                      return item
                    })
                  })
                }
                resolve(response)
                addEditForm.setFieldValue('icon_image', null)
                addEditForm.resetForm()
                setData({})
                setIsImgChange(false)
                setIconImage(null)
                setAddShapeModal(false)
              } else {
                reject(response)
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
      const payload = {
        name: values.name,
      }
      const processUpload = async () => {
        if (isImgChange) {
          const response = await uploadToS3(values.icon_image, 'stickers')
          payload.image = response.key
          if (data?.data?.id) {
            payload.id = data?.data?.id
          }
          console.log('payload: ', payload)
          return await handlePromise(
            data?.data?.id ? editStickerApi(payload) : addStickerApi(payload),
          )
        } else {
          const { image, ...restpayload } = payload
          if (data?.data?.id) {
            restpayload.id = data?.data?.id
          }
          return await handlePromise(
            data?.data?.id ? editStickerApi(restpayload) : addStickerApi(restpayload),
          )
        }
      }
      showPromiseNotification(processUpload())
    },
  })
  const handleImageChange = useCallback(
    (e) => {
      const file = e.target.files[0]
      console.log('file: ', file)
      if (file && file.type.startsWith('image')) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setIconImage(reader?.result)
        }
        reader.readAsDataURL(file)
        setIsImgChange(true)
        setIconImage(file)
        addEditForm.setFieldValue('icon_image', file)
      } else if (file) {
        setIsImgChange(false)
        setIconImage(null)
        showErrorMessage('Only image files are allowed.')
        addEditForm.setFieldError('icon_image', 'Only image files are allowed.')
        addEditForm.setFieldValue('icon_image', null)
      } else {
        if (data?.type !== 'edit') {
          setIsImgChange(false)
          addEditForm.setFieldError('icon_image', 'Please Add Sticker Image')
          addEditForm.setFieldValue('icon_image', null)
        }
      }
    },
    [addEditForm, data?.type],
  )
  return (
    <>
      <div
        className={addShapeModal ? 'modal fade show d-block' : 'modal fade'}
        tabIndex={-1}
        data-coreui-backdrop="static"
        data-coreui-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {data?.type === 'edit' ? 'Edit' : data?.type === 'view' ? 'View' : 'Add'} Sticker
              </h5>
              <button
                type="button"
                className="btn-close"
                data-coreui-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setData({})
                  addEditForm.resetForm()
                  setAddShapeModal(false)
                  setIsImgChange(false)
                  fileRef.current.value = '';
                }}
              />
            </div>
            <form onSubmit={addEditForm.handleSubmit}>
              <div className="modal-body">
                <div className="mb-3 row">
                  <input
                    className="img-input d-none"
                    id="file"
                    // {...register('profile_image')}
                    name="icon_image"
                    accept="image/*"
                    type="file"
                    // style={{ height: 'fit-content' }}
                    onChange={(e) => handleImageChange(e)}
                    ref={fileRef}
                    disabled={data?.type == 'view'}
                  />
                  <div
                    className="icon-profile mb-3 col-12"
                    style={{ display: !isImgChange && !data?.data?.image && 'none' }}
                  >
                    <label
                      className={data?.type === 'edit' ? '-label custom-hover' : '-label'}
                      htmlFor="file"
                    >
                      <i className="fas fa-camera"></i>
                      <span>Change Image</span>
                    </label>
                    {/* <input
                                            className="img-input"
                                            id="file"
                                            // {...register('profile_image')}
                                            name='icon_image'
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => handleImageChange(e)}
                                            // disabled={data?.type !== 'edit'}
                                            /> */}
                    <img
                      src={iconImage}
                      // src={defaultImage}
                      id="output"
                      alt="Profile"
                      width="200"
                      className="img-thumbnail icon-image"
                      onClick={() => {
                        fileRef.current.click()
                      }}
                    />
                  </div>
                  <div
                    className="area"
                    style={{ display: (isImgChange || data?.data?.image) && 'none' }}
                    onClick={() => {
                      fileRef.current.click()
                    }}
                  >
                    <div id="dropZone">Add sticker here</div>
                  </div>
                  {addEditForm.submitCount && addEditForm.errors.icon_image ? (
                    <span className="error mt-2">{addEditForm.errors.icon_image}</span>
                  ) : null}
                  <div className="col-12">
                    <label htmlFor="name" className="form-label userLabel">
                      <span className="text-danger">*</span> Sticker Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter sticker name"
                      value={addEditForm.values.name}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.name ? (
                      <span className="error mt-2">{addEditForm.errors.name}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <CButton
                  color="primary"
                  onClick={() => {
                    setData({})
                    addEditForm.resetForm()
                    setAddShapeModal(false)
                    setIsImgChange(false)
                    setIconImage(null)
                    fileRef.current.value = '';
                  }}
                  className="theme-custom-btn-outline"
                >
                  Close
                </CButton>
                {data?.type !== 'view' && (
                  <CButton
                    color="primary"
                    type="submit"
                    // onClick={() => {
                    //   setAddCategoryModal(false)
                    // }}
                    className="theme-custom-btn"
                  >
                    {data?.type === 'edit' ? 'Edit' : 'Add'}
                  </CButton>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {addShapeModal && <div className="modal-backdrop fade show"></div>}
    </>
  )
}

StickerModal.propTypes = {
  addShapeModal: PropTypes.bool,
  setAddShapeModal: PropTypes.func,
  setFilter: PropTypes.func,
  setPayload: PropTypes.func,
  initialFilter: PropTypes.object,
  getStickerList: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  setShape: PropTypes.func,
}

export default StickerModal
