import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import {
  activeInactiveTemplateApi,
  blockUnblockSubAdmin,
  deleteSubAdmin,
  deleteTemplateApi,
  editTemplateStatusApi,
  getActiveTemplateReport,
  getSubAdmin,
  getTemplateApi,
} from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import moment from 'moment'
import { CButton } from '@coreui/react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'
import Swal from 'sweetalert2'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Globals from 'src/common/utils'
import { exportExcelFile } from 'src/common/genrateExcel'
import _ from 'lodash'

const Template = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Templates')
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [template, setTemplate] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 't.id',
    sort_direction: 'desc',
  })
  const initialFilter = useMemo(() => {
    return {
      page: 1,
      type: '',
      status: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      ...sorting,
    }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'subadmin' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'subadmin' && { ...prevFilters }),
  })
  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    const { limit, ...updatedPayload } = payload
    console.log('payload: ', payload)
    setFilter((prev) => ({ ...prev, ...updatedPayload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const navigate = useNavigate()

  const setDates = (e, { startDate, endDate }) => {
    setPayload((prev) => ({
      ...prev,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    }))
  }
  const getTemplate = useCallback((payload) => {
    getTemplateApi(payload).then((response) => {
      console.log('response: ', response)
      if (response.code === 200) {
        setTemplate(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setTemplate([])
        setLoaded(false)
      }
    })
  }, [])
  const updateStatus = (e, id) => {
    console.log('e: ', e)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'You want to change the status',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            editTemplateStatusApi({ template_id: id, type: e }).then((response) => {
              if (response.code === 200) {
                setTemplate((item) =>
                  item.map((val) => {
                    if (val.id === id) val.type = e
                    return val
                  }),
                )
                resolve(response)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }
  const handleActiveInactiveTemplate = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Inactive it!' : 'Yes, Active it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        minWidth: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            activeInactiveTemplateApi({ template_id: id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                setTemplate((prev) =>
                  prev.map((item) => {
                    if (id === item.id) {
                      item.is_active = status === 0 ? 1 : 0
                    }
                    return item
                  }),
                )
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }
  const HandleDeleteTemplate = async (id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            deleteTemplateApi({ template_id: id }).then((response) => {
              console.log('response: ', response)
              if (response.code === 200) {
                resolve(response)
                if (template.length >= 2) {
                  getTemplate(filter)
                } else {
                  setFilter((prev) => {
                    return { ...prev, page: filter.page - 1 }
                  })
                }
                // const filteredData = template.filter((item) => item.id !== id)
                // setTemplate(filteredData)
                // setTotalRows((prev) => prev - 1)
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      sort_key: 't.id',
      minWidth: '50px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Designer Name',
      selector: (row) => row.full_name,
      sortable: true,
      sort_key: 'u.full_name',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Template Name',
      selector: (row) => row.name,
      sortable: true,
      sort_key: 't.name',
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Template',
      // selector: (row) => row.thumbnail,
      minWidth: '150px',
      style: {
        fontSize: '14px',
      },
      cell: (row) => (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img src={row.thumbnail} height={50} width={50} alt="Image" />
      ),
    },
    {
      name: 'Download Count',
      selector: (row) => row.download_count || 0,
      sortable: true,
      sort_key: 't.download_count',
      minWidth: '200px',
      center: true,
      align: 'center',
      style: {
        fontSize: '14px',
        textAlign: 'center',
      },
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format('DD-MMM-YYYY'),
      minWidth: '150px',
      sortable: true,
      sort_key: 't.created_at',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Request',
      minWidth: '160px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <>
          {row.type ? (
            <ul className="list-inline mt-2">
              <li className="list-inline-item">
                <select
                  className="form-select"
                  onChange={(e) => updateStatus(e.target.value, row.id)}
                  value={row.type}
                  disabled={!(isUserAdmin || getUserAccess.is_edit === 1)}
                >
                  {[
                    { value: 'requested', label: 'Requested', disabled: true },
                    { value: 'accepted', label: 'Accepted' },
                    { value: 'rejected', label: 'Rejected' },
                  ]?.map((item) => {
                    return (
                      <option key={item?.value} value={item?.value} disabled={item.disabled}>
                        {item?.label}
                      </option>
                    )
                  })}
                </select>
              </li>
            </ul>
          ) : (
            <p className="ms-4">-</p>
          )}
        </>
      ),
    },
    (isUserAdmin || getUserAccess.is_edit === 1) && {
      name: 'Status',
      minWidth: '150px',
      alignItems: 'center',
      cell: (row) => (
        //  <OverlayTrigger placement="top" overlay={<Tooltip>Active/Inactive</Tooltip>}>
        <li
          className={
            row.is_active === 0
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={() => handleActiveInactiveTemplate(row.id, row.is_active)}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_active === 0
                  ? 'fas fa-toggle-off text-danger'
                  : 'fas fa-toggle-on text-success'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
        //  </OverlayTrigger  >
      ),
    },
    {
      name: 'Action',
      minWidth: '250px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={() => navigate(`/view-template/${row.id}`)}>
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger >
          )}
          {/* {(isUserAdmin || getUserAccess.is_edit === 1) && (
           //  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
              <li className="list-inline-item">
                <button
                  className="btn btn-link"
                  onClick={() => {
                    navigate(`/sub-admin/edit/${row.id}`)
                  }}
                >
                  <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
                </button>
              </li>
            //  </OverlayTrigger >
          )} */}
          {(isUserAdmin || getUserAccess.is_remove === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <li className="list-inline-item">
              <button className="btn btn-link" onClick={(e) => HandleDeleteTemplate(row.id)}>
                <i className="fas fa-trash-alt text-danger" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger  >
          )}
        </ul>
      ),
    },
  ]

  const exportExcel = useCallback((payload) => {
    const myPromise = new Promise((resolve, reject) =>
      getActiveTemplateReport({ start_date: filter?.start_date, end_date: filter?.end_date, type: payload.type, status: payload.status, search: payload.search }).then(
        (response) => {
          console.log('response excel:', response);
          if (response.code === 200) {
            let columns = [
              {
                header: 'ID',
                key: 'id',
                width: 15,
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true,
              },
              { header: 'Name', key: 'full_name', width: 30, horizontal: 'center' },
              { header: 'Email', key: 'email', width: 30, horizontal: 'center' },
              { header: 'Template Name', key: 'name', width: 30, horizontal: 'center' },
              { header: 'Orientation', key: 'orientation', width: 30, horizontal: 'center' },
              { header: 'Download Count', key: 'download_count', width: 15, horizontal: 'center' },
              { header: 'Type', key: 'is_type', width: 15, horizontal: 'center' },
              { header: 'Status', key: 'is_active', width: 15, horizontal: 'center' },
              { header: 'Template Created Date', key: 'created_at', width: 30, horizontal: 'center' },
            ]

            const ExportData = response?.data?.map((user) => {
              return {
                ...user,
                created_at: user?.created_at?.length
                  ? moment(user.created_at).format('DD-MM-YYYY')
                  : '',
                is_active: user?.is_active ? 'Active' : 'Inactive',
                is_type: user?.type == 'requested' ? 'Requested' : user.type == 'accepted' ? 'Accepted' : user.type == 'rejected' ? 'Rejected' : 'Pending',
              }
            })

            const fileName = `Template_Report_` + moment().format('DDMMYYYYhmmss')
            exportExcelFile(columns, ExportData, fileName)
            resolve(response)
          } else {
            reject(response)
          }
        },
      ),
      
    )
    showPromiseNotification(myPromise)
  }, [filter?.end_date, filter?.start_date])

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getTemplate(filter)
    setPrevFilters({ ...filter, listing_page: 'subadmin' })
  }, [filter, getTemplate, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Template Page</title>
      </Helmet>
      <AppHeader title="Template" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            title={'Template'}
            columns={columns}
            data={template}
            pagination
            paginationServer
            onSort={handleSort}
            sortServer
            responsive
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-3 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-10 col-12">
                    <DateRangePicker
                      onApply={setDates}
                      initialSettings={{
                        startDate: moment().subtract(6, 'days').format('MM/DD/YYYY'),
                        endDate: moment().format('MM/DD/YYYY'),
                        ranges: Globals.datePickerRanges,
                        autoUpdateInput: false,
                      }}
                    >
                      <input
                        type="text"
                        value={
                          payload.start_date !== ''
                            ? moment(payload.start_date).format('MMMM DD, YYYY') +
                            ' - ' +
                            moment(payload.end_date).format('MMMM DD, YYYY')
                            : 'Select Period'
                        }
                        className=" form-control "
                        onChange={() => { }}
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('type', e.target.value)}
                      value={payload.type}
                    >
                      <option value={''} disabled={true}>
                        Select Request Type
                      </option>
                      {[
                        { value: 'requested', label: 'Requested' },
                        { value: 'accepted', label: 'Accepted' },
                        { value: 'rejected', label: 'Rejected' },
                      ]?.map((item) => {
                        return (
                          <option key={item?.value} value={item?.value} disabled={item.disabled}>
                            {item?.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('status', e.target.value)}
                      value={payload.status}
                    >
                      <option value={''} disabled={true} selected>
                        Select Status
                      </option>
                      {[
                        { value: '1', label: 'Active' },
                        { value: '0', label: 'Inactive' },
                      ]?.map((item) => {
                        return (
                          <option key={item?.value} value={item?.value} disabled={item.disabled}>
                            {item?.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-between  ">
                  <div>
                    <CButton
                      color="primary"
                      onClick={() => {
                        applyFilter()
                      }}
                      disabled={
                        !payload?.search?.length &&
                        !payload.type.length &&
                        !payload.start_date.length &&
                        !payload.status
                      }
                      size="sm"
                      className="mt-3 theme-custom-btn"
                    >
                      Apply
                    </CButton>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      disabled={
                        !payload?.search?.length &&
                        !payload.type.length &&
                        !payload.start_date.length &&
                        !payload.status
                      }
                      onClick={() => {
                        clearFilter()
                      }}
                      className="ms-3 mt-3 theme-custom-btn-outline"
                    >
                      Clear
                    </CButton>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        exportExcel(filter)
                      }}
                      disabled={template.length <= 0}
                      className="ms-3 mt-3 theme-custom-btn-outline"
                    >
                      Export
                    </CButton>
                  </div>
                </div>
              </div>
            }
          />
        </div >
      )}
    </>
  )
}

export default Template
