import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCommentBubble,
  cilContact,
  cilEducation,
  cilFindInPage,
  cilListRich,
  cilSpeedometer,
  cilUser,
  cilUserFollow,
  cibGnuSocial,
  cibSquarespace,
  cibScribd,
  cibClockify,
  cibPrismic,
  cisCommentSquareRateReview,
  cilSitemap,
  cilFont,
  cilMoney,
  cilBellExclamation,
  cilBell,
  cilPeople,
  cilCalendarCheck,
} from '@coreui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { CNavItem } from '@coreui/react'
import { getUserDataFromLocal, isAdmin } from './common/LocalStorageService'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    title: 'Dashboard',
    to: '/dashboard',
    isDefault: true,
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'User',
    title: 'Users',
    to: '/user',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Designer Requests',
    title: 'Designer Requests',
    to: '/designerrequest',
    icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Category',
    title: 'Categories',
    to: '/category',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Sub Category',
    title: 'Sub Categories',
    to: '/sub-category',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Templates',
    title: 'Templates',
    to: '/template',
    // to: '/',
    icon: <CIcon icon={cibPrismic} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Subscription Plans',
    title: 'Subscription Plans',
    to: '/subscription',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Social Media Icons',
    title: 'Social Media Icons',
    to: '/social-media-icon',
    icon: <CIcon icon={cibGnuSocial} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Fonts',
    title: 'Fonts',
    to: '/fonts',
    icon: <CIcon icon={cilFont} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Shapes',
    title: 'Shapes',
    to: '/shapes',
    icon: <CIcon icon={cibSquarespace} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Stickers',
    title: 'Stickers',
    to: '/sticker',
    icon: <CIcon icon={cibScribd} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Cliparts',
    title: 'Cliparts',
    to: '/clipart',
    icon: <CIcon icon={cibClockify} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Monthly Settlement',
    title: 'Monthly Settlements',
    to: '/monthly-settlement',
    // to: '/',
    icon: <CIcon icon={cilCalendarCheck} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'My Earning',
    title: 'My Earning',
    to: '/my-earning',
    // to: '/',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Rating Reviews',
    title: 'Rating Reviews',
    to: '/rating-review',
    icon: <FontAwesomeIcon icon={faStar} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Sub Admin',
    title: 'Sub Admins',
    to: '/sub-admin',
    isDefault: getUserDataFromLocal() && isAdmin(),
    icon: <CIcon icon={cilEducation} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Custom Notifications',
    title: 'Custom Notifications',
    to: '/notification',
    icon: <CIcon icon={cilBellExclamation} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CMS Pages',
    title: 'CMS Pages',
    to: '/static',
    icon: <CIcon icon={cilFindInPage} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'FAQ',
    title: 'FAQs',
    to: '/faqs',
    icon: <CIcon icon={cilCommentBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'ContactUs Queries',
    title: 'Contact Us Queries',
    to: '/contactus',
    icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
  },
]

export default _nav
