import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Loader from '../loader/Loader'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { editStaticPageApi, getStaticPageApi } from 'src/api/apiHandler'
import { AppHeader } from 'src/components'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'

const modules = {
  toolbar: [
    [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ script: "super" }, { script: "sub" }],
    [{ header: [!1, 1, 2, 3, 4, 5, 6] }, 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          'custom-color',
        ],
      },

      {
        background: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          'custom-color',
        ],
      },
    ],
  ],
}

const formats = [
  'header',
  'height',
  'bold',
  'italic',
  'font',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'clean',
  'super',
  'sub',
  'list',
  'color',
  'background',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
  'size',
]
const EditStaticPage = (props) => {
  const { type } = props
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [staticPage, setStaticPage] = useState({})

  const getPage = useCallback((payload) => {
    console.log('payload: ', payload)
    getStaticPageApi(payload).then((response) => {
      if (response.code === 200) {
        setStaticPage(response.data[0])
        setLoaded(false)
      } else {
        setStaticPage([])
        setLoaded(false)
      }
    })
  }, [])

  const editPageSchema = Yup.object().shape({
    title_en: Yup.string()
      .required('Please Enter Title')
      .min(3, 'Too Short!')
      .max(100, 'Too Long!')
      ,// .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    title_fr: Yup.string()
      .required('Please Enter Title')
      .min(3, 'Too Short!')
      .max(100, 'Too Long!')
      ,// .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    title_port: Yup.string()
      .required('Please Enter Title')
      .min(3, 'Too Short!')
      .max(100, 'Too Long!')
      ,// .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    tag: Yup.string()
      .required('Please Enter Tag')
      .min(3, 'Too Short!')
      .max(100, 'Too Long!')
      ,// .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    content_en: Yup.string().required('Please Enter Content'),
    content_fr: Yup.string().required('Please Enter Content'),
    content_port: Yup.string().required('Please Enter Content'),
  })

  const editPageForm = useFormik({
    enableReinitialize: true,
    initialValues: { ...staticPage },
    validationSchema: editPageSchema,
    onSubmit: async (values) => {
      var formData = {
        page_id: id,
        ...values,
      }

      const myPromise = new Promise((resolve, reject) =>
        editStaticPageApi(formData).then((response) => {
          if (response.code === 200) {
            resolve(response)
          } else {
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
  })

  useEffect(() => {
    if (id) {
      getPage({ page_id: id })
    }
  }, [getPage, id])

  return (
    <>
      <Helmet>
        <title>Edit Static Page</title>
      </Helmet>
      <AppHeader title={'Edit Static Page'} />
      <div className="container-fluid mt-2">
        {loaded ? (
          <Loader />
        ) : (
          <div className="row justify-content-center">
            <div className="col-12 col-xl-12 col-lg-12 col-md-6 col-sm-12">
              <div className="card">
                <div
                  className="card-header text-dark fw-medium  fs-5"
                  style={{ backgroundColor: 'lightpink' }}
                >
                  {id ? editPageForm.values.tag : 'Add Static Page'}
                </div>
                <div className="card-body">
                  <form name="editPageForm" id="editPageForm" onSubmit={editPageForm.handleSubmit}>
                    <div className="mb-3 row">
                      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <label htmlFor="title_en" className="form-label userLabel">
                          <span className="text-danger">*</span> Title (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title_en"
                          id="title_en"
                          placeholder="Enter Your Title"
                          value={editPageForm.values.title_en}
                          onChange={(e) => editPageForm.handleChange(e)}
                          disabled={type === 'view'}
                        />
                        {editPageForm.submitCount && editPageForm.errors.title_en ? (
                          <span className="error mt-2">{editPageForm.errors.title_en}</span>
                        ) : null}
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <label htmlFor="tag" className="form-label userLabel">
                          <span className="text-danger">*</span> Tag
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="tag"
                          id="tag"
                          onChange={(e) => editPageForm.handleChange(e)}
                          disabled={type === 'view'}
                          value={editPageForm.values.tag}
                          placeholder="Enter Your Tag"
                        />
                        {editPageForm.errors.tag && editPageForm.submitCount ? (
                          <span className="error mt-2">{editPageForm.errors.tag}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <label htmlFor="title_fr" className="form-label userLabel">
                          <span className="text-danger">*</span> Title (French)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title_fr"
                          id="title_fr"
                          placeholder="Enter Your Title"
                          value={editPageForm.values.title_fr}
                          onChange={(e) => editPageForm.handleChange(e)}
                          disabled={type === 'view'}
                        />
                        {editPageForm.errors.title_fr && editPageForm.submitCount ? (
                          <span className="error mt-2">{editPageForm.errors.title_fr}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <label htmlFor="title_port" className="form-label userLabel">
                          <span className="text-danger">*</span> Title (Portuguese)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title_port"
                          id="title_port"
                          placeholder="Enter Your Title"
                          value={editPageForm.values.title_port}
                          onChange={(e) => editPageForm.handleChange(e)}
                          disabled={type === 'view'}
                        />
                        {editPageForm.errors.title_port && editPageForm.submitCount ? (
                          <span className="error mt-2">{editPageForm.errors.title_port}</span>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-3 row"></div>

                    <div className="mb-3 row">
                      <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="content_en" className="form-label userLabel">
                          <span className="text-danger">*</span> Content (English)
                        </label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="Please Enter CMS Content..."
                          style={{ height: '220px', marginBottom: '50px' }}
                          id="content_en"
                          value={editPageForm.values.content_en}
                          onChange={(e) => editPageForm.setFieldValue('content_en', e)}
                          readOnly={type === 'view'}
                        />

                        {editPageForm.errors.content_en && editPageForm.submitCount ? (
                          <span className="error mt-2">{editPageForm.errors.content_en}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="content_fr" className="form-label userLabel">
                          <span className="text-danger">*</span> Content (French)
                        </label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="Please Enter CMS Content..."
                          style={{ height: '220px', marginBottom: '50px' }}
                          value={editPageForm.values.content_fr}
                          onChange={(e) => editPageForm.setFieldValue('content_fr', e)}
                          readOnly={type === 'view'}
                        />

                        {editPageForm.errors.content_fr && editPageForm.submitCount ? (
                          <span className="error mt-2">{editPageForm.errors.content_fr}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="content_port" className="form-label userLabel">
                          <span className="text-danger">*</span> Content (Portuguese)
                        </label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="Please Enter CMS Content..."
                          style={{ height: '220px', marginBottom: '50px' }}
                          value={editPageForm.values.content_port}
                          onChange={(e) => editPageForm.setFieldValue('content_port', e)}
                          readOnly={type === 'view'}
                        />

                        {editPageForm.errors.content_port && editPageForm.submitCount ? (
                          <span className="error mt-2">{editPageForm.errors.content_port}</span>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center ">
                      <Link className="btn btn-outline-danger me-2" to="/static">
                        <i className="fas fa-arrow-left" /> Back
                      </Link>
                      {type === 'edit' && (
                        <button type="submit" className="btn btn-outline-success my-2">
                          Update
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

EditStaticPage.propTypes = {
  type: PropTypes.string,
}

export default EditStaticPage
