import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import DataTable from 'react-data-table-component'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import swal from 'sweetalert2'
import { activeInactiveStaticPage, getStaticPageApi } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import { CButton } from '@coreui/react'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'

const StaticPage = () => {
  const navigate = useNavigate()
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('CMS Pages')
  const { prevFilters, setPrevFilters } = useFilter()

  const initialFilter = useMemo(() => {
    return { page: 1, search: '', limit: 5 }
  }, [])

  const [staticPage, setStaticPage] = useState([])
  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'cms' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'cms' && { ...prevFilters }),
  })
  const [loaded, setLoaded] = useState(false)

  const getStaticPage = useCallback((payload) => {
    getStaticPageApi(payload).then((response) => {
      if (response.code === 200) {
        setStaticPage(response.data)
        setLoaded(false)
      } else {
        setStaticPage([])
        setLoaded(false)
      }
    })
  }, [])

  const activePage = (e, id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Inactive it!' : 'Yes, Active it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        width: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const myPromise = new Promise((resolve, reject) =>
            activeInactiveStaticPage({ page_id: id }).then((response) => {
              if (response.code === 200) {
                resolve(response)
                setStaticPage((prev) =>
                  prev.map((item) => {
                    if (id === item.id) {
                      item.is_active = status === 0 ? 1 : 0
                    }
                    return item
                  }),
                )
              } else {
                reject(response)
              }
            }),
          )
          showPromiseNotification(myPromise)
        }
      })
  }

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '1vh',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'PAGE ID',
      selector: (row, index) => row.page_id,
      sortable: true,
      minWidth: '50px',
      grow: 1,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Title',
      selector: (row) => row.title_en,
      sortable: true,
      grow: 1,

      minWidth: '250px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Tag',
      selector: (row) => row.tag,
      sortable: true,
      minWidth: '250px',
      grow: 1,

      style: {
        fontSize: '14px',
      },
    },
  ]

  if (isUserAdmin || getUserAccess.is_edit === 1) {
    columns.push({
      name: 'Status',
      selector: (row) => (
        <li
          className={
            row.is_active === 1
              ? 'text-success list-inline-item userlist'
              : 'text-danger list-inline-item userlist'
          }
          onClick={(e) => activePage(e, row.page_id, row.is_active)}
        >
          <button className="btn btn-link">
            <i
              className={
                row.is_active === 1
                  ? 'fas fa-toggle-on text-success'
                  : 'fas fa-toggle-off text-danger'
              }
              style={{ fontSize: '25px' }}
            />
          </button>
        </li>
      ),
      sortable: true,
      minWidth: '200px',
      grow: 1,

      style: {
        fontSize: '14px',
        // justifyContent: "center",
        marginLeft: '0.2%',
      },
    })
  }
  if (isUserAdmin || getUserAccess.is_edit === 1 || getUserAccess.is_view === 1) {
    columns.push({
      name: 'Action',
      minWidth: '300px',
      grow: 1,
      style: {
        textAlign: 'center',
      },
      selector: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/static/view-static/${row.id}`)}
              >
                <i className="fas fa-eye fs-6 te"></i>
              </button>
            </li>
            //  </OverlayTrigger >
          )}
          {(isUserAdmin || getUserAccess.is_edit === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/static/edit-static/${row.page_id}`)}
              >
                <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger  >
          )}
        </ul>
      ),
    })
  }

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])
  useEffect(() => {
    getStaticPage(filter)
    setPrevFilters({ ...filter, listing_page: 'cms' })
  }, [filter, getStaticPage, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>CMS Page</title>
      </Helmet>
      <AppHeader title="CMS Pages" />
      <div className="px-3">
        {loaded ? (
          <Loader />
        ) : (
          <DataTable
            title="All CMS Pages"
            columns={columns}
            data={staticPage}
            // pagination
            selectableRowsHighlight
            highlightOnHover
            style={{ width: '100%' }}
            striped
            responsive
            subHeader
            customStyles={tableCustomStyles}
            fixedHeader
            fixedFooter
            onChangeRowsPerPage={(noRows) => {
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            pointerOnHover
            subHeaderAlign="left"
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <CButton
                    color="primary"
                    onClick={() => {
                      applyFilter()
                    }}
                    size="sm"
                    className="mt-3 theme-custom-btn"
                    disabled={!payload.search.length}
                  >
                    Apply
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      clearFilter()
                    }}
                    disabled={!payload.search.length}
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Clear
                  </CButton>
                </div>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export default StaticPage
