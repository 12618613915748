import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CButton } from '@coreui/react'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import {
    addFontApi,
    editFontApi,
} from 'src/api/apiHandler'
import { showErrorMessage, showPromiseNotification } from 'src/common/notification'
import uploadToS3 from 'src/common/s3AwsService'

const FontModal = (props) => {
    const {
        addFontModal,
        setAddFontModal,
        initialFilter,
        setFilter,
        setPayload,
        getFontList,
        data = {},
        setData,
        setFontList,
        setIcon,
    } = props
    const [isFileChange, setIsFileChange] = useState(false)
    const fileRef = useRef()
    const [fontFile, setFontFile] = useState(null)
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('* Please Enter Font Name')
            .min(3, '* Too Short!')
            .max(30, '* Too Long!')
            .matches(/^\S(?:\s?\S)*$/, '* Not start with white space'),
        font_file: Yup.mixed()
            .test('fileSize', 'File size is too large (max 10 MB)', (value) => {
                if (!value || !value.size) return true;
                return value.size <= 20 * 1024 * 1024;
            })
            .test('fileType', 'Only font files are allowed (ttf, otf)', (value) => {
                console.log('value: ', value.name);
                if (!value || !value.name) return true;
                const fileName = value.name.toLowerCase();
                return fileName.endsWith('.ttf') || fileName.endsWith('.otf');
            })
            .required('* Please Add a Font File')
    })
    useEffect(() => {
        if (data?.data?.image) {
            setFontFile(data?.data?.image)
        }
    }, [data?.data?.image])
    const addEditForm = useFormik({
        enableReinitialize: true,
        initialValues: { name: data?.data?.name || '', font_file: data?.data?.image || '' },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const handlePromise = (promise) => {
                return new Promise((resolve, reject) => {
                    promise
                        .then((response) => {
                            if (response.code === 200) {
                                console.log('response: ', response)

                                if (data?.type === 'add') {
                                    setFilter(initialFilter)
                                    setPayload(initialFilter)
                                    getFontList(initialFilter)
                                } else if (data?.type == 'edit') {
                                    // setIcon(prevIcons => {
                                    //     const updatedIcons = [...prevIcons];
                                    //     const indexToUpdate = updatedIcons.findIndex(icon => icon.id === data?.data?.id);
                                    //     if (indexToUpdate !== -1) {
                                    //         updatedIcons[indexToUpdate] = response.data;
                                    //     }
                                    //     return updatedIcons;
                                    // });
                                    setFontList((prevFontFile) => {
                                        console.log('prevFontFile: ', prevFontFile);
                                        return prevFontFile.map((item) => {
                                            if (item.id === data?.data?.id) {
                                                item = { ...item, ...response.data }
                                            }
                                            return item
                                        })
                                    })
                                }
                                resolve(response)
                                addEditForm.setFieldValue('font_file', null)
                                addEditForm.resetForm()
                                setData({})
                                setIsFileChange(false)
                                setFontFile(null)
                                setAddFontModal(false)
                            } else {
                                reject(response)
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            }
            const payload = {
                name: values.name,
            }
            const processUpload = async () => {
                if (isFileChange) {
                    const response = await uploadToS3(values.font_file, 'fonts', 'public-read')
                    console.log('response.key: ', response.key);
                    payload.image = response.key
                    if (data?.data?.id) {
                        payload.id = data?.data?.id
                    }
                    return await handlePromise(
                        data?.data?.id ? editFontApi(payload) : addFontApi(payload),
                    )
                } else {
                    const { image, ...restpayload } = payload
                    if (data?.data?.id) {
                        restpayload.id = data?.data?.id
                    }
                    return await handlePromise(
                        data?.data?.id
                            ? editFontApi(restpayload)
                            : addFontApi(restpayload),
                    )
                }
            }
            showPromiseNotification(processUpload())
        },
    })
    const handleFileChange = useCallback(
        (e) => {
            const file = e.target.files[0]
            if (file && (file.name.endsWith('.ttf') || file.name.endsWith('.otf'))) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFontFile(reader.result);
                };
                reader.readAsDataURL(file);
                setIsFileChange(true);
                setFontFile(null);
                addEditForm.setFieldValue('font_file', file);
            } else if (file) {
                setIsFileChange(false)
                setFontFile(null)
                showErrorMessage('Only font files are allowed.')
                addEditForm.setFieldError('font_file', 'Only font files are allowed.')
                addEditForm.setFieldValue('font_file', null)
            } else {
                if (data?.type !== 'edit') {
                    setIsFileChange(false)
                    addEditForm.setFieldError('font_file', '* Please Add Font File')
                    addEditForm.setFieldValue('font_file', null)
                }
            }
        },
        [addEditForm, data?.type],
    )
    return (
        <>
            <div
                className={addFontModal ? 'modal fade show d-block' : 'modal fade'}
                tabIndex={-1}
                data-coreui-backdrop="static"
                data-coreui-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {data?.type === 'edit' ? 'Edit' : data?.type === 'view' ? 'View' : 'Add'} Font
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-coreui-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setData({})
                                    addEditForm.resetForm()
                                    setAddFontModal(false)
                                    setIsFileChange(false)
                                }}
                            />
                        </div>
                        <form onSubmit={addEditForm.handleSubmit}>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <input
                                        className="img-input d-none"
                                        id="file"
                                        name="file"
                                        accept=".ttf,.otf"
                                        type="file"
                                        onChange={(e) => handleFileChange(e)}
                                        ref={fileRef}
                                        disabled={data?.type === 'view'}
                                    />
                                    {fontFile && (
                                        <div style={{ fontFamily: 'CustomFont', display: (isFileChange || data?.data?.image) ? 'flex' : 'none', justifyContent: 'center' }}>
                                            <p style={{ fontSize: '45px' }}
                                                onClick={() => {
                                                    fileRef.current.click()
                                                }}
                                            >Cool Font</p>
                                            <style>
                                                {`
                                                        @font-face {
                                                            font-family: 'CustomFont';
                                                            src: url(${fontFile});
                                                        }
                                                    `}
                                            </style>
                                        </div>
                                    )}
                                    <div
                                        className="area"
                                        style={{ display: (isFileChange || data?.data?.image) && 'none' }}
                                        onClick={() => {
                                            fileRef.current.click()
                                        }}
                                    >
                                        <div id="dropZone">Add font here</div>
                                    </div>
                                    {addEditForm.submitCount && addEditForm.errors.font_file ? (
                                        <span className="error mt-2">{addEditForm.errors.font_file}</span>
                                    ) : null}
                                    <div className="col-12">
                                        <label htmlFor="name" className="form-label userLabel">
                                            <span className="text-danger">*</span> Font Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            id="name"
                                            placeholder="Enter font name"
                                            value={addEditForm.values.name}
                                            disabled={data?.type === 'view'}
                                            onChange={(e) => addEditForm.handleChange(e)}
                                        />
                                        {addEditForm.submitCount && addEditForm.errors.name ? (
                                            <span className="error mt-2">{addEditForm.errors.name}</span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <CButton
                                    color="primary"
                                    onClick={() => {
                                        setData({})
                                        addEditForm.resetForm()
                                        setAddFontModal(false)
                                        setIsFileChange(false)
                                        setFontFile(null)
                                    }}
                                    className="theme-custom-btn-outline"
                                >
                                    Close
                                </CButton>
                                {data?.type !== 'view' && (
                                    <CButton
                                        color="primary"
                                        type="submit"
                                        // onClick={() => {
                                        //   setAddCategoryModal(false)
                                        // }}
                                        className="theme-custom-btn"
                                    >
                                        {data?.type === 'edit' ? 'Edit' : 'Add'}
                                    </CButton>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {addFontModal && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

FontModal.propTypes = {
    addFontModal: PropTypes.bool,
    setAddFontModal: PropTypes.func,
    setFilter: PropTypes.func,
    setPayload: PropTypes.func,
    initialFilter: PropTypes.object,
    getFontList: PropTypes.func,
    data: PropTypes.object,
    setData: PropTypes.func,
    setIcon: PropTypes.func,
    setFontList: PropTypes.func,
}

export default FontModal
