import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { saveUserDataToLocal } from 'src/common/LocalStorageService'
import { login, resetPassword } from 'src/api/apiHandler'
import { showErrorMessage, showPromiseNotification } from 'src/common/notification'
import useQueryParams from 'src/hooks/use-query-params'
import moment from 'moment'

const ResetPassword = () => {
  let navigate = useNavigate()
  const queryParams = useQueryParams()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isconfirmPasswordVisible, setconfirmPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState)
  }

  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible((prevState) => !prevState)
  }

  const queryPayload = JSON.parse(atob(queryParams.payload))
  console.log('queryPayload: ', queryPayload)

  const LoginSchema = Yup.object({
    new_password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        'Password must contain at least one capital letter, one small letter and one number',
      )
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required('Please confirm your password'),
  })

  const loginForm = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
    },
    validationSchema: LoginSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        adminId: queryPayload?.id,
        password: values?.new_password,
      }
      const myPromise = new Promise((resolve, reject) => {
        resetPassword(payload).then((response) => {
          console.log('response: ', typeof response, response)
          if (response.code === 200) {
            resolve(response)
            navigate('/')
          } else {
            reject(response)
          }
        })
      })
      showPromiseNotification(myPromise)
    },
  })

  useEffect(() => {
    if (queryPayload?.time) {
      const givenTimeStr = queryPayload?.time
      const updatedUnixTime = moment.unix(givenTimeStr).add(1, 'hour').unix()
      // const givenTime = moment(givenTimeStr, 'YYYY-MM-DD HH:mm')
      const currentTime = moment().unix()
      // const timeDifference = givenTime.diff(currentTime, 'hours')

      if (currentTime > updatedUnixTime) {
        showErrorMessage('Reset password link was expired, please request again for reset password')
        navigate('/')
      }
    }
  }, [navigate, queryPayload?.time])

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer className="w-lg-25  w-md-50 w-sm-100 ">
          <CRow className="justify-content-center">
            <CCol sm={12} md={8} lg={4}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm name="loginForm" onSubmit={loginForm.handleSubmit}>
                      <img src={process.env.PUBLIC_URL + '/Logosvg.svg'}></img>
                      <div className="w-100 mt-5">
                        <h3 className="text-center mb-4">Reset Password</h3>
                        <p
                          style={{ fontSize: '14px', color: '#98a6ad', textAlign: 'center' }}
                          className="mb-4"
                        >
                          Enter your new password and confirm password for change your account
                          credentials
                        </p>
                        <div className="mb-3">
                          <CInputGroup className="my-1 position-relative ">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                              type={isPasswordVisible ? 'text' : 'password'}
                              name="new_password"
                              id="new_password"
                              placeholder="New Password"
                              autoComplete="new_password"
                              value={loginForm.values.new_password}
                              onChange={(e) => loginForm.handleChange(e)}
                            />

                            <i
                              onClick={() => {
                                togglePasswordVisibility()
                              }}
                              className={isPasswordVisible ? 'fa-solid fa-eye-slash' : 'fas fa-eye'}
                              style={{
                                fontSize: '20px',
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                transform: 'translate(-50%, -50%)',
                              }}
                            ></i>
                          </CInputGroup>

                          {loginForm.errors.new_password && loginForm.touched.new_password ? (
                            <span className="error">{loginForm.errors.new_password}</span>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <CInputGroup className="my-1 ">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                              type={isconfirmPasswordVisible ? 'text' : 'password'}
                              name="confirm_password"
                              id="confirm_password"
                              placeholder="Confirm Password"
                              value={loginForm.values.confirm_password}
                              onChange={(e) => loginForm.handleChange(e)}
                              autoComplete="confirm_password"
                            />
                            <i
                              onClick={() => {
                                toggleconfirmPasswordVisibility()
                              }}
                              className={
                                isconfirmPasswordVisible ? 'fa-solid fa-eye-slash' : 'fas fa-eye'
                              }
                              style={{
                                fontSize: '20px',
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                transform: 'translate(-50%, -50%)',
                              }}
                            ></i>
                          </CInputGroup>
                          {loginForm.errors.confirm_password &&
                          loginForm.touched.confirm_password ? (
                            <span className="error">{loginForm.errors.confirm_password}</span>
                          ) : null}
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <CButton color="primary" type="submit" className="px-4 theme-custom-btn">
                            Submit
                          </CButton>
                        </div>
                      </div>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default ResetPassword
