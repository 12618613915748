import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { NotificationManager } from 'react-notifications'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CommonAddApi, CommonEditApi } from 'src/services/SocialService'
import * as Yup from 'yup'
import Loader from '../loader/Loader'
import { addFAQ, editFAQ, getFAQ } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import PropTypes from 'prop-types'
import { AppHeader } from 'src/components'

const EditFaq = (props) => {
  const { type } = props
  const { id } = useParams()
  const navigate = useNavigate()

  const [faq, setFaq] = useState([])
  const path = window.location.href.split('/')[4]
  const [loaded, setLoaded] = useState(false)

  const getFaq = useCallback((payload) => {
    getFAQ(payload).then((response) => {
      if (response.code === 200) {
        setFaq(response.data.data[0])
        setLoaded(false)
      } else {
        setFaq([])
        setLoaded(false)
      }
    })
  }, [])

  const editFaqSchema = Yup.object({
    question_en: Yup.string()
      .required('Please Enter Question')
      .min(3, 'Too Short!')
      .max(500, 'Too Long!'),
    // .matches(/^\S(?:\s?\S)*$/, 'Not start or end with white space'),
    answer_en: Yup.string()
      .required('Please Enter Answer')
      .min(3, 'Too Short!')
      .max(1000, 'Too Long!'),
    // .matches(/^\S(?:\s?\S)*$/, 'Not start or end with white space'),
    question_fr: Yup.string()
      .required('Please Enter Question')
      .min(3, 'Too Short!')
      .max(500, 'Too Long!'),
    // .matches(/^\S(?:\s?\S)*$/, 'Not start or end with white space'),
    answer_fr: Yup.string()
      .required('Please Enter Answer')
      .min(3, 'Too Short!')
      .max(1000, 'Too Long!'),
    // .matches(/^\S(?:\s?\S)*$/, 'Not start or end with white space'),
    question_port: Yup.string()
      .required('Please Enter Question')
      .min(3, 'Too Short!')
      .max(500, 'Too Long!'),
    // .matches(/^\S(?:\s?\S)*$/, 'Not start or end with white space'),
    answer_port: Yup.string()
      .required('Please Enter Answer')
      .min(3, 'Too Short!')
      .max(1000, 'Too Long!'),
    // .matches(/^\S(?:\s?\S)*$/, 'Not start or end with white space'),
  })

  const editFaqForm = useFormik({
    initialValues: {
      ...faq,
    },
    enableReinitialize: true,
    validationSchema: editFaqSchema,
    onSubmit: async (values) => {
      console.log('values: ', values)
      var formData = {
        ...values,
      }

      if (id) {
        formData.faq_id = values?.id
        const myPromise = new Promise((resolve, reject) =>
          editFAQ(formData).then((response) => {
            if (response.code === 200) {
              resolve(response)
            } else {
              reject(response)
            }
          }),
        )
        showPromiseNotification(myPromise)
      } else {
        const myPromise = new Promise((resolve, reject) =>
          addFAQ(formData).then((response) => {
            if (response.code === 200) {
              resolve(response)
              navigate('/faqs')
            } else {
              reject(response)
            }
          }),
        )
        showPromiseNotification(myPromise)
      }
    },
  })

  useEffect(() => {
    if (id) {
      getFaq({ faq_id: id })
    }
  }, [getFaq, id])

  return (
    <>
      <Helmet>
        <title>{type === 'edit' ? 'Edit' : type === 'add' ? 'Add' : 'View'} FAQ</title>
      </Helmet>
      <AppHeader title={type === 'edit' ? 'Edit FAQ' : type === 'add' ? 'Add FAQ' : 'View FAQ'} />
      {loaded ? (
        <Loader />
      ) : (
        <div className="container-fluid my-2 px-3">
          <div className="card">
            <div
              className="card-header text-dark fw-bolder fs-4"
              style={{ backgroundColor: 'lightpink' }}
            >
              {type === 'edit' ? 'Edit' : type === 'add' ? 'Add' : 'View'} FAQ
            </div>
            <div className="card-body">
              <form name="editFaqForm" id="editFaqForm" onSubmit={editFaqForm.handleSubmit}>
                <div className="mb-3 row">
                  <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="question_en" className="form-label userLabel">
                      <span className="text-danger">*</span> Question (English)
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="question_en"
                      rows={3}
                      id="question_en"
                      placeholder="Enter Your Question"
                      value={editFaqForm.values.question_en}
                      onChange={(e) => editFaqForm.handleChange(e)}
                      disabled={type === 'view'}
                    />
                    {editFaqForm.errors.question_en && editFaqForm.submitCount ? (
                      <span className="error mt-2">{editFaqForm.errors.question_en}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="question_fr" className="form-label userLabel">
                      <span className="text-danger">*</span> Question (French)
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="question_fr"
                      rows={3}
                      id="question_fr"
                      placeholder="Enter Your Question"
                      value={editFaqForm.values.question_fr}
                      onChange={(e) => editFaqForm.handleChange(e)}
                      disabled={type === 'view'}
                    />
                    {editFaqForm.errors.question_fr && editFaqForm.submitCount ? (
                      <span className="error mt-2">{editFaqForm.errors.question_fr}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="question_port" className="form-label userLabel">
                      <span className="text-danger">*</span> Question (Portuguese)
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="question_port"
                      rows={3}
                      id="question_port"
                      placeholder="Enter Your Question"
                      value={editFaqForm.values.question_port}
                      onChange={(e) => editFaqForm.handleChange(e)}
                      disabled={type === 'view'}
                    />
                    {editFaqForm.errors.question_port && editFaqForm.submitCount ? (
                      <span className="error mt-2">{editFaqForm.errors.question_port}</span>
                    ) : null}
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="answer_en" className="form-label userLabel">
                      <span className="text-danger">*</span> Answer (English)
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="answer_en"
                      id="answer_en"
                      rows={6}
                      placeholder="Enter Your Answer"
                      value={editFaqForm.values.answer_en}
                      onChange={(e) => editFaqForm.handleChange(e)}
                      disabled={type === 'view'}
                    />
                    {editFaqForm.errors.answer_en && editFaqForm.submitCount ? (
                      <span className="error mt-2">{editFaqForm.errors.answer_en}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="answer_fr" className="form-label userLabel">
                      <span className="text-danger">*</span> Answer (French)
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="answer_fr"
                      id="answer_fr"
                      rows={6}
                      placeholder="Enter Your Answer"
                      value={editFaqForm.values.answer_fr}
                      onChange={(e) => editFaqForm.handleChange(e)}
                      disabled={type === 'view'}
                    />
                    {editFaqForm.errors.answer_fr && editFaqForm.submitCount ? (
                      <span className="error mt-2">{editFaqForm.errors.answer_fr}</span>
                    ) : null}
                  </div>
                  <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="answer_port" className="form-label userLabel">
                      <span className="text-danger">*</span> Answer (Portuguese)
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="answer_port"
                      id="answer_port"
                      rows={6}
                      placeholder="Enter Your Answer"
                      value={editFaqForm.values.answer_port}
                      onChange={(e) => editFaqForm.handleChange(e)}
                      disabled={type === 'view'}
                    />
                    {editFaqForm.errors.answer_port && editFaqForm.submitCount ? (
                      <span className="error mt-2">{editFaqForm.errors.answer_port}</span>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center ">
                  <Link className="btn btn-outline-danger me-2" to="/faqs">
                    <i className="fas fa-arrow-left" /> Back
                  </Link>
                  {(type === 'edit' || type === 'add') && (
                    <button type="submit" className="btn btn-outline-success my-2">
                      {type === 'edit' ? 'Edit' : 'Add'}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
EditFaq.propTypes = {
  type: PropTypes.string,
}
export default EditFaq
