import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import { MonthlySettlement, getUsers, updateEarningStatus } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Globals from 'src/common/utils'
import moment from 'moment'
import { CButton } from '@coreui/react'
import Loader from '../loader/Loader'
import { AppHeader } from 'src/components'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const MonthlySettlementsPage = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Monthly Settlement')
  const clickRef = useRef()
  const { prevFilters, setPrevFilters } = useFilter()
  const [loaded, setLoaded] = useState(false)
  const [monthlySettlementList, setMonthlySettlementList] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pageLength, setPageLength] = useState(5)
  const [sorting, setSorting] = useState({
    sort_by: 'u.id',
    sort_direction: 'desc',
  })

  const minDate = moment().clone().startOf('month').clone().subtract(1, 'day').toDate()
  const [startDate, setStartDate] = useState(minDate)

  const initialFilter = useMemo(() => {
    // const date = moment(startDate, 'ddd MMM DD YYYY HH:mm:ss Z')
    return {
      page: 1,
      is_paid: '',
      search: '',
      limit: pageLength,
      start_date: '',
      end_date: '',
      // start_date: date.startOf('month').format('YYYY/MM/DD'),
      // end_date: date.endOf('month').format('YYYY/MM/DD'),
      ...sorting,
    }
  }, [pageLength, sorting])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'monthly_settlement' && { ...prevFilters }),
  })
  console.log('payload: ', payload);

  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'monthly_settlement' && { ...prevFilters }),
  })
  console.log('filter: ', filter)
  console.log('payload: ', payload);

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
    setStartDate(minDate);
  }, [initialFilter, minDate])

  const navigate = useNavigate()

  const getMonthlySettlementList = useCallback((payload) => {
    MonthlySettlement(payload).then((response) => {
      if (response.code === 200) {
        setMonthlySettlementList(response.data.data)
        setTotalRows(response.data.total_page_count)
        setLoaded(false)
      } else {
        setMonthlySettlementList([])
        setLoaded(false)
      }
    })
  }, [])

  const handlePaymentStatus = useCallback(
    (data) => {
      const myPromise = new Promise((resolve, reject) =>
        updateEarningStatus({
          userId: data?.id,
          start_date: filter.start_date,
          end_date: filter.end_date,
          is_paid: 1,
        }).then((response) => {
          if (response.code === 200) {
            setMonthlySettlementList((prev) =>
              prev.map((val) => {
                console.log('val.id: ', val.id, val)
                console.log('data?.id: ', data?.id)

                if (val.id === data?.id) {
                  val.download_template = val.download_template?.map((item) => {
                    return { ...item, is_paid: 1 }
                  })
                }
                return val
              }),
            )
            resolve(response)
          } else {
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
    [filter.end_date, filter.start_date],
  )

  const HandleDate = useCallback((startDate) => {
    const date = moment(startDate, 'ddd MMM DD YYYY HH:mm:ss Z')
    console.log('date: ', date);
    setPayload((prev) => ({
      ...prev,
      start_date: date.startOf('month').format('YYYY/MM/DD'),
      end_date: date.endOf('month').format('YYYY/MM/DD'),
    }))
  }, [])
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        marginTop: '10px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      sortable: true,
      minWidth: '50px',
      sort_key: 'u.id',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Full Name',
      selector: (row) => row.full_name,
      sort_key: 'u.full_name',
      sortable: true,
      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Templates Download',
      selector: (row) => {
        const countPaidTemplates = row?.download_template?.reduce((count, subItem) => {
          return count + (subItem.price > 0 ? 1 : 0);
        }, 0);
        return countPaidTemplates
      },
      sort_key: 'td.download_count',
      sortable: true,
      minWidth: '200px',
      headerStyle: {
        justifyContent: 'center',
      },
      style: {
        fontSize: '14px',
        justifyContent: 'center',
      },
    },

    {
      name: 'Earning',
      selector: (row) => {
        const price = row?.download_template
          ?.reduce((total, subItem) => total + subItem.price, 0)
          .toFixed(2)
        return price ? `$${price}` : 0
      },

      minWidth: '200px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Payment Status',
      minWidth: '200px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <select
              className="form-select"
              // onChange={(e) => updateStatus(e.target.value, row.id)}
              value={row?.download_template?.every((item) => item.is_paid) ? 1 : 0}
              disabled={
                !(isUserAdmin || getUserAccess.is_edit === 1) ||
                row?.download_template?.every((item) => item.is_paid)
              }
              onChange={() => {
                handlePaymentStatus(row)
              }}
            >
              {[
                { value: 1, label: 'Paid' },
                { value: 0, label: 'Unpaid' },
              ]?.map((item) => {
                return (
                  <option key={item?.value} value={item?.value} disabled={item.disabled}>
                    {item?.label}
                  </option>
                )
              })}
            </select>
          </li>
        </ul>
      ),
    },
    (isUserAdmin || getUserAccess.is_view || getUserAccess.is_remove) && {
      name: 'Action',
      minWidth: '150px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() =>
                  navigate(`/view-monthly-settlement/${row.id}`, {
                    state: {
                      filterData: { start_date: filter.start_date, end_date: filter.end_date },
                    },
                  })
                }
              >
                <i className="fas fa-eye" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
          )}
        </ul>
      ),
    },
  ]

  // const setDates = (e, { startDate, endDate }) => {
  //   setPayload((prev) => ({
  //     ...prev,
  //     start_date: startDate?.format('YYYY-MM-DD'),
  //     end_date: endDate?.format('YYYY-MM-DD'),
  //   }))
  // }

  const handleSort = (column, sortDirection) => {
    if (column.sort_key) {
      setSorting((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
      setFilter((prev) => {
        return { ...prev, sort_by: column.sort_key, sort_direction: sortDirection }
      })
    }
  }

  useEffect(() => {
    getMonthlySettlementList(filter)
    setPrevFilters({ ...filter, listing_page: 'monthly_settlement' })
  }, [filter, getMonthlySettlementList, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Monthly Settlement</title>
      </Helmet>
      <AppHeader title="Monthly Settlement" />
      {loaded ? (
        <Loader />
      ) : (
        <div className="px-3">
          <DataTable
            title={'Monthly Settlement'}
            columns={columns}
            data={monthlySettlementList}
            pagination
            paginationServer
            responsive
            onSort={handleSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={filter.page}
            paginationPerPage={filter.limit}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            onChangeRowsPerPage={(noRows) => {
              setPageLength(noRows)
              handleFilter('limit', noRows)
            }}
            onChangePage={(page) => {
              handleFilter('page', page)
            }}
            selectableRowsHighlight
            highlightOnHover
            striped
            subHeader
            fixedHeader
            fixedFooter
            persistTableHead
            subHeaderWrap
            subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            subHeaderComponent={
              <div className="w-100 ">
                <div className="row row-gap-2 ">
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search.."
                      value={payload.search}
                      onChange={(e) => {
                        handleFilterPayload('search', e.target.value)
                        if (!e.target.value.length) {
                          clearFilter()
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          if (e.target.value !== '') {
                            applyFilter()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12 d-flex ">
                    <select
                      className="form-select"
                      onChange={(e) => handleFilterPayload('is_paid', e.target.value)}
                      value={payload.is_paid}
                    >
                      <option value={''} disabled={true}>
                        Select Status
                      </option>
                      {[
                        { value: 1, label: 'Paid' },
                        { value: 0, label: 'Unpaid' },
                      ]?.map((item) => {
                        return (
                          <option key={item?.value} value={item?.value} disabled={item.disabled}>
                            {item?.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                    <DatePicker
                      selected={startDate}
                      className="form-control w-100 cursor-pointer"
                      onChange={(date) => { setStartDate(date); HandleDate(date); }}
                      dateFormat="MMM yyyy"
                      showMonthYearPicker
                      maxDate={minDate}
                    />
                  </div>
                </div>
                <>
                  <CButton
                    color="primary"
                    onClick={() => {
                      applyFilter()
                    }}
                    ref={clickRef}
                    size="sm"
                    className="mt-3 theme-custom-btn"
                    disabled={!payload.search.length && !payload.is_paid.length && !payload.start_date.length}
                  >
                    Apply
                  </CButton>
                  <CButton
                    color="primary"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      clearFilter()
                    }}
                    disabled={!payload.search.length && !payload.is_paid.length && !payload.start_date.length}
                    className="ms-3 mt-3 theme-custom-btn-outline"
                  >
                    Clear
                  </CButton>
                </>
              </div>
            }
          />
        </div>
      )}
    </>
  )
}

export default MonthlySettlementsPage
