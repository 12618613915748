import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { activeInactiveFAQ, deleteFAQ, getFAQ, getSubscription } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'
import { CButton } from '@coreui/react'
import { AppHeader } from 'src/components'
import Loader from '../loader/Loader'
import { getUserPermissions, isAdmin, isSubadmin } from 'src/common/LocalStorageService'
import { useFilter } from 'src/common/FilterContex'

const Subscription = () => {
  const isUserAdmin = isAdmin()
  const getUserAccess = isSubadmin() && getUserPermissions('Subscription')
  const { prevFilters, setPrevFilters } = useFilter()

  const navigate = useNavigate()
  const initialFilter = useMemo(() => {
    return { page: 1, search: '', limit: 5 }
  }, [])

  const [payload, setPayload] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'subscription' && { ...prevFilters }),
  })
  const [filter, setFilter] = useState({
    ...initialFilter,
    ...(prevFilters.listing_page === 'subscription' && { ...prevFilters }),
  })

  const [loaded, setLoaded] = useState(false)
  const [subscription, setSubscription] = useState([])

  const [totalRows, setTotalRows] = useState(0)

  const deletePage = async (id) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        width: '30%',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let body = { faq_id: id }
          deleteFaq(body, id)
        }
      })
  }

  const activePage = (id, status) => {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-success',
        cancelButton: status === 1 ? 'btn btn-outline-danger mx-2' : 'btn btn-outline-danger mx-2',
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Status will be changed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: status === 1 ? 'Yes, Inactive it!' : 'Yes, Active it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let body = { faq_id: id }
          activeInactiveFaq(body, id)
        }
      })
  }

  const getAllSubscription = useCallback((payload) => {
    getSubscription(payload).then((response) => {
      if (response.code === 200) {
        setSubscription(response.data)
        setLoaded(false)
      } else {
        setSubscription([])
        setLoaded(false)
      }
    })
  }, [])

  const activeInactiveFaq = useCallback(
    (payload, id) => {
      const myPromise = new Promise((resolve, reject) =>
        activeInactiveFAQ(payload).then((response) => {
          console.log('response: ', response, subscription)
          if (response.code === 200) {
            resolve(response)
            const updatedData = subscription.map((item) => {
              if (item.id === id) {
                return { ...item, is_active: response?.data?.is_active }
              }
              return item
            })
            setSubscription(updatedData)
            setLoaded(false)
          } else {
            // setFaq([])
            setLoaded(false)
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
    [subscription],
  )

  const deleteFaq = useCallback(
    (payload, id) => {
      const myPromise = new Promise((resolve, reject) =>
        deleteFAQ(payload).then((response) => {
          if (response.code === 200) {
            const filteredData = subscription.filter((item) => item.id !== id)
            console.log('filteredData: ', filteredData)
            resolve(response)
            if (subscription.length >= 2) {
              getAllSubscription(filter)
            } else {
              setFilter((prev) => {
                return { ...prev, page: filter.page - 1 }
              })
            }
            // setFaq(filteredData)
            // setTotalRows((prev) => prev - 1)
            setLoaded(false)
          } else {
            // setFaq([])
            setLoaded(false)
            reject(response)
          }
        }),
      )
      showPromiseNotification(myPromise)
    },
    [subscription],
  )

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        alignItems: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'ID',
      selector: (row, index) => row.id,
      width: '100px',
      sortable: true,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Name',
      selector: (row, index) => row.name_en,
      minWidth: '200px',
      sortable: true,
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Plan (Monthly)',
      selector: (row, index) => `$${row.price_monthly}`,
      minWidth: '200px',
      sortable: true,
      maxWidth: '500px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Plan (Yearly)',
      selector: (row, index) => `$${row.price_yearly}`,
      minWidth: '200px',
      sortable: true,
      maxWidth: '500px',
      style: {
        fontSize: '14px',
      },
    },
    {
      name: 'Action',
      minWidth: '200px',
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <ul className="list-inline">
          {(isUserAdmin || getUserAccess.is_view === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/subscription/view-subscription/${row.id}`)}
              >
                <i className="fas fa-eye fs-6 te"></i>
              </button>
            </li>
            //  </OverlayTrigger >
          )}
          {(isUserAdmin || getUserAccess.is_edit === 1) && (
            //  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
            <li className="list-inline-item">
              <button
                className="btn btn-link"
                onClick={() => navigate(`/subscription/edit-subscription/${row.id}`)}
              >
                <i className="fas fa-edit text-info" style={{ fontSize: '20px' }}></i>
              </button>
            </li>
            //  </OverlayTrigger  >
          )}
        </ul>
      ),
    },
  ]

  const handleFilter = useCallback((key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }))
  }, [])
  const handleFilterPayload = useCallback((key, value) => {
    setPayload((prev) => ({ ...prev, [key]: value }))
  }, [])

  const applyFilter = useCallback(() => {
    console.log('payload: ', payload)
    setFilter((prev) => ({ ...prev, ...payload, page: 1 }))
    setPayload((prev) => ({ ...prev, page: 1 }))
  }, [payload])

  const clearFilter = useCallback(() => {
    setFilter(initialFilter)
    setPayload(initialFilter)
  }, [initialFilter])

  useEffect(() => {
    getAllSubscription(filter)
    setPrevFilters({ ...filter, listing_page: 'faq' })
  }, [filter, getAllSubscription, setPrevFilters])

  return (
    <>
      <Helmet>
        <title>Subscription Page</title>
      </Helmet>

      <AppHeader title="Subscription" />
      <div className="px-3">
        {loaded ? (
          <Loader />
        ) : (
          <DataTable
            title="Subscription Page"
            columns={columns}
            data={subscription}
            // pagination
            selectableRowsHighlight
            highlightOnHover
            striped
            // subHeader
            fixedHeader
            fixedFooter
            // persistTableHead
            // subHeaderWrap
            // subHeaderAlign="left"
            customStyles={tableCustomStyles}
            progressPending={loaded}
            noDataComponent={
              <>
                <h5 className="p-3">No Data Found</h5>
              </>
            }
            // subHeaderComponent={
            //   <div className="w-100 ">
            //     <div className="row ">
            //       <div className="col-lg-4 col-md-6 col-sm-10 col-12">
            //         <input
            //           type="text"
            //           className="form-control"
            //           placeholder="Search.."
            //           value={payload.search}
            //           onChange={(e) => {
            //             handleFilterPayload('search', e.target.value)
            //             if (!e.target.value.length) {
            //               clearFilter()
            //             }
            //           }}
            //           onKeyDown={(e) => {
            //             if (e.key == 'Enter') {
            //               if (e.target.value !== '') {
            //                 applyFilter()
            //               }
            //             } else if (!e.target.value.length) {
            //               clearFilter()
            //             }
            //           }}
            //         />
            //       </div>
            //     </div>
            //     <div className="d-flex justify-content-between ">
            //       <div>
            //         <CButton
            //           color="primary"
            //           onClick={() => {
            //             applyFilter()
            //           }}
            //           disabled={!payload.search.length}
            //           size="sm"
            //           className="mt-3 theme-custom-btn"
            //         >
            //           Apply
            //         </CButton>
            //         <CButton
            //           color="primary"
            //           variant="outline"
            //           size="sm"
            //           disabled={!payload.search.length}
            //           onClick={() => {
            //             clearFilter()
            //           }}
            //           className="ms-3 mt-3 theme-custom-btn-outline"
            //         >
            //           Clear
            //         </CButton>
            //       </div>
            //     </div>
            //   </div>
            // }
          />
        )}
      </div>
    </>
  )
}

export default Subscription
